import React from 'react';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import moment from 'moment-timezone';
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const momentParseFormat = 'YYYY-MM-DD HH:mm';
const momentDayFormat = 'MM-DD HH:mm a';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    fab: {
        margin: theme.spacing(0.5),
    },
    progress: {
        margin: theme.spacing(0.5),
    },
}));

const LastReadingsTable = ({readings}) => {
    const classes = useStyles();

    const roundToTwo = (num) => {
        return +(Math.round(num + "e+2") + "e-2");
    };

    if (readings) {
        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Pulse Count Total</TableCell>
                            <TableCell>kWh Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {readings.map((reading, index) => {
                            let readingTime = '-';
                            if (reading.date_time) {
                                readingTime = moment(reading.date_time, momentParseFormat);
                                readingTime = readingTime.tz(moment.tz.guess()).format(momentDayFormat);
                            }

                            return (<TableRow key={index}>
                                <TableCell>{readingTime}</TableCell>
                                <TableCell>{reading.pulse_count_total}</TableCell>
                                <TableCell>{roundToTwo(reading.kwh_total)} kWh</TableCell>
                            </TableRow>);
                        })
                        }
                    </TableBody>
                </Table>
            </Paper>
        );
    }
};

LastReadingsTable.defaultProps = {};

LastReadingsTable.propTypes = {
    readings: PropTypes.array,
    fetchReadings: PropTypes.func
};

export default LastReadingsTable;

import React from 'react';
import PropTypes from "prop-types";
import {makeStyles, withStyles} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Fab from '@material-ui/core/Fab';
import MaterialTable, {MTableHeader} from "material-table";
import Pageview from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from "react-router-dom";
import UsersIcon from '@material-ui/icons/Group';

const momentParseFormat = 'YYYY-MM-DD HH:mm:ss';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    fab: {
        margin: theme.spacing(0.5),
    },
    progress: {
        margin: theme.spacing(0.5),
    },
}));

const AccountTable = ({accounts, paginator, fetchAccounts, searchInput, orderColumn, orderDirection}) => {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        fetchAccounts(newPage, searchInput, orderColumn, orderDirection);
    };

    const onSearchChange = (search) => {
        fetchAccounts(0, search)
    };

    const onSortChange = (orderedColumnId, orderDirection) => {
        fetchAccounts(paginator.currentPage, searchInput, columns[orderedColumnId].field, orderDirection);
    };

    const getSortColumnId = (orderColumn) => {
        return columns.findIndex(column => column.field === orderColumn);
    };

    const columns = [
        { title: "Account ID", field: "account_id" },
        { title: "Account Number", field: "account_number", type: "numeric" },
        { title: "Account Name", field: "account_name"},
        { title: "Business", field: "business.full_business_name", sorting: false },
        { title: "Status", field: "status", sorting: false },
    ];

    if (accounts){
        return (
            <MaterialTable
                columns={columns}
                data={accounts}
                title="Accounts Table"
                options={{
                    pageSize: 20,
                    debounceInterval: 800,
                    searchText: searchInput,
                    actionsColumnIndex: 5
                }}
                onSearchChange={onSearchChange}
                components={{
                    Header: props => (
                        <MTableHeader {...props} onOrderChange={onSortChange} orderBy={getSortColumnId(orderColumn)} orderDirection={orderDirection}  />
                    ),
                    Pagination: () => (
                        <TablePagination
                            page={paginator.currentPage}
                            count={paginator.totalRecords}
                            rowsPerPageOptions={[]}
                            rowsPerPage={20}
                            onChangePage={handleChangePage}
                        />
                    )
                }}
                actions={[
                    rowData => (
                        {
                            icon: () => {
                                return (
                                        <Tooltip title="View" aria-label="View">
                                            <Fab color="primary" className={classes.fab} size="small" component={Link} to={`accounts/${rowData.account_id}/view`}>
                                                <Pageview />
                                            </Fab>
                                        </Tooltip>
                                    );
                            }
                        }),
                    rowData => (
                        {
                            icon: () => {
                                return (
                                    <Tooltip title="Edit" aria-label="Edit">
                                        <Fab color="primary" className={classes.fab} size="small" component={Link} to={`accounts/${rowData.account_id}`}>
                                            <EditIcon />
                                        </Fab>
                                    </Tooltip>
                                );
                            }
                        }),
                    rowData => (
                        {
                            icon: () => {
                                return (
                                    <Tooltip title="Users" aria-label="Users">
                                        <Fab color="primary" className={classes.fab} size="small" component={Link} to={`accounts/${rowData.account_id}/users`}>
                                            <UsersIcon />
                                        </Fab>
                                    </Tooltip>
                                );
                            }
                        })
                ]}
            />
        );
    }
};

AccountTable.defaultProps = {
};

AccountTable.propTypes = {
    accounts: PropTypes.array,
    paginator: PropTypes.object,
    fetchAccounts: PropTypes.func,
    searchInput: PropTypes.string,
    orderColumn: PropTypes.string,
    orderDirection: PropTypes.string
};

export default AccountTable;

import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import {Link} from "react-router-dom";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import LoadingSpinner from "../../LoadingSpinner"

const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2, 0, 0),
    },
    formBox: {
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2, 4, 4, 4),
        borderRadius: '4px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);'
    },
    fullWidth: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    autocomplete: {
        margin: theme.spacing(2, 2, 1, 0),
        width: `calc((100% - 16px)/2)`,
        display: 'inline-flex'
    },
    textFieldLeft: {
        marginRight: theme.spacing(2),
        width: `calc((100% - 16px)/2)`
    },
    textField: {
        width: `calc((100% - 16px)/2)`
    },
    spaceBetween: {
        marginRight: theme.spacing(2),
    },
    closeButton: {
        borderRadius: `4px !important`,
        marginRight: `16px !important`
    },
}));

const BusinessForm = ({onStoreForm, nextStep, formValues, getBusiness, business, editionMode}) => {

    const classes = useStyles();
    const formikRef = useRef();
    const [open, setOpen] = React.useState(false);
    const [displayForm, setDisplayForm] = React.useState(false);

    useEffect(() => {
        if (!(business.data && business.data.length)) {
            getBusiness(1);
        }else {
            setDisplayForm(true);
        }
    }, []);

    useEffect(() => {
        if (business.data && business.data.length){
            if (formValues.business.business_id) {
                mapValuesToField(formValues.business);
            }else{
                mapValuesToField(null);
            }
            setTimeout(() => {
                setDisplayForm(true);
            });
        }
    }, [business]);

    const validationRules = () => {
        return Yup.object().shape({
            business_id: Yup.string(),
            full_business_name: Yup.string()
                .required('Required'),
            business_type: Yup.string()
                .required('Required'),
            business_street: Yup.string()
                .required('Required'),
            business_city: Yup.string()
                .required('Required'),
            business_state: Yup.string()
                .required('Required'),
            business_country: Yup.string()
                .required('Required'),
            business_zipcode: Yup.string()
                .required('Required'),
            primary_contact_name: Yup.string()
                .required('Required'),
            primary_contact_email: Yup.string()
                .email()
                .required('Required'),
            primary_contact_phone: Yup.string().matches(/[\d() -]+$/, {message: 'Format valid: (999) 999-9999'})
                .required('Required')
        });
    };

    const mapValuesToObject = values => {
        let businessObject = {
            business: {
                full_business_name: values.full_business_name,
                business_type: values.business_type,
                primary_contact_name: values.primary_contact_name,
                primary_contact_email: values.primary_contact_email,
                primary_contact_phone: values.primary_contact_phone,
                address: {
                    address_type: 'business_address',
                    street: values.address ? values.address.street : values.business_street,
                    city: values.address ? values.address.city : values.business_city,
                    state: values.address ? values.address.state : values.business_state,
                    country: values.address ? values.address.country : values.business_country,
                    zipcode: values.address ? values.address.zipcode : values.business_zipcode,
                    latitude: '111',
                    longitude: '111'
                }
            }
        };
        if (values.business_id) {
            businessObject.business.business_id = values.business_id;
        }
        return businessObject;
    };

    const mapObjectToValues = () => {
        const values = formValues.business;
        const result = {
            business_id: values.business_id || '',
            full_business_name: values.full_business_name || '',
            business_type: values.business_type || '',
            primary_contact_name: values.primary_contact_name || '',
            primary_contact_email: values.primary_contact_email || '',
            primary_contact_phone: values.primary_contact_phone || '',
            business_street: values.address ? values.address.street : '',
            business_city: values.address ? values.address.city : '',
            business_state: values.address ? values.address.state : '',
            business_country: values.address ? values.address.country : '',
            business_zipcode: values.address ? values.address.zipcode : '',
            business: values
        };
        return result;
    };

    const mapValuesToField = (value) => {
        if (value) {
            formikRef.current.setFieldValue('business_id', value.business_id || '');
            formikRef.current.setFieldValue('full_business_name', value.full_business_name || '');
            formikRef.current.setFieldValue('business_type', value.business_type || '');
            formikRef.current.setFieldValue('primary_contact_name', value.primary_contact_name || '');
            formikRef.current.setFieldValue('primary_contact_phone', value.primary_contact_phone || '');
            formikRef.current.setFieldValue('primary_contact_email', value.primary_contact_email || '');
            formikRef.current.setFieldValue('business_street', value.address && value.address.street ? value.address.street : '');
            formikRef.current.setFieldValue('business_city', value.address && value.address.city ? value.address.city : '');
            formikRef.current.setFieldValue('business_state', value.address && value.address.state ? value.address.state : '');
            formikRef.current.setFieldValue('business_country', value.address && value.address.country ? value.address.country : '');
            formikRef.current.setFieldValue('business_zipcode', value.address && value.address.zipcode ? value.address.zipcode : '');
        }
        if (value === null) {
            formikRef.current.resetForm();
            mapValuesToField({});
        }
    };

    const onChangeBusinessName = (event, value) => {
        mapValuesToField(value);
    };

    const renderBusinessForm = () => {
        return (<Formik
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
            ref={formikRef}
            initialValues={mapObjectToValues()}
            validationSchema={validationRules}
            onSubmit={(values) => {
                if (editionMode) {
                    onStoreForm(mapValuesToObject(values));
                }
                nextStep();
            }}
        >
            {({handleSubmit, errors, values, handleChange, handleBlur, touched}) => {
                return (
                    <div>
                        {displayForm ?
                        <form onSubmit={handleSubmit} className={classes.form} noValidate autoComplete="off">
                            <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                Business Information
                            </Typography>
                            <Grid container className={classes.formBox}>
                                <Grid item sm={12}>
                                    <Autocomplete
                                        id="field_1_autocomplete"
                                        open={open}
                                        onOpen={() => {
                                            setOpen(true);
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                        }}
                                        disabled={!editionMode}
                                        margin="normal"
                                        variant="outlined"
                                        className={classes.autocomplete}
                                        getOptionLabel={option => option.full_business_name}
                                        onChange={onChangeBusinessName}
                                        options={business.data}
                                        loading={business.isFetching}
                                        freeSolo={true}
                                        autoHighlight={true}
                                        defaultValue={values.business}
                                        renderOption={(option, {inputValue}) => {
                                            const matches = match(option.full_business_name, inputValue);
                                            const parts = parse(option.full_business_name, matches);

                                            return (
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span key={index}
                                                              style={{fontWeight: part.highlight ? 700 : 400}}>
                                                                                        {part.text}
                                                                                </span>
                                                    ))}
                                                </div>
                                            );
                                        }}
                                        disableOpenOnFocus={true}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                id="field_1"
                                                label="Business Name"
                                                variant="outlined"
                                                autoComplete="field_1"
                                                value={values.full_business_name}
                                                error={errors.full_business_name && touched.full_business_name}
                                                onChange={(event) => {
                                                    values.full_business_name = event.target.value;
                                                }}
                                                name="field_1"
                                                helperText={(errors.full_business_name && touched.full_business_name) && errors.full_business_name}
                                                /*InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {!!business.isFetching ?
                                                                <CircularProgress color="inherit" size={20}/> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}*/
                                            />
                                        )}
                                    />
                                    <TextField
                                        id="business_id"
                                        name="business_id"
                                        value={values.business_id}
                                        type="hidden"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <TextField
                                        disabled={!editionMode || !!values.business_id}
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_street && touched.business_street}
                                        id="business_street"
                                        label="Business Street"
                                        name="business_street"
                                        value={values.business_street}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_street && touched.business_street) && errors.business_street}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        disabled={!editionMode || !!values.business_id}
                                        select
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu
                                            }
                                        }}
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_type && touched.business_type}
                                        id="business_type"
                                        label="Business Type"
                                        name="business_type"
                                        value={values.business_type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_type && touched.business_type) && errors.business_type}
                                        className={classes.textFieldLeft}
                                    >
                                        <MenuItem value="commercial">Commercial</MenuItem>
                                        <MenuItem value="industrial">Industrial</MenuItem>
                                    </TextField>
                                    <TextField
                                        disabled={!editionMode || !!values.business_id}
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_city && touched.business_city}
                                        id="business_city"
                                        label="Business City"
                                        name="business_city"
                                        value={values.business_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_city && touched.business_city) && errors.business_city}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        disabled={!editionMode || !!values.business_id}
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.primary_contact_name && touched.primary_contact_name}
                                        id="primary_contact_name"
                                        label="Primary Contact Name"
                                        name="primary_contact_name"
                                        value={values.primary_contact_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.primary_contact_name && touched.primary_contact_name) && errors.primary_contact_name}
                                        className={classes.textFieldLeft}
                                    />
                                    <TextField
                                        disabled={!editionMode || !!values.business_id}
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_state && touched.business_state}
                                        id="business_state"
                                        label="Business State"
                                        name="business_state"
                                        value={values.business_state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_state && touched.business_state) && errors.business_state}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        disabled={!editionMode || !!values.business_id}
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.primary_contact_email && touched.primary_contact_email}
                                        id="primary_contact_email"
                                        label="Primary Contact Email"
                                        name="primary_contact_email"
                                        value={values.primary_contact_email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.primary_contact_email && touched.primary_contact_email) && errors.primary_contact_email}
                                        className={classes.textFieldLeft}
                                    />
                                    <TextField
                                        disabled={!editionMode || !!values.business_id}
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_country && touched.business_country}
                                        id="business_country"
                                        label="Business Country"
                                        name="business_country"
                                        value={values.business_country}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_country && touched.business_country) && errors.business_country}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        disabled={!editionMode || !!values.business_id}
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.primary_contact_phone && touched.primary_contact_phone}
                                        id="primary_contact_phone"
                                        label="Primary Contact Phone"
                                        name="primary_contact_phone"
                                        value={values.primary_contact_phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.primary_contact_phone && touched.primary_contact_phone) && errors.primary_contact_phone}
                                        className={classes.textFieldLeft}
                                    />
                                    <TextField
                                        disabled={!editionMode || !!values.business_id}
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_zipcode && touched.business_zipcode}
                                        id="business_zipcode"
                                        label="Business Zipcode"
                                        name="business_zipcode"
                                        value={values.business_zipcode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_zipcode && touched.business_zipcode) && errors.business_zipcode}
                                        className={classes.textField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction={'row-reverse'}>
                                <Grid item>
                                    <Fab
                                        color="default"
                                        size="small"
                                        component={Link}
                                        to={`/accounts`}
                                        variant={'extended'}
                                        className={classes.closeButton}
                                    >
                                        Cancel
                                    </Fab>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        : <LoadingSpinner />   
                    }
                    </div>
                )
            }}
        </Formik>);
    };

    return (
        <Grid container justify={'center'}>
            <Grid item sm={8}>
                {renderBusinessForm()}
            </Grid>
        </Grid>
    );
};

BusinessForm.propTypes = {
    onSubmit: PropTypes.func,
    nextStep: PropTypes.func,
    isFetching: PropTypes.bool
};

export default BusinessForm;


import {
    CHECK_METERS_REQUEST,
    CHECK_METERS_SUCCESS,
    CHECK_METERS_ERROR
} from '../actions/actionTypes';

const INITIAL_STATE = {
    isFetching: false,
    meters: [],
    metersWithIssues: [],
    error: false,
    errorMessage: '',
    stats: {
        all: 0,
        issues: 0,
        working: 0,
        lastUpdateTime: null
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHECK_METERS_REQUEST:
            return {
                ...state,
                isFetching: true,
                meters: [],
                metersWithIssues: [],
                error: false,
                errorMessage: '',
                stats: {
                    all: 0,
                    issues: 0,
                    working: 0,
                    lastUpdateTime: null
                }
            };

        case CHECK_METERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                meters: action.meters,
                metersWithIssues: action.metersWithIssues,
                error: false,
                errorMessage: '',
                stats: {
                    all: action.stats.all,
                    issues: action.stats.issues,
                    working: action.stats.working,
                    lastUpdateTime: action.stats.lastUpdateTime,
                }
            };

        case CHECK_METERS_ERROR:
            return {
                ...state,
                isFetching: false,
                meters: [],
                metersWithIssues: [],
                error: true,
                errorMessage: action.payload,
                stats: {
                    all: 0,
                    issues: 0,
                    working: 0,
                    lastUpdateTime: null
                }
            };

        default:
            return state;
    }
};

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import {Container, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {getUserById, updateUser} from "../../../actions/userActions";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    titleContainer: {
        margin: theme.spacing(1, 0, 3),
    },
    root: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(4),
        width: '100%'
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2, 0, 0),
    },
    formBox: {
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2, 4, 4, 4),
        borderRadius: '4px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);'
    },
    fullWidth: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textFieldLeft: {
        marginRight: theme.spacing(2),
        width: `calc((100% - 16px)/2)`
    },
    textField: {
        width: `calc((100% - 16px)/2)`
    },
    spaceBetween: {
        marginRight: theme.spacing(2),
    },
    closeButton: {
        borderRadius: `4px !important`,
        marginRight: `16px !important`
    },
    progress: {
        margin: theme.spacing(0.5),
    },
    loadingContainer: {
        textAlign: 'center',
    },
    userFormContainer: {
        marginBottom: theme.spacing(4)
    }
}));

const UserEditionPage = ({getUserById, updateUser, userById, match}) => {

    const classes = useStyles();
    const userId = match.params.id;

    useEffect(() => {
        getUserById(userId);
    }, []);

    useEffect(() => {
        if (userById.data && userById.data.user_id) {
            mapObjectToValues();
        }
    }, [userById]);

    const validationRules = () => {
        return Yup.object().shape({
            user_id: Yup.string(),
            email: Yup.string()
                .required('Required'),
            first_name: Yup.string()
                .required('Required'),
            last_name: Yup.string()
                .required('Required'),
            password: Yup.string()
                .min(8, 'Password must be 8 characters long')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_#])[A-Za-z\d$@$!%*?&_#]/, 'Must contain a number, one uppercase character, one lowercase character and a special character such as $, @, !, %, *, ?, &, _, #'),
            repeat_password: Yup.string()
                .when('password', {
                    is: (val) => val,
                    then: Yup.string()
                        .required('Required')
                        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                    otherwise: Yup.string()
                }),
            primary_phone: Yup.string().matches(/[\d() -]+$/, {message: 'Format valid: (999) 999-9999'})
                .required('Required'),
            secondary_phone: Yup.string().matches(/[\d() -]+$/, {message: 'Format valid: (999) 999-9999'})
        });
    };

    const mapObjectToValues = () => {
        return {
            user_id: userById.data.user_id,
            email: userById.data.email || '',
            first_name: userById.data.first_name || '',
            last_name: userById.data.last_name || '',
            primary_phone: userById.data.primary_phone || '',
            secondary_phone: userById.data.secondary_phone || '',
            password: userById.data.password || ''
        };
    };

    const onSubmit = (values) => {
        updateUser(values);
    };

    const LoadingSpinner = () => {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress className={classes.progress}/>
            </div>
        );
    };

    const renderUserForm = () => {
        if (userById.isFetching) {
            return <LoadingSpinner/>;
        }

        return (<Formik
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
            initialValues={mapObjectToValues()}
            validationSchema={validationRules}
            onSubmit={(values) => {
                values.email = userById.data.email;
                onSubmit(values)
            }}
        >
            {({handleSubmit, errors, values, handleChange, handleBlur, touched}) => {
                return (
                    <form onSubmit={handleSubmit} className={classes.form} noValidate>
                        <Paper className={classes.root}>
                            <Grid container>
                                <Grid item sm={12}>
                                    <TextField
                                        disabled={true}
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.email && touched.email}
                                        id="email"
                                        label="Email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.email && touched.email) && errors.email}
                                        className={classes.fullWidth}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.first_name && touched.first_name}
                                        id="first_name"
                                        label="First Name"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.first_name && touched.first_name) && errors.first_name}
                                        className={classes.textFieldLeft}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.last_name && touched.last_name}
                                        id="last_name"
                                        label="Last Name"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.last_name && touched.last_name) && errors.last_name}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.password && touched.password}
                                        id="password"
                                        label="Password"
                                        name="password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.password && touched.password) && errors.password}
                                        className={classes.textFieldLeft}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.repeat_password && touched.repeat_password}
                                        id="repeat_password"
                                        label="Repeat Password"
                                        name="repeat_password"
                                        type="password"
                                        value={values.repeat_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.repeat_password && touched.repeat_password) && errors.repeat_password}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.primary_phone && touched.primary_phone}
                                        id="primary_phone"
                                        label="Primary Phone"
                                        name="primary_phone"
                                        value={values.primary_phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.primary_phone && touched.primary_phone) && errors.primary_phone}
                                        className={classes.textFieldLeft}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.secondary_phone && touched.secondary_phone}
                                        id="secondary_phone"
                                        label="Secondary Phone"
                                        name="secondary_phone"
                                        value={values.secondary_phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.secondary_phone && touched.secondary_phone) && errors.secondary_phone}
                                        className={classes.textField}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Grid container direction={'row-reverse'}>
                            <Grid item>
                                <Fab
                                    color="default"
                                    size="small"
                                    component={Link}
                                    to={`/users`}
                                    variant={'extended'}
                                    className={classes.closeButton}
                                >
                                    Cancel
                                </Fab>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Formik>);
    };

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    <Typography variant="h4" component="h4">
                        Edit User
                    </Typography>
                </Grid>
            </Grid>
            {renderUserForm()}
        </Container>

    );
};

UserEditionPage.propTypes = {
    getUserById: PropTypes.func,
};

const mapStateToProps = state => ({
    userById: state.userById
});

const mapDispatchToProps = {
    getUserById,
    updateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditionPage);


import axios from 'axios';
import history from '../history';
import {logoutUser} from "../actions/authActions";

const getTenant = () => {
  const tenant = localStorage.getItem("TENANT");
  let tenantHeader;
  switch (tenant){
      case 'COI':
          tenantHeader = 'tenant_coi';
          break;
      case 'TECO':
          tenantHeader = 'tenant_teco';
          break;
      case 'NYPA':
          tenantHeader = 'tenant_nypa';
          break;
      default:
          tenantHeader = 'tenant_coi';
  }

  return tenantHeader;
};

let defaultConfig = {
    baseURL: process.env.REACT_APP_API_PHP_BASE_PATH, //ToDo: move to an env variable.
    headers: {
        'Content-Type': 'application/json',
        'X-TenantID': getTenant()
    }
};

let axiosInstance = axios.create(defaultConfig);

const setupInterceptors = (store) => {
    // Set the AUTH token for any request
    axiosInstance.interceptors.request.use(function (config) {
        const token = localStorage.getItem('TOKEN');
        config.headers.Authorization =  token ? `Bearer ${token}` : '';
        return config;
    });

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error && error.response && error.response.status === 401){
                // 401 Error dispatch logoutUser.
                store.dispatch(logoutUser());
                //redirects to login page:
                history.push('/login');
            }

            return Promise.reject(error);
        }
    );
};

export { setupInterceptors, getTenant };
export default axiosInstance;


import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getAccount, updateAccount, cleanAccount } from "../../../actions/accountActions";
import { connect } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Container, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
    titleContainer: {
        margin: theme.spacing(1, 0, 3),
    },
    root: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(4)
    }
}))

const AccountViewPage = ({ getAccount, updateAccount, account, match,cleanAccount }) => {

    const classes = useStyles();
    const accountId = match.params.id;

    useEffect(() => {
        getAccount(accountId);
        return () => {
            cleanAccount();
        }
    }, []);

    useEffect(() => {
        if (account.account && account.account.account_id) {
            mapObjectToValues();
        }
    }, [account]);

    const LoadingSpinner = () => {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress className={classes.progress} />
            </div>
        );
    };

    const mapObjectToValues = () => {
        const result = {
            account_id: account.account.account_id,
            account_name: account.account.account_name || '',
            account_number: account.account.account_number || '',
            primary_contact_name: account.account.primary_contact_name || '',
            primary_contact_email: account.account.primary_contact_email || '',
            primary_contact_phone: account.account.primary_contact_phone || '',
            service_address_id: account.account.service_address ? account.account.service_address.address_id : '',
            service_street: account.account.service_address ? account.account.service_address.street : '',
            service_city: account.account.service_address ? account.account.service_address.city : '',
            service_state: account.account.service_address ? account.account.service_address.state : '',
            service_country: account.account.service_address ? account.account.service_address.country : '',
            service_zipcode: account.account.service_address ? account.account.service_address.zipcode : '',
            mailing_address_id: account.account.mailing_address ? account.account.mailing_address.address_id : '',
            mailing_street: account.account.mailing_address ? account.account.mailing_address.street : '',
            mailing_city: account.account.mailing_address ? account.account.mailing_address.city : '',
            mailing_state: account.account.mailing_address ? account.account.mailing_address.state : '',
            mailing_country: account.account.mailing_address ? account.account.mailing_address.country : '',
            mailing_zipcode: account.account.mailing_address ? account.account.mailing_address.zipcode : ''
        };
        return result;
    };

    const renderBusinessInfo = () => {
        return (
            <div>
                <Typography variant="h6" component="h6">
                    Business Information
                </Typography>
                <Grid container className={classes.titleContainer}>
                    <Grid item sm={8}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="spanning table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Business Name</TableCell>
                                        <TableCell align="right">{account.account.business.full_business_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Business Type</TableCell>
                                        <TableCell align="right">{account.account.business.business_type}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Primary Contact Name</TableCell>
                                        <TableCell align="right">{account.account.business.primary_contact_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Primary Contact Email</TableCell>
                                        <TableCell align="right">{account.account.business.primary_contact_email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Primary Contact Phone</TableCell>
                                        <TableCell align="right">{account.account.business.primary_contact_phone}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Business Street</TableCell>
                                        <TableCell align="right">{account.account.business.address.street}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Business City</TableCell>
                                        <TableCell align="right">{account.account.business.address.city}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Business State</TableCell>
                                        <TableCell align="right">{account.account.business.address.state}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Business Country</TableCell>
                                        <TableCell align="right">{account.account.business.address.country}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Business Zipcode</TableCell>
                                        <TableCell align="right">{account.account.business.address.zipcode}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>)
    }

    const renderAccountInformation = () => {
        return (
            <div>
                <Typography variant="h6" component="h6">
                    Account Information
                </Typography>
                <Grid container className={classes.titleContainer}>
                    <Grid item sm={8}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="spanning table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Account Name</TableCell>
                                        <TableCell align="right">{account.account.account_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Account Number</TableCell>
                                        <TableCell align="right">{account.account.account_number}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Primary Contact Name</TableCell>
                                        <TableCell align="right">{account.account.primary_contact_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Primary Contact Email</TableCell>
                                        <TableCell align="right">{account.account.primary_contact_email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Primary Contact Phone</TableCell>
                                        <TableCell align="right">{account.account.primary_contact_phone}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const renderServiceAddress = () => {
        return (
            <div>
                <Typography variant="h6" component="h6">
                    Service Address
                </Typography>
                <Grid container className={classes.titleContainer}>
                    <Grid item sm={8}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="spanning table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Street</TableCell>
                                        <TableCell align="right">{account.account.service_address.street}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>City</TableCell>
                                        <TableCell align="right">{account.account.service_address.city}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>State</TableCell>
                                        <TableCell align="right">{account.account.service_address.state}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Country</TableCell>
                                        <TableCell align="right">{account.account.service_address.country}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Zipcode</TableCell>
                                        <TableCell align="right">{account.account.service_address.zipcode}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const renderMailingAddress = () => {
        return (
            <div>
                <Typography variant="h6" component="h6">
                    Mailing Address
                </Typography>
                <Grid container className={classes.titleContainer}>
                    <Grid item sm={8}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="spanning table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Street</TableCell>
                                        <TableCell align="right">{account.account.mailing_address.street}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>City</TableCell>
                                        <TableCell align="right">{account.account.mailing_address.city}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>State</TableCell>
                                        <TableCell align="right">{account.account.mailing_address.state}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Country</TableCell>
                                        <TableCell align="right">{account.account.mailing_address.country}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Zipcode</TableCell>
                                        <TableCell align="right">{account.account.mailing_address.zipcode}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const renderUserList = () => {
        return (
            <div>
                <Typography variant="h6" component="h6">
                    Contact List
                </Typography>
                {account.account.contact_list.map((user, index) => {
                    return (
                        <Grid container className={classes.titleContainer} key={index} >
                            <Grid item sm={8}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="spanning table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Email</TableCell>
                                                <TableCell align="right">{user.user.email}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>First Name</TableCell>
                                                <TableCell align="right">{user.user.first_name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Last Name</TableCell>
                                                <TableCell align="right">{user.user.last_name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Primary Phone</TableCell>
                                                <TableCell align="right">{user.user.primary_phone}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Secondary Phone</TableCell>
                                                <TableCell align="right">{user.user.secondary_phone}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Notify By</TableCell>
                                                <TableCell align="right">
                                                    {user.email_notification ? ' Email |' : ''}
                                                    {user.message_notification ? ' Message |' : ''}
                                                    {user.phone_notification ? ' Phone |' : ''}
                                                    {user.alarm_notification ? ' Alarm |' : ''}
                                                    {user.app_notification ? ' App |' : ''}

                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    )
                })}
            </div>
        )
    }

    const renderAccountView = () => {
        if (account.isFetching) {
            return <LoadingSpinner />;
        }
        return (
            <div>
                {account.account.business ? renderBusinessInfo() : <></>}
                {account.account ? renderAccountInformation() : <></>}
                {account.account.service_address ? renderServiceAddress() : <></>}
                {account.account.mailing_address ? renderMailingAddress() : <></>}
                {account.account.contact_list ? renderUserList() : <></>}
            </div>
        )
    }

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    <Typography variant="h4" component="h4">
                        View Account
                    </Typography>
                </Grid>
            </Grid>
            {renderAccountView()}
        </Container>

    );
}

AccountViewPage.propTypes = {
    getAccount: PropTypes.func,
};

const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = {
    getAccount,
    updateAccount,
    cleanAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountViewPage);



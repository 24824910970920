import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import {getMeters, updateMeterStatus, getIlonMeterList, cleanIlonMeterList} from "../../../actions/meterActions";
import MeterTable from "./MeterTable";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import DVRIcon from '@material-ui/icons/Dvr';
import RefreshIcon from "@material-ui/icons/Refresh";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    alignRight: {
        textAlign: 'right'
    },
    loadingContainer: {
        textAlign: 'center'
    }
}));


const MeterPage = ({meters, getMeters, updateMeterStatus,ilonMeterList, getIlonMeterList,cleanIlonMeterList }) => {
    const classes = useStyles();

    useEffect(() => {
        fetchMeters()
        fetchIlonMeters()

        return () => {
            cleanIlonMeterList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchMeters = (page, search, orderColumn, orderDirection) => {
        getMeters(page, search, orderColumn, orderDirection);
    };

    const fetchIlonMeters = () => {
        getIlonMeterList()
    }

    const onRefresh = () => {
        fetchMeters();
    };

    const updateMeterStatusFunction = (id, status) => {
        updateMeterStatus({
            id: id,
            status: status
        });
    };

    const LoadingSpinner = () => {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress className={classes.progress}/>
            </div>
        );
    };

    const renderMeterList = () => {
        if (meters.isFetching)
            return <LoadingSpinner />;

        if (meters.error)
            return <h3>There was an error getting the meters: {meters.errorMessage}</h3>;

        return (
            <MeterTable
                meters={meters.data}
                paginator={meters.paginator}
                fetchMeters={fetchMeters}
                updateMeterStatus={updateMeterStatusFunction}
                searchInput={meters.searchInput}
                orderColumn={meters.orderColumn}
                orderDirection={meters.orderDirection}
                ilonMeterList = {ilonMeterList.data}
            />
        );
    };

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    <Typography variant="h4" component="h4">
                        Meters
                    </Typography>
                </Grid>
                <Grid item sm={4} className={classes.alignRight}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        component={Link}
                        to="/meters/create">
                        New Meter
                        <DVRIcon className={classes.rightIcon}/>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={onRefresh}>
                        Refresh <RefreshIcon/>
                    </Button>
                </Grid>
            </Grid>
            {renderMeterList()}
        </Container>
    );
};

const mapStateToProps = state => ({
    meters: state.meters,
    ilonMeterList: state.ilonMeterList
});

const mapDispatchToProps = {
    getMeters,
    updateMeterStatus,
    getIlonMeterList,
    cleanIlonMeterList
};

export default connect(mapStateToProps, mapDispatchToProps)(MeterPage);

import {
    GET_METERS_REQUEST,
    GET_METERS_SUCCESS,
    GET_METERS_ERROR,
    CREATE_METERS_REQUEST,
    CREATE_METERS_SUCCESS,
    CREATE_METERS_ERROR,
    CREATE_METERS_CLEAN_UP,
    GET_READINGS_REQUEST,
    GET_READINGS_SUCCESS,
    GET_READINGS_ERROR,
    GET_DATA_POINTS_ERROR,
    GET_DATA_POINTS_SUCCESS,
    GET_DATA_POINTS_REQUEST,
    GET_METER_REQUEST,
    GET_METER_SUCCESS,
    GET_METER_ERROR,
    GET_UNASSOCIATED_METERS_REQUEST,
    GET_UNASSOCIATED_METERS_SUCCESS,
    GET_UNASSOCIATED_METERS_ERROR,
    METER_REBOOT_REQUEST,
    METER_REBOOT_SUCCESS, 
    METER_REBOOT_ERROR, 
    GET_ILON_METER_LIST_REQUEST,
    GET_ILON_METER_LIST_ERROR,
    GET_ILON_METER_LIST_SUCCESS,
    GET_ILON_METER_LIST_RESET,
    GET_OUTPUT_TOGGLE_STATUS_REQUEST,
    GET_OUTPUT_TOGGLE_STATUS_SUCCESS,
    GET_OUTPUT_TOGGLE_STATUS_ERROR,
    GET_OUTPUT_TOGGLE_STATUS_RESET,
    OUTPUT_TOGGLE_REQUEST,
    OUTPUT_TOGGLE_SUCCESS,
    OUTPUT_TOGGLE_ERROR
} from '../actions/actionTypes';

const INITIAL_STATE = {
    isFetching: false,
    data: [],
    paginator: {
      currentPage: 0,
      totalRecords: 0,
      from: 0,
      to: 0
    },
    error: false,
    errorMessage: '',
    searchInput: '',
    orderColumn: 'account_name',
    orderDirection: 'asc'
};

const INITIAL_UNASSOCIATED_METERS_STATE = {
    isFetching: false,
    data: [],
    error: false,
    errorMessage: '',
};

const INITIAL_READINGS_STATE = {
    isFetching: false,
    data: [],
    error: false,
    errorMessage: '',
};

const INITIAL_ILON_METER_LIST_STATE = {
    isFetching: false,
    data: [],
    error: false,
    errorMessage: '',
};

const INITIAL_METER_STATE = {
    isFetching: false,
    meter: {},
    error: false,
    errorMessage: '',
};

const INITIAL_TOGGLE_OUTPUT_STATE = {
    isFetching: false,
    data: {},
    error: false,
    errorMessage: '',
};

const INITIAL_TOGGLE_OUTPUT = {
    isFetching: false,
    success: false,
    error: false,
    errorMessage: '',
}


export const meters = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_METERS_REQUEST:
            return {
                ...state,
                isFetching: true,
                data: [],
                error: false,
                errorMessage: '',
                searchInput: '',
                orderColumn: action.orderColumn,
                orderDirection: action.orderDirection,
            };

        case GET_METERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.meters,
                paginator: {
                    currentPage: action.currentPage,
                    totalRecords: action.totalRecords,
                    from: action.from,
                    to: action.to
                },
                error: false,
                errorMessage: '',
                searchInput: action.searchInput,
                orderColumn: action.orderColumn,
                orderDirection: action.orderDirection
            };

        case GET_METERS_ERROR:
            return {
                ...state,
                isFetching: false,
                data: [],
                error: true,
                errorMessage: action.payload,
            };

        case CREATE_METERS_REQUEST:
            return {
                ...state,
                isFetching: true, //ToDo: replace it with isCreating?
                error: false,
                errorMessage: ''
            };

        case CREATE_METERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: false,
                errorMessage: '',
            };

        case CREATE_METERS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export const unassociatedMeters = (state = INITIAL_UNASSOCIATED_METERS_STATE, action) => {
    switch (action.type) {
        case GET_UNASSOCIATED_METERS_REQUEST:
            return {
                ...state,
                isFetching: true,
                data: [],
                error: false,
                errorMessage: ''
            };

        case GET_UNASSOCIATED_METERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.meters,
                error: false,
                errorMessage: ''
            };

        case GET_UNASSOCIATED_METERS_ERROR:
            return {
                ...state,
                isFetching: false,
                data: [],
                error: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export const meter = (state = INITIAL_METER_STATE, action) => {
    switch (action.type) {
        case GET_METER_REQUEST:
            return {
                ...state,
                isFetching: true,
                meter: {},
                error: false,
                errorMessage: ''
            };

        case GET_METER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                meter: action.meter,
                error: false,
                errorMessage: '',
            };

        case GET_METER_ERROR:
            return {
                ...state,
                isFetching: false,
                meter: {},
                error: true,
                errorMessage: action.payload,
            };
        case CREATE_METERS_CLEAN_UP:
            return {
                ...state,
                isFetching: false,
                meter: {},
                error: false,
                errorMessage: ''
            };

        default:
            return state;
    }
};

export const readings = (state = INITIAL_READINGS_STATE , action) => {
    switch (action.type) {
        case GET_READINGS_REQUEST:
            return {
                ...state,
                isFetching: true,
                data: [],
                error: false,
                errorMessage: ''
            };

        case GET_READINGS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.readings,
                error: false,
                errorMessage: '',
            };

        case GET_READINGS_ERROR:
            return {
                ...state,
                isFetching: false,
                data: [],
                error: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export const dataPoints = (state = INITIAL_READINGS_STATE , action) => {
    switch (action.type) {
        case GET_DATA_POINTS_REQUEST:
            return {
                ...state,
                isFetching: true,
                data: [],
                error: false,
                errorMessage: ''
            };

        case GET_DATA_POINTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.readings,
                error: false,
                errorMessage: '',
            };

        case GET_DATA_POINTS_ERROR:
            return {
                ...state,
                isFetching: false,
                data: [],
                error: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};


export const meterReboot = (state = {} , action) => {
    switch (action.type) {
        case METER_REBOOT_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: false,
                errorMessage: ''
            };

        case METER_REBOOT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: false,
                errorMessage: '',
            };

        case METER_REBOOT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export const ilonMeterList = (state = INITIAL_ILON_METER_LIST_STATE, action) => {
    switch (action.type) {
        case GET_ILON_METER_LIST_REQUEST:
            return {
                ...state,
                isFetching: true,
                data: [],
                error: false,
                errorMessage: ''
            };

        case GET_ILON_METER_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.data,
                error: false,
                errorMessage: '',
            };

        case GET_ILON_METER_LIST_ERROR:
            return {
                ...state,
                isFetching: false,
                data: [],
                error: true,
                errorMessage: action.payload,
            };

        case GET_ILON_METER_LIST_RESET:
            return {
                isFetching: false,
                data: [],
                error: false,
                errorMessage: ''
            };

        default:
            return state;
    }
}; 

export const outputToggleStatus = (state = INITIAL_TOGGLE_OUTPUT_STATE , action) => {
    switch (action.type) {
        case GET_OUTPUT_TOGGLE_STATUS_REQUEST:
            return {
                ...state,
                isFetching: true,
                data: {},
                error: false,
                errorMessage: ''
            };

        case GET_OUTPUT_TOGGLE_STATUS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                error: false,
                errorMessage: '',
            };

        case GET_OUTPUT_TOGGLE_STATUS_ERROR:
            return {
                ...state,
                isFetching: false,
                data: {},
                error: true,
                errorMessage: action.payload,
            };
        case GET_OUTPUT_TOGGLE_STATUS_RESET:
            return {
                ...state,
                isFetching: false,
                data: {},
                error: false,
                errorMessage: ''
            };
        default:
            return state;
    }
};

export const outputToggle = (state = INITIAL_TOGGLE_OUTPUT , action) => {
    switch (action.type) {
        case OUTPUT_TOGGLE_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorMessage: ''
            };

        case OUTPUT_TOGGLE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: true,
                error: false,
                errorMessage: ''
            };

        case OUTPUT_TOGGLE_ERROR:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorMessage: action.payload
            };
            
        default:
            return state;
    }
};

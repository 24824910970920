import React, {useEffect} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import BusinessForm from "./BusinessForm";
import AccountForm from "./AccountForm";
import UsersForm from "./UsersForm";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const MultiStepAccountForm = ({
                                  onSubmitAction,
                                  getBusiness,
                                  business,
                                  getUsers,
                                  users,
                                  account,
                                  getBusinessByName,
                                  validateAccountNumber,
                                  accountNumber,
                                  accountId,
                                  accountCreate,
                                  businessByName
                              }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [editionMode, setEditionMode] = React.useState(true);
    const steps = ['Business', 'Account', 'Users'];
    const [form, setForm] = React.useState({
        business: {},
        account: {},
        users: []
    });
    const [showCreateNewCompanyForm, setShowCreateNewCompanyForm] = React.useState(true);
    const [showCreateNewUserForm, setShowCreateNewUserForm] = React.useState(true);

    useEffect(() => {
        cleanState();
    }, []);

    useEffect(() => {
        if (account && account.account && account.account.account_id) {
            setEditionMode(false);
            onStoreFormValues(mapAccountToForm());
        }
    }, [account]);

    const cleanState = () => {
        setForm({
            business: {},
            account: {},
            users: []
        });
        setEditionMode(true);
        setActiveStep(0);
    };

    const mapAccountToForm = () => {
        const accountObj = account.account;
        const businessObj = account.account.business;
        const usersObj = account.account.contact_list;

        let accountToForm = {
            business: businessObj,
            account: accountObj
        };
        if (usersObj && usersObj.length) {
            accountToForm.users = usersObj.map(user => {
                const userObj = user.user;
                userObj.contact_type = user.contact_type;
                return userObj;
            });
        }
        return accountToForm;
    };

    const mapFormToAccount = () => {
        let formToAccount = {
            account: form.account,
            users: form.users
        };

        if (form.meters){
            formToAccount.meters = form.meters;
        }

        if (form.business.business_id) {
            formToAccount.business = {
                business_id: form.business.business_id
            };
        } else {
            formToAccount.business = form.business;
        }

        return formToAccount;
    };

    const onStoreFormValues = (values) => {
        setForm(Object.assign(form, values));
    };

    const onShowCreateNewCompany = (value) => {
        setShowCreateNewCompanyForm(value);
    };

    const onShowCreateNewUserForm = (value) => {
        setShowCreateNewUserForm(value);
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <BusinessForm
                        showCreateNewCompanyForm={showCreateNewCompanyForm}
                        onShowCreateNewCompanyForm={onShowCreateNewCompany}
                        nextStep={handleNext}
                        formValues={form}
                        business={business}
                        businessByName={businessByName}
                        getBusiness={getBusiness}
                        editionMode={editionMode}
                        getBusinessByName={getBusinessByName}
                        onStoreForm={onStoreFormValues}
                    />
                );
            case 1:
                return (
                    <AccountForm
                        prevStep={handlePrev}
                        formValues={form}
                        nextStep={handleNext}
                        validateAccountNumber={validateAccountNumber}
                        accountNumber={accountNumber}
                        editionMode={editionMode}
                        onStoreForm={onStoreFormValues}
                    />
                );
            case 2:
                return (
                    <UsersForm
                        showCreateNewUserForm={showCreateNewUserForm}
                        onShowCreateNewUserForm={onShowCreateNewUserForm}
                        prevStep={handlePrev}
                        formValues={form}
                        users={users}
                        getUsers={getUsers}
                        editionMode={editionMode}
                        onStoreForm={onStoreFormValues}
                        onSubmit={onSubmit}
                        accountCreate={accountCreate}
                    />
                );
            default:
                return 'Unknown stepIndex';
        }
    };

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handlePrev = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const onSubmit = (values) => {
        onStoreFormValues(values);
        onSubmitAction(mapFormToAccount());
    };

    return (
        <div className={classes.root}>
            <Grid>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {account.isFetching && <Grid container justify={'center'} className={classes.formBox}>
                        <Grid item sm={8}>
                            <Typography>Loading...</Typography>
                        </Grid>
                    </Grid>}
                    {!account.isFetching && getStepContent(activeStep)}
                </div>
            </Grid>
        </div>
    );
};

MultiStepAccountForm.propTypes = {};

export default MultiStepAccountForm;


import {
    TENANT_CHANGE_SUCCESS, TENANT_CHANGE_ERROR
} from '../actions/actionTypes';

const INITIAL_STATE = {
    tenant: localStorage.getItem('TENANT'),
    error: false,
    errorMessage: '',
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TENANT_CHANGE_SUCCESS:
            return {
                ...state,
                tenant: action.tenant,
                error: false,
                errorMessage: ''
            };

        case TENANT_CHANGE_ERROR:
            return {
                ...state,
                tenant: null,
                error: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

import {combineReducers} from 'redux';
import authReducer from './authReducer';
import {meters, meter, readings, dataPoints, unassociatedMeters, outputToggleStatus, outputToggle, meterReboot, ilonMeterList} from './meterReducer';
import {account, accountCreate, accountNumber, accounts} from './accountReducer';
import meterCheckReducer from './meterCheckReducer';
import tenantReducer from './tenantReducer';
import {business, businessByName, businessById} from "./businessReducer";
import {users, userById} from "./userReducer";
import {gateways, gateway} from "./gatewayReducer";

export default combineReducers({
    auth: authReducer,
    tenant: tenantReducer,
    meters: meters,
    meter: meter,
    unassociatedMeters: unassociatedMeters,
    readings: readings,
    dataPoints: dataPoints,
    accountNumber: accountNumber,
    account: account,
    accounts: accounts,
    accountCreate: accountCreate,
    business: business,
    businessByName: businessByName,
    businessById: businessById,
    gateways: gateways,
    gateway: gateway,
    users: users,
    userById: userById,
    checkMeters: meterCheckReducer,
    meterReboot: meterReboot,
    ilonMeterList: ilonMeterList,
    outputToggleStatus: outputToggleStatus,
    outputToggle: outputToggle
});

import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formBox: {
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2, 4, 4, 4),
        borderRadius: '4px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);'
    },
    columnTitle: {
        width: `calc(100% - 8px)`,
        margin: '16px 8px 0',
    },
    textFieldLeft: {
        width: `calc(100% - 8px)`,
        marginRight: '8px'
    },
    textField: {
        width: `calc(100% - 8px)`,
        marginLeft: '8px'
    }
}));

const AccountForm = ({onStoreForm, nextStep, formValues, prevStep, editionMode, validateAccountNumber, accountNumber}) => {
    const classes = useStyles();
    const formikRef = useRef();
    const [accountNumberUsed, setAccountNumberUsed] = React.useState(false);

    useEffect(() => {
        if (accountNumber.account && accountNumber.account.account_id) {
            setAccountNumberUsed(true);
        } else {
            setAccountNumberUsed(false);
        }
    }, [accountNumber]);

    const validationRules = () => {
        if (editionMode) {
            return Yup.object().shape({
                account_name: Yup.string()
                    .required('Required'),
                account_number: Yup.string()
                    .required('Required'),
                meter_number: Yup.string(),
                pulse_weight: Yup.string(),
                service_street: Yup.string()
                    .required('Required'),
                service_city: Yup.string()
                    .required('Required'),
                service_state: Yup.string()
                    .required('Required'),
                service_country: Yup.string()
                    .required('Required'),
                service_zipcode: Yup.string()
                    .required('Required'),
                mailing_street: Yup.string()
                    .required('Required'),
                mailing_city: Yup.string()
                    .required('Required'),
                mailing_state: Yup.string()
                    .required('Required'),
                mailing_country: Yup.string()
                    .required('Required'),
                mailing_zipcode: Yup.string()
                    .required('Required'),
                primary_contact_name: Yup.string()
                    .required('Required'),
                primary_contact_email: Yup.string()
                    .required('Required'),
                primary_contact_phone: Yup.string().matches(/[\d() -]+$/, {message: 'Format valid: (999) 999-9999'})
                    .required('Required')
            });
        }
        return {}
    }

    const mapValuesToObject = values => {
        const form = {
            account: {
                utility_id: 1, // TODO Change it to use the tenant id selected on the TopBar
                account_name: values.account_name,
                account_number: values.account_number,
                primary_contact_name: values.primary_contact_name,
                primary_contact_email: values.primary_contact_email,
                primary_contact_phone: values.primary_contact_phone,
                business_type: values.business_type,
                service_address: {
                    street: values.service_street,
                    city: values.service_city,
                    state: values.service_state,
                    country: values.service_country,
                    zipcode: values.service_zipcode,
                    address_type: 'account_service_address',
                    latitude: '111',
                    longitude: '111'
                },
                mailing_address: {
                    street: values.mailing_street,
                    city: values.mailing_city,
                    state: values.mailing_state,
                    country: values.mailing_country,
                    zipcode: values.mailing_zipcode,
                    address_type: 'account_mailing_address',
                    latitude: '111',
                    longitude: '111'
                }
            }
        };
        if (values.meter_number){
            const meter = {
                meter_number: values.meter_number
            };
            if(values.pulse_weight){
                meter.pulse_weight = values.pulse_weight
            }
            form.meters = [meter];
        }
        return form;
    };

    const mapObjectToValues = () => {
        const values = formValues.account;
        const meters = formValues.meters;
        const business = formValues.business;
        return {
            account_name: values.account_name || business.full_business_name,
            account_number: values.account_number || '',
            meter_number: meters && meters.length ? meters[0].meter_number : '',
            pulse_weight: meters && meters.length ? meters[0].pulse_weight : '',
            primary_contact_name: values.primary_contact_name || business.primary_contact_name,
            primary_contact_email: values.primary_contact_email || business.primary_contact_email,
            primary_contact_phone: values.primary_contact_phone || business.primary_contact_phone,
            service_street: values.service_address ? values.service_address.street : business.address.street,
            service_city: values.service_address ? values.service_address.city : business.address.city,
            service_state: values.service_address ? values.service_address.state : business.address.state,
            service_country: values.service_address ? values.service_address.country : business.address.country,
            service_zipcode: values.service_address ? values.service_address.zipcode : business.address.zipcode,
            mailing_street: values.mailing_address ? values.mailing_address.street : business.address.street,
            mailing_city: values.mailing_address ? values.mailing_address.city : business.address.city,
            mailing_state: values.mailing_address ? values.mailing_address.state : business.address.state,
            mailing_country: values.mailing_address ? values.mailing_address.country : business.address.country,
            mailing_zipcode: values.mailing_address ? values.mailing_address.zipcode : business.address.zipcode,
        }
    };

    const goBack = (values) => {
        if (editionMode) {
            onStoreForm(mapValuesToObject(values));
        }
        prevStep();
    }

    const onBlurAccountNumber = (event) => {
        if (event.target.value) {
            validateAccountNumber({account_number: event.target.value});
        }
    };

    return (
        <Formik
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
            ref={formikRef}
            initialValues={mapObjectToValues()}
            validationSchema={validationRules}
            onSubmit={(values) => {
                if (editionMode) {
                    onStoreForm(mapValuesToObject(values));
                }
                nextStep();
            }}
        >
            {({handleSubmit, errors, values, handleChange, handleBlur, touched, isSubmitting}) => {
                if (accountNumberUsed) {
                    errors.account_number = "This account number is already used";
                    touched.account_number = true;
                } else {
                    if (errors.account_number !== "Required") {
                        delete errors.account_number;
                    }
                }
                return (
                    <form onSubmit={handleSubmit} className={classes.form} noValidate>
                        <Grid container justify={'center'}>
                            <Grid item sm={8}>
                                <Grid container className={classes.formBox}>
                                    <Grid item sm={12}>
                                        <Grid container>
                                            <Grid item sm={6}>
                                                <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                                    Account Information
                                                </Typography>
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.account_name && touched.account_name}
                                                    id="account_name"
                                                    label="Account Name"
                                                    name="account_name"
                                                    value={values.account_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.account_name && touched.account_name) && errors.account_name}
                                                    className={classes.textFieldLeft}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.account_number && touched.account_number}
                                                    id="account_number"
                                                    label="Account Number"
                                                    name="account_number"
                                                    value={values.account_number}
                                                    onChange={handleChange}
                                                    onBlur={onBlurAccountNumber}
                                                    helperText={(errors.account_number && touched.account_number) && errors.account_number}
                                                    className={classes.textFieldLeft}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {!!accountNumber.isFetching ?
                                                                    <CircularProgress color="inherit"
                                                                                      size={20}/> : null}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.primary_contact_name && touched.primary_contact_name}
                                                    id="primary_contact_name"
                                                    label="Primary Contact Name"
                                                    name="primary_contact_name"
                                                    value={values.primary_contact_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.primary_contact_name && touched.primary_contact_name) && errors.primary_contact_name}
                                                    className={classes.textFieldLeft}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.primary_contact_email && touched.primary_contact_email}
                                                    id="primary_contact_email"
                                                    label="Primary Contact Email"
                                                    name="primary_contact_email"
                                                    value={values.primary_contact_email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.primary_contact_email && touched.primary_contact_email) && errors.primary_contact_email}
                                                    className={classes.textFieldLeft}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.primary_contact_phone && touched.primary_contact_phone}
                                                    id="primary_contact_phone"
                                                    label="Primary Contact Phone"
                                                    name="primary_contact_phone"
                                                    value={values.primary_contact_phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.primary_contact_phone && touched.primary_contact_phone) && errors.primary_contact_phone}
                                                    className={classes.textFieldLeft}
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                                    Service Address
                                                </Typography>
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.service_street && touched.service_street}
                                                    id="service_street"
                                                    label="Street"
                                                    name="service_street"
                                                    value={values.service_street}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.service_street && touched.service_street) && errors.service_street}
                                                    className={classes.textField}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.service_city && touched.service_city}
                                                    id="service_city"
                                                    label="City"
                                                    name="service_city"
                                                    value={values.service_city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.service_city && touched.service_city) && errors.service_city}
                                                    className={classes.textField}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.service_state && touched.service_state}
                                                    id="service_state"
                                                    label="State"
                                                    name="service_state"
                                                    value={values.service_state}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.service_state && touched.service_state) && errors.service_state}
                                                    className={classes.textField}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.service_country && touched.service_country}
                                                    id="service_country"
                                                    label="Country"
                                                    name="service_country"
                                                    value={values.service_country}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.service_country && touched.service_country) && errors.service_country}
                                                    className={classes.textField}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.service_zipcode && touched.service_zipcode}
                                                    id="service_zipcode"
                                                    label="Zipcode"
                                                    name="service_zipcode"
                                                    value={values.service_zipcode}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.service_zipcode && touched.service_zipcode) && errors.service_zipcode}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            {!editionMode && <Grid item sm={6}></Grid>}
                                            {editionMode && <Grid item sm={6}>
                                                <Typography variant="h6" component="h6"
                                                            className={classes.columnTitle}>
                                                    Meter Information
                                                </Typography>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.meter_number && touched.meter_number}
                                                    id="meter_number"
                                                    label="Meter Number"
                                                    name="meter_number"
                                                    value={values.meter_number}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.meter_number && touched.meter_number) && errors.meter_number}
                                                    className={classes.textFieldLeft}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.pulse_weight && touched.pulse_weight}
                                                    id="pulse_weight"
                                                    label="Pulse Weight"
                                                    name="pulse_weight"
                                                    value={values.pulse_weight}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.pulse_weight && touched.pulse_weight) && errors.pulse_weight}
                                                    className={classes.textFieldLeft}
                                                />
                                            </Grid>}
                                            <Grid item sm={6}>
                                                <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                                    Mailing Address
                                                </Typography>
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.mailing_street && touched.mailing_street}
                                                    id="mailing_street"
                                                    label="Street"
                                                    name="mailing_street"
                                                    value={values.mailing_street}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.mailing_street && touched.mailing_street) && errors.mailing_street}
                                                    className={classes.textField}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.mailing_city && touched.mailing_city}
                                                    id="mailing_city"
                                                    label="City"
                                                    name="mailing_city"
                                                    value={values.mailing_city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.mailing_city && touched.mailing_city) && errors.mailing_city}
                                                    className={classes.textField}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.mailing_state && touched.mailing_state}
                                                    id="mailing_state"
                                                    label="State"
                                                    name="mailing_state"
                                                    value={values.mailing_state}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.mailing_state && touched.mailing_state) && errors.mailing_state}
                                                    className={classes.textField}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.mailing_country && touched.mailing_country}
                                                    id="mailing_country"
                                                    label="Country"
                                                    name="mailing_country"
                                                    value={values.mailing_country}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.mailing_country && touched.mailing_country) && errors.mailing_country}
                                                    className={classes.textField}
                                                />
                                                <TextField
                                                    disabled={!editionMode}
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={errors.mailing_zipcode && touched.mailing_zipcode}
                                                    id="mailing_zipcode"
                                                    label="Zipcode"
                                                    name="mailing_zipcode"
                                                    value={values.mailing_zipcode}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.mailing_zipcode && touched.mailing_zipcode) && errors.mailing_zipcode}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction={'row-reverse'}>
                                    <Grid item>
                                        <Button
                                            onClick={() => goBack(values)}
                                            className={classes.backButton}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            disabled={accountNumber.isFetching}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )
            }}
        </Formik>
    );
};

AccountForm.propTypes = {
    onSubmit: PropTypes.func,
    prevStep: PropTypes.func,
    nextStep: PropTypes.func,
    isFetching: PropTypes.bool
};

export default AccountForm;


import {
    GET_GATEWAYS_REQUEST,
    GET_GATEWAYS_SUCCESS,
    GET_GATEWAYS_ERROR,
    GET_GATEWAY_REQUEST,
    GET_GATEWAY_SUCCESS,
    GET_GATEWAY_ERROR,
    CREATE_GATEWAY_CLEAN_UP,
} from '../actions/actionTypes';

const INITIAL_STATE = {
    isFetching: false,
    data: [],
    paginator: {
      currentPage: 0,
      totalRecords: 0,
      from: 0,
      to: 0
    },
    error: false,
    errorMessage: '',
    searchInput: '',
    orderColumn: 'gateway_id',
    orderDirection: 'asc'
};

const INITIAL_GATEWAY_STATE = {
    isFetching: false,
    gateway: {},
    error: false,
    errorMessage: '',
};


export const gateways = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_GATEWAYS_REQUEST:
            return {
                ...state,
                isFetching: true,
                data: [],
                error: false,
                errorMessage: '',
                searchInput: '',
                orderColumn: action.orderColumn,
                orderDirection: action.orderDirection,
            };

        case GET_GATEWAYS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.meters,
                paginator: {
                    currentPage: action.currentPage,
                    totalRecords: action.totalRecords,
                    from: action.from,
                    to: action.to
                },
                error: false,
                errorMessage: '',
                searchInput: action.searchInput,
                orderColumn: action.orderColumn,
                orderDirection: action.orderDirection
            };

        case GET_GATEWAYS_ERROR:
            return {
                ...state,
                isFetching: false,
                data: [],
                error: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export const gateway = (state = INITIAL_GATEWAY_STATE, action) => {
    switch (action.type) {
        case GET_GATEWAY_REQUEST:
            return {
                ...state,
                isFetching: true,
                gateway: {},
                error: false,
                errorMessage: ''
            };

        case GET_GATEWAY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                gateway: action.gateway,
                error: false,
                errorMessage: '',
            };

        case GET_GATEWAY_ERROR:
            return {
                ...state,
                isFetching: false,
                gateway: {},
                error: true,
                errorMessage: action.payload,
            };

        case CREATE_GATEWAY_CLEAN_UP:
            return {
                ...state,
                isFetching: false,
                gateway: {},
                error: false,
                errorMessage: ''
            };
        default:
            return state;
    }
};

import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import GatewayMeterTable from "./GatewayMeterTable";
import MeterIcon from "@material-ui/icons/Memory";
import Paper from "@material-ui/core/Paper";
import LoadingSpinner from "../../LoadingSpinner"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: '100%',
        marginBottom: theme.spacing(4)
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textFieldLeft: {
        marginRight: theme.spacing(2),
        width: `calc((100% - 16px)/2)`
    },
    textField: {
        width: `calc((100% - 16px)/2)`
    },
    closeButton: {
        borderRadius: `4px !important`,
        marginRight: `16px !important`
    },
    autocomplete: {
        margin: theme.spacing(2, 2, 1, 0),
        width: `calc((100% - 16px)/2)`,
        display: 'inline-flex'
    },
}));

const GatewayForm = ({isFetching, onSubmit, gateway, unassociatedMeters}) => {
    const classes = useStyles();
    const [meterAssociateForm, setMeterAssociateForm] = React.useState(false);
    const formikRef = useRef();
    const [meterList, setMeterList] = React.useState([]);
    const addressMappingDefault = [{
        name: '001'
    }, {
        name: '002'
    }, {
        name: '003'
    }, {
        name: '004'
    }];
    const [addressMappingList, setAddressMappingList] = React.useState(addressMappingDefault.slice(0));
    const [unassociatedMeterList, setUnassociatedMeterList] = React.useState(unassociatedMeters.data.slice(0));

    useEffect(() => {
        if (gateway.gateway && gateway.gateway.gateway_id) {
            mapObjectToValues();
            if (gateway.gateway.meter_list && gateway.gateway.meter_list.length) {
                setMeterList(gateway.gateway.meter_list);
            }
        }
    }, [gateway]);

    useEffect(() => {
        if (unassociatedMeters.data.length) {
            setUnassociatedMeterList(unassociatedMeters.data.slice(0));
        }
    }, [unassociatedMeters]);


    useEffect(() => {
        disableAddressMappingUsed();
        removeUnassociatedMeterUsedFromList();
    }, [meterList]);

    const disableAddressMappingUsed = () => {
        const addressList = addressMappingDefault.slice(0);
        meterList.map(meter => {
            if (meter.address_mapping) {
                switch (meter.address_mapping) {
                    case "001":
                        addressList[0].disabled = true;
                        break;
                    case "002":
                        addressList[1].disabled = true;
                        break;
                    case "003":
                        addressList[2].disabled = true;
                        break;
                    case "004":
                        addressList[3].disabled = true;
                        break;
                }
            }
        });
        setAddressMappingList(addressList);
    };

    const removeUnassociatedMeterUsedFromList = () => {
        const unassociatedList = unassociatedMeters.data.slice(0);
        setUnassociatedMeterList(unassociatedList.filter(item => {
            const meter = meterList.find(meter => meter.meter_id === item.meter_id);
            return !meter;
        }));
    };

    const validationRules = Yup.object().shape({
        type: Yup.string()
            .required('Required'),
        unique_identifier: Yup.string()
            .required('Required')
            .matches(/^001EC6(([0-9A-F]{2}){3})$/, 'The Unique Identifier is the device mac address. For example: 001EC6010101'),
        wireless_number: Yup.string()
            .required('Required'),
        meter_id: Yup.number(),
        address_mapping: Yup.string()
            .when('meter_id', {
                is: (val) => val,
                then: Yup.string()
                    .required('Required')
                    .matches(/^00[1-4]$/, 'The Address Mapping should be 001, 002, 003 or 004'),
                otherwise: Yup.string(),
            }),
        notes: Yup.string()
    });

    const mapObjectToValues = () => {
        const result = {
            type: gateway.gateway.type || 'obvious',
            gateway_id: gateway.gateway.gateway_id,
            unique_identifier: gateway.gateway.unique_identifier || '',
            wireless_number: gateway.gateway.wireless_number || '',
            notes: gateway.gateway.notes || '',
            address_mapping: '',
            meter_id: '',
            associate_meter: '',
        };
        return result;
    };

    const mapValuesToObject = (values) => {
        const result = {
            type: values.type,
            unique_identifier: values.unique_identifier || '',
            wireless_number: values.wireless_number || '',
            notes: values.notes || '',
            meterList: meterList
        };
        if (values.gateway_id) {
            result.gateway_id = values.gateway_id;
        }
        return result;
    };

    const onSubmitAction = (values, setSubmitting) => {

        setSubmitting(false);
        onSubmit(mapValuesToObject(values));
    };

    const handleAssociateOtherMeter = (values) => {
        let meter = unassociatedMeterList.find(meter => meter.meter_id === values.meter_id);
        meter.address_mapping = values.address_mapping;
        setMeterList(oldArray => [...oldArray, meter]);
    };

    const handleRemoveMeter = (meter) => {
        setMeterList(meterList.filter(item => item.meter_id !== meter.meter_id));
    };

    const resetMeterAssociateForm = () => {
        formikRef.current.setFieldValue('meter_id');
        formikRef.current.setFieldValue('address_mapping');
    };
    
    return (
        <Formik
            initialValues={mapObjectToValues()}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={validationRules}
            ref={formikRef}
            onSubmit={(values, {setSubmitting}) => {
                onSubmitAction(values, setSubmitting)
            }}
        >
            {({handleSubmit, errors, values, handleChange, handleBlur, touched, isSubmitting}) => {
                return (
                    unassociatedMeters.isFetching ? <LoadingSpinner/> :
                    <form onSubmit={handleSubmit} className={classes.form} noValidate>
                        <Paper className={classes.root}>
                            <Grid container>
                                <Grid item sm={12}>
                                    <TextField
                                        select
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.type && touched.type}
                                        id="type"
                                        label="Device Type"
                                        name="type"
                                        value={values.type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.type && touched.type) && errors.type}
                                        className={classes.textFieldLeft}
                                    >
                                        <MenuItem value="obvious">Obvious</MenuItem>
                                    </TextField>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.unique_identifier && touched.unique_identifier}
                                        id="unique_identifier"
                                        label="Unique Identifier"
                                        name="unique_identifier"
                                        value={values.unique_identifier}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.unique_identifier && touched.unique_identifier) && errors.unique_identifier}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.wireless_number && touched.wireless_number}
                                        id="wireless_number"
                                        label="Wireless Number"
                                        name="wireless_number"
                                        value={values.wireless_number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.wireless_number && touched.wireless_number) && errors.wireless_number}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        variant="outlined"
                                        id="notes"
                                        label="Notes"
                                        error={errors.notes && touched.notes}
                                        multiline
                                        rowsMax="4"
                                        value={values.notes}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.notes && touched.notes) && errors.notes}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: '32px'}}>
                                <Grid item sm={8} style={{marginBottom: '16px'}}>
                                    <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                        Associated Meters
                                    </Typography>
                                </Grid>
                                <Grid item sm={4} style={{marginBottom: '16px', textAlign: 'right'}}>
                                    {(meterList.length < 4) && <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            setMeterAssociateForm(true);
                                        }}
                                    >
                                        Associate {!!meterList.length ? 'Other' : ''} Meter <MeterIcon/>
                                    </Button>}
                                </Grid>
                            </Grid>
                            {meterAssociateForm && <Grid container>
                                <Grid item sm={12}>
                                    <TextField
                                        select
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.meter_id && touched.meter_id}
                                        id="meter_id"
                                        label="Meter Number"
                                        name="meter_id"
                                        value={values.meter_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.meter_id && touched.meter_id) && errors.meter_id}
                                        className={classes.textFieldLeft}
                                    >
                                        {!!unassociatedMeterList.length && unassociatedMeterList.map((meter) => (
                                            <MenuItem key={meter.meter_id} value={meter.meter_id}>{meter.meter_number}</MenuItem>
                                        ))}
                                        {!unassociatedMeterList.length &&
                                        <MenuItem value="">You don't have meters free to associate </MenuItem>}
                                    </TextField>
                                    <TextField
                                        select
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.address_mapping && touched.address_mapping}
                                        id="address_mapping"
                                        label="Address Mapping"
                                        name="address_mapping"
                                        value={values.address_mapping}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.address_mapping && touched.address_mapping) && errors.address_mapping}
                                        className={classes.textField}
                                    >
                                        {addressMappingList.map((addressMapping) => (
                                            !addressMapping.disabled && <MenuItem key={addressMapping.name} value={addressMapping.name}>{addressMapping.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item sm={12} style={{textAlign: 'right'}}>
                                    <Button
                                        variant="contained"
                                        disabled={isSubmitting}
                                        color="default"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            setMeterAssociateForm(false);
                                            resetMeterAssociateForm();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={isSubmitting || !values.address_mapping || !values.meter_id}
                                        color="primary"
                                        onClick={() => {
                                            handleAssociateOtherMeter(values);
                                            setMeterAssociateForm(false);
                                            resetMeterAssociateForm();
                                        }}
                                    >
                                        Associate
                                    </Button>
                                </Grid>
                            </Grid>}
                            <Grid container>
                                {!!meterList.length && <GatewayMeterTable
                                    meters={meterList}
                                    onRemoveMeter={handleRemoveMeter}
                                />}
                            </Grid>
                        </Paper>
                        <Grid container direction={'row-reverse'}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    disabled={isSubmitting}
                                    color="default"
                                    className={classes.closeButton}
                                    component={Link}
                                    to="/gateways"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isSubmitting}
                                    color="primary"
                                    className={classes.submit}
                                >
                                    {isFetching && !gateway.gateway.gateway_id && <span>Creating Gateway ...</span>}
                                    {!isFetching && !gateway.gateway.gateway_id && <span>Create</span>}
                                    {isFetching && gateway.gateway.gateway_id && <span>Updating Gateway ...</span>}
                                    {!isFetching && gateway.gateway.gateway_id && <span>Update</span>}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Formik>
    );
};

GatewayForm.propTypes = {
    onSubmit: PropTypes.func,
    isFetching: PropTypes.bool
};

export default GatewayForm;


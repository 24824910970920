import React from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const LoginForm = (props) => {
    const classes = useStyles();

    const validationRules = Yup.object().shape({
        email: Yup.string()
            .email()
            .required('Required'),
        password: Yup.string()
            .required('Required')
    });

    const onSubmitAction = (values, setSubmitting) => {
        setTimeout(() => {
            setSubmitting(false);

            //calling father prop:
            props.onSubmit(values);
        }, 400);
    };

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationRules}
            onSubmit={(values, { setSubmitting }) => {
               onSubmitAction(values, setSubmitting)
            }}
        >
            {({handleSubmit, errors, values, handleChange, handleBlur, touched, isSubmitting}) => {

                return (
                    <form onSubmit={handleSubmit} className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            error={errors.email && touched.email}
                            id="email"
                            label="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={(errors.email && touched.email) && errors.email}
                            fullWidth
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            type="password"
                            error={errors.password && touched.password}
                            id="password"
                            label="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={(errors.password && touched.password) && errors.password}
                            fullWidth
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting}
                            color="primary"
                            className={classes.submit}
                            fullWidth
                        >
                            { props.isFetching && <span>Loggin in ...</span>}
                            { !props.isFetching && <span>Sign In</span>}
                        </Button>
                    </form>
                );
            }}
        </Formik>
    );
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func,
    isFetching: PropTypes.bool
};

export default LoginForm;


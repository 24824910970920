import React, { forwardRef } from 'react';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import moment from 'moment-timezone';
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Fab from '@material-ui/core/Fab';
import BuildIcon from '@material-ui/icons/Build';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { Refresh } from '@material-ui/icons';
import PowerIcon from '@material-ui/icons/Power';
import TimeLineIcon from '@material-ui/icons/Timeline';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable, {MTableHeader} from "material-table";
import {Link} from "react-router-dom";
import { rebootMeter } from '../../../actions/meterActions';
import {connect} from "react-redux";

const momentParseFormat = 'YYYY-MM-DD HH:mm:ss';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    fab: {
        margin: theme.spacing(0.5),
        textAlign: 'center'
    },
    progress: {
        margin: theme.spacing(0.5),
    },
}));

const MeterTable = ({dashboard, meters, paginator, fetchMeters, updateMeterStatus, searchInput, orderColumn, orderDirection, rebootMeter, meterReboot, ilonMeterList}) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
  
    const handleConfigureMeter = meter => {
        console.log('Getting hash... and change meter status to `configuring`');
        changeMeterStatus(meter, 'configuring');
    };

    const handleInstallMeter = meter => {
        console.log('Change meter status to `installed`');
        changeMeterStatus(meter, 'installed');
    };

    const changeMeterStatus = (meter, status) => {
        updateMeterStatus(meter.meter_id, status);
    };

    /*    const renderActions = (meter) => {
            if (loading) {
                return <LoadingSpinner/>;
            }

            return (
                <div>


                </div>
            );

        };*/

    // const CheckGreenIcon = withStyles(theme => ({
    //     root: {
    //         color: theme.palette.getContrastText(green[300]),
    //         backgroundColor: green[300],
    //         '&:hover': {
    //             backgroundColor: green[700],
    //         },
    //     },
    // }))(Fab);

    const LoadingSpinner = () => {
        return (
            <CircularProgress className={classes.progress}/>
        );
    };

    const handleChangePage = (event, newPage) => {
        fetchMeters(newPage, searchInput, orderColumn, orderDirection);
    };

    const onSearchChange = (search) => {
        fetchMeters(0, search)
    };

    const onSortChange = (orderedColumnId, orderDirection) => {
        fetchMeters(paginator.currentPage, searchInput, columns[orderedColumnId].field, orderDirection);
    };

    const getSortColumnId = (orderColumn) => {
        return columns.findIndex(column => column.field === orderColumn);
    };

    const toggleIlonButton = (meterID) => {
        rebootMeter(meterID)
    }

    const columns = [
        {title: "Meter ID", field: "meter_id"},
        {title: "Meter Number", field: "meter_number", type: "numeric"},
        {title: "Account Name", field: "account_name", sorting: false},
        {title: "Account Number", field: "account_number", type: "numeric", sorting: false},
        {title: "Pulse Weight", field: "pulse_weight", sorting: false},
        {
            title: "Last Reading Time", render: rowData => {
                let lastReadingTime = '-';
                if (rowData.last_reading_time) {
                    lastReadingTime = moment(rowData.last_reading_time, momentParseFormat);
                    lastReadingTime = lastReadingTime.tz(moment.tz.guess()).format(momentParseFormat);
                }
                return (
                    lastReadingTime
                )
            }
        },
        {
            title: "Last Reading Insert Time", render: rowData => {
                let lastReadingInsertTime = '-';
                if (rowData.last_reading_insert_time) {
                    lastReadingInsertTime = moment(rowData.last_reading_insert_time, momentParseFormat);
                    lastReadingInsertTime = lastReadingInsertTime.tz(moment.tz.guess()).format(momentParseFormat);
                }

                return (
                    lastReadingInsertTime
                )
            }
        },
        {
            title: "Last Meter Check", render: rowData => {
                let lastMeterCheck = '-';
                if (rowData.last_meter_check) {
                    lastMeterCheck = moment(rowData.last_meter_check, momentParseFormat);
                    lastMeterCheck = lastMeterCheck.tz(moment.tz.guess()).format(momentParseFormat);
                }

                return (
                    lastMeterCheck
                )
            }
        },
        {title: "Flat Readings", sorting: false, render: rowData => rowData.flat_readings === 1 ? 'Yes' : 'No'},
        {
            title: "Real-Time Demand",
            sorting: false,
            render: rowData => rowData.last_15_minutes_average_demand ? rowData.last_15_minutes_average_demand.toFixed(2) + 'kW' : '-'
        },
        {
            title: "Status", render: rowData => { //This is only to display status when you are not in dashboard
                if (!dashboard) {
                    return (
                        rowData.status
                    )
                }
            }
        },
    ];

    if (meters) {
        return (
            <>
            {meterReboot && meterReboot.isFetching && <LoadingSpinner/>}
            {meterReboot && meterReboot.error && <h2>There was an error rebooting Ilon device.</h2>}
            <Paper className={classes.root}>
                <MaterialTable
                    columns={columns}
                    data={meters}
                    title="Meters Table"
                    options={{
                        pageSize: 20,
                        debounceInterval: 800,
                        searchText: searchInput,
                        actionsColumnIndex: 14

                    }}
                    onSearchChange={onSearchChange}
                    components={{
                        Header: props => (
                            <MTableHeader {...props} onOrderChange={onSortChange} orderBy={getSortColumnId(orderColumn)}
                                          orderDirection={orderDirection}/>
                        ),
                        Pagination: () => (
                            <TablePagination
                                page={paginator.currentPage}
                                count={paginator.totalRecords}
                                rowsPerPageOptions={[]}
                                rowsPerPage={20}
                                onChangePage={handleChangePage}
                            />
                        )
                    }}
                    icons={{
                        ConfigureFabButton: forwardRef((props, ref) => (
                            <Fab ref={ref} props={props} color="primary" className={classes.fab} size="small">
                                <BuildIcon/>
                            </Fab>
                        ))
                    }}
                    actions={[
                            rowData => (
                                {
                                    icon: () => {
                                        return (
                                            <Tooltip title="Edit Meter" aria-label="Edit Meter">
                                                <Fab color="primary" className={classes.fab} size="small" component={Link}
                                                     to={`meters/${rowData.meter_id}`}>
                                                    <EditIcon/>
                                                </Fab>
                                            </Tooltip>
                                        );
                                    }
                                }),
                            rowData => (
                                {
                                    icon: () => {
                                        return (
                                            <Tooltip title="Real Time Demand" aria-label="Real Time Deman">
                                                <Fab color="primary" className={classes.fab} size="small" component={Link}
                                                     to={`meters/${rowData.meter_id}/readings`}>
                                                    <TimeLineIcon/>
                                                </Fab>
                                            </Tooltip>
                                        );
                                    },
                                    hidden: rowData.status !== 'active'
                                }),
                            rowData => (
                                {
                                    icon: () => {
                                        return (<Fab color="primary" className={classes.fab} size="small" >
                                        <Refresh />
                                   </Fab>
                                            );
                                    },
                                    tooltip: "Reboot",
                                    onClick: (event, meter) => {
                                        toggleIlonButton(rowData.meter_id)
                                    },
                                    hidden: !(ilonMeterList.includes(rowData.meter_id))
                                }),
                            rowData => (
                                {
                                    icon: () => {
                                        return (<BuildIcon />)
                                    },
                                    tooltip: "Configure",
                                    onClick: (event, meter) => {
                                        handleConfigureMeter(meter);
                                    },
                                    hidden: !(rowData.status === 'initialized' && rowData.pulse_weight)
                                }),
                            rowData => (
                                {
                                    icon: () => {
                                        return (<PowerIcon />);
                                    },
                                    tooltip: "Install",
                                    onClick: (event, meter) => {
                                        handleInstallMeter(meter);
                                    },
                                    hidden: rowData.status !== 'configured'
                                }),
                            rowData => (
                                {
                                    icon: () => {
                                        return (<ReportProblemIcon />);
                                    },
                                    tooltip: "Problem",
                                    onClick: (event, meter) => {
                                        // TODO
                                    },
                                    hidden: rowData.status !== 'issue'
                                })
                        ]}
                />
            </Paper>
            </>
            
        );
    }
};

MeterTable.defaultProps = {
    dashboard: false
};

MeterTable.propTypes = {
    dashboard: PropTypes.bool,
    meters: PropTypes.array,
    paginator: PropTypes.object,
    fetchMeters: PropTypes.func,
    updateMeterStatus: PropTypes.func,
    searchInput: PropTypes.string,
    orderColumn: PropTypes.string,
    orderDirection: PropTypes.string
};

const mapStateToProps = (state) => ({
    meterReboot: state.meterReboot  
  });
  

const mapDispatchToProps = {
    rebootMeter
};

export default connect(mapStateToProps, mapDispatchToProps)(MeterTable);

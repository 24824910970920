import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(theme => ({
    closeButton: {
        borderRadius: `4px !important`,
        marginRight: `16px !important`
    },
}));


const MaterCreationDialog = ({openMeterCreationDialog, setOpenMeterCreationDialog, account}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={openMeterCreationDialog}
            TransitionComponent={Transition}
            keepMounted
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"Create Meter"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    The Account was created successfully. Do you want to create a new meter associated with this
                    account?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Fab
                    color="default"
                    size="small"
                    component={Link}
                    to={`/accounts`}
                    variant={'extended'}
                    className={classes.closeButton}
                >
                    No
                </Fab>
                <Fab
                    color="primary"
                    size="small"
                    component={Link}
                    to={`/meters/create?account_id=${account.account_id}`}
                    variant={'extended'}
                    className={classes.closeButton}
                >
                    Yes
                </Fab>
            </DialogActions>
        </Dialog>
    );
};

export default MaterCreationDialog;

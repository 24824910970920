import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import {Link} from "react-router-dom";
import MaterCreationDialog from "./MeterCreationDialog";
import Autocomplete from '@material-ui/lab/Autocomplete';
import UserTable from "./UserTable";
import AddUserIcon from '@material-ui/icons/AddCircle';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formBox: {
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2, 4, 4, 4),
        borderRadius: '4px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);'
    },
    columnTitle: {
        width: `calc(100% - 8px)`,
        margin: '16px 8px 0',
    },
    autocomplete: {
        margin: theme.spacing(2, 2, 1, 0),
        width: `calc((100% - 16px)/2)`,
        display: 'inline-flex'
    },
    textFieldLeft: {
        marginRight: theme.spacing(2),
        width: `calc((100% - 16px)/2)`
    },
    textField: {
        width: `calc((100% - 16px)/2)`
    },
    spaceBetween: {
        marginRight: theme.spacing(2),
    },
    closeButton: {
        borderRadius: `4px !important`
    }
}));

const UsersForm = ({
                       onStoreForm,
                       onSubmit,
                       formValues,
                       prevStep,
                       getUsers,
                       users,
                       showCreateNewUserForm,
                       onShowCreateNewUserForm,
                       editionMode,
                       accountCreate
                   }) => {
    const classes = useStyles();
    const [openMeterCreationDialog, setOpenMeterCreationDialog] = React.useState(false);
    const [usersList, setUsersList] = React.useState(formValues.users);
    const [showAddUserForm, setShowAddUserForm] = React.useState(formValues.users.length === 0);
    const [open, setOpen] = React.useState(false);
    const [userSelected, setUserSelected] = React.useState(false);
    const formikRef = useRef();

    const userSettingsDefaultValues = () => {
        return {
            email_notification: false,
            message_notification: false,
            phone_notification: false,
            app_notification: false,
            alarm_notification: false
        };
    };

    const [userSettings, setUserSettings] = React.useState(userSettingsDefaultValues());

    useEffect(() => {
        if (!(users.data && users.data.length)) {
            getUsers(1);
        }
    }, []);

    useEffect(() => {
        if (!usersList.length) {
            setShowAddUserForm(true);
        }
        // mapValuesToField(formValues.users);
    }, [usersList]);

    useEffect(() => {
        if (!accountCreate.isFetching && accountCreate.account && accountCreate.account.account_id) {
            setOpenMeterCreationDialog(true);
        }
    }, [accountCreate]);


    const validationRules = Yup.object().shape({
        user_id: Yup.string(),
        email: Yup.string()
            .required('Required'),
        contact_type: Yup.number()
            .required('Required'),
        first_name: Yup.string()
            .required('Required'),
        last_name: Yup.string()
            .required('Required'),
        password: Yup.string()
            .when('user_id', {
                is: (val) => val,
                then: Yup.string(),
                otherwise: Yup.string()
                    .required('Required')
                    .min(8, 'Password must be 8 characters long')
                    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_#])[A-Za-z\d$@$!%*?&_#]/, 'Must contain a number, one uppercase character, one lowercase character and a special character such as $, @, !, %, *, ?, &, _, #')
            }),
        repeat_password: Yup.string()
            .when('user_id', {
                is: (val) => val,
                then: Yup.string(),
                otherwise: Yup.string()
                    .required('Required')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
            }),
        primary_phone: Yup.string().matches(/[\d() -]+$/, {message: 'Format valid: (999) 999-9999'})
            .required('Required'),
        secondary_phone: Yup.string().matches(/[\d() -]+$/, {message: 'Format valid: (999) 999-9999'})
    });

    const mapUsersToObject = values => {
        formValues.users.push({
            email: values.email,
            contact_type: values.contact_type,
            first_name: values.first_name,
            last_name: values.last_name,
            primary_phone: values.primary_phone,
            secondary_phone: values.secondary_phone,
            password: values.password
        });
    };

    const mapUsersListToObject = () => {
        const users = usersList.map(user => {
            if (user.user_id) {
                return {
                    user_id: user.user_id,
                    contact_type: user.contact_type,
                    user_setting: user.user_setting
                };
            } else {
                return {
                    email: user.email,
                    contact_type: user.contact_type,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    primary_phone: user.primary_phone,
                    secondary_phone: user.secondary_phone || '',
                    password: user.password,
                    user_setting: user.user_setting
                };
            }
        });
        return users;
    };

    const mapObjectToValues = () => {
        return {
            user_id: '',
            email: '',
            contact_type: '',
            first_name: '',
            last_name: '',
            primary_phone: '',
            secondary_phone: '',
            password: '',
            repeat_password: '',
            user_setting: {}
        };
    };

    const handleCheckboxChange = (name) => (event) => {
        setUserSettings({...userSettings, [name]: event.target.checked});
    };

    const goBack = () => {
        if (editionMode) {
            onStoreForm({users: usersList});
        }
        prevStep();
    };

    const userListHaveAPrimeryUser = () => {
        const primaryUser = usersList.filter(user => {
            return user.contact_type === 1;
        });
        return !!primaryUser.length;
    };

    const closeForm = () => {
        setShowAddUserForm(false);
        onShowCreateNewUserForm(false);
        setUserSettings(userSettingsDefaultValues());
    };

    const addNewUser = (values) => {
        values.user_setting = userSettings;
        setUsersList(oldArray => [...oldArray, values]);
    };

    const deleteUser = (rowData) => {
        setUsersList(usersList.filter(item => item.tableData.id !== rowData.tableData.id));
    };

    const mapValuesToField = (value) => {
        if (value) {
            formikRef.current.setFieldValue('user_id', value.user_id || '');
            formikRef.current.setFieldValue('email', value.email || '');
            formikRef.current.setFieldValue('first_name', value.first_name || '');
            formikRef.current.setFieldValue('last_name', value.last_name || '');
            formikRef.current.setFieldValue('primary_phone', value.primary_phone || '');
            formikRef.current.setFieldValue('secondary_phone', value.secondary_phone || '');
        }
        if (value === null) {
            formikRef.current.resetForm();
            mapValuesToField({});
        }
    };


    const onChangeEmail = (event) => {
        formikRef.current.setFieldValue('user_id', '');
        formikRef.current.setFieldValue('email', event.target.value || '');
        formikRef.current.setFieldValue('first_name', '');
        formikRef.current.setFieldValue('last_name', '');
        formikRef.current.setFieldValue('primary_phone', '');
        formikRef.current.setFieldValue('secondary_phone', '');
    }

    const onBlurUserEmail = (event) => {
        const userAdded = usersList.find(item => item.email === event.target.value);
        setUserSelected(!!(userAdded && userAdded.user_id));
    };

    const onChangeUserEmail = (event, value) => {
        mapValuesToField(value);
    };

    const renderContactTypeField = (errors, values, handleChange, handleBlur, touched) => {
        return (<TextField
            select
            variant="outlined"
            margin="normal"
            error={errors.contact_type && touched.contact_type}
            id="contact_type"
            label="Type"
            name="contact_type"
            value={values.contact_type}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={(errors.contact_type && touched.contact_type) && errors.contact_type}
            className={classes.textField}
        >
            <MenuItem value={1}
                      disabled={userListHaveAPrimeryUser()}>Primary</MenuItem>
            <MenuItem value={2}>Secondary</MenuItem>
        </TextField>);
    }

    const renderUserSettingsFields = () => {
        return (<Grid container>
            <Grid item sm={12}>
                <Typography variant="h6" component="h6"
                            className={classes.columnTitle}>
                    Notifications
                </Typography>
            </Grid>
            <Grid item sm={2}>
                <FormControlLabel
                    control={<Checkbox
                        checked={userSettings.email_notification}
                        onChange={handleCheckboxChange('email_notification')}
                        color="primary"/>}
                    label="Email"/>
            </Grid>
            <Grid item sm={2}>
                <FormControlLabel
                    control={<Checkbox
                        checked={userSettings.phone_notification}
                        onChange={handleCheckboxChange('phone_notification')}
                        color="primary"/>}
                    label="Phone"/>
            </Grid>
            <Grid item sm={2}>
                <FormControlLabel
                    control={<Checkbox
                        checked={userSettings.message_notification}
                        onChange={handleCheckboxChange('message_notification')}
                        color="primary"/>}
                    label="Text/SMS"/>
            </Grid>
            <Grid item sm={2}>
                <FormControlLabel
                    control={<Checkbox
                        checked={userSettings.alarm_notification}
                        onChange={handleCheckboxChange('alarm_notification')}
                        color="primary"/>}
                    label="Alarm"/>
            </Grid>
            <Grid item sm={2}>
                <FormControlLabel
                    control={<Checkbox
                        checked={userSettings.app_notification}
                        onChange={handleCheckboxChange('app_notification')}
                        color="primary"/>}
                    label="App"/>
            </Grid>
        </Grid>);
    };

    return (
        <div>
{/*            <MaterCreationDialog
                openMeterCreationDialog={openMeterCreationDialog}
                setOpenMeterCreationDialog={setOpenMeterCreationDialog}
                account={accountCreate.account}
            />*/}
            <Grid container justify={'center'}>
                {!!usersList.length && <Grid item sm={8} style={{marginBottom: '32px'}}>
                    <Grid container>
                        <Grid item sm={10} style={{marginBottom: '16px'}}>
                            <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                Users List
                            </Typography>
                        </Grid>
                        <Grid item sm={2} style={{marginBottom: '16px'}}>
                            {editionMode && <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    onShowCreateNewUserForm(true);
                                    setShowAddUserForm(true);
                                }}
                            >
                                Add User <AddUserIcon/>
                            </Button>}
                        </Grid>
                    </Grid>
                    <UserTable users={usersList} onDelete={deleteUser} showRemove={editionMode}/>
                </Grid>}
                <Grid item sm={8}>
                    <Grid>
                        {showAddUserForm && <Grid>
                            <Grid item sm={12} className={classes.userFormContainer}>
                                <Grid item sm={12}>
                                    <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                        User Information
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} className={classes.formBox}>
                                    <Formik
                                        validateOnBlur={true}
                                        validateOnChange={true}
                                        enableReinitialize={true}
                                        ref={formikRef}
                                        initialValues={mapObjectToValues()}
                                        validationSchema={validationRules}
                                        onSubmit={(values, {resetForm}) => {
                                            addNewUser(values);
                                            resetForm();
                                            closeForm();
                                        }}
                                    >
                                        {({handleSubmit, errors, values, handleChange, handleBlur, touched}) => {
                                            if (userSelected) {
                                                errors.email = "This email is already added for this account";
                                                touched.email = true;
                                            } else {
                                                if (errors.email !== "Required") {
                                                    delete errors.email;
                                                }
                                            }
                                            return (
                                                <form onSubmit={handleSubmit} className={classes.form} noValidate>
                                                    <Grid container justify={'center'}>
                                                        <Grid item sm={12}>
                                                            <Grid container>
                                                                <Autocomplete
                                                                    id="field_1_autocomplete"
                                                                    open={open}
                                                                    onOpen={() => {
                                                                        setOpen(true);
                                                                    }}
                                                                    onClose={() => {
                                                                        setOpen(false);
                                                                    }}
                                                                    disabled={!editionMode}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    className={classes.autocomplete}
                                                                    getOptionLabel={option => option.email}
                                                                    onChange={onChangeUserEmail}
                                                                    onBlur={onBlurUserEmail}
                                                                    options={users.data}
                                                                    filterSelectedOptions={true}
                                                                    loading={users.isFetching}
                                                                    freeSolo={true}
                                                                    autoHighlight={true}
                                                                    defaultValue={() => {
                                                                        if (values.email) {
                                                                            return values;
                                                                        }
                                                                        return;
                                                                    }}
                                                                    disableOpenOnFocus={true}
                                                                    renderOption={(option, {inputValue}) => {
                                                                        const matches = match(option.email, inputValue);
                                                                        const parts = parse(option.email, matches);

                                                                        return (
                                                                            <div>
                                                                                {parts.map((part, index) => (
                                                                                    <span key={index}
                                                                                          style={{fontWeight: part.highlight ? 700 : 400}}>
                                                                                            {part.text}
                                                                                    </span>
                                                                                ))}
                                                                            </div>
                                                                        );
                                                                    }}
                                                                    renderInput={params => (
                                                                        <TextField
                                                                            {...params}
                                                                            id="field_1"
                                                                            label="Email"
                                                                            variant="outlined"
                                                                            autoComplete="field_1"
                                                                            value={values.email}
                                                                            error={errors.email && touched.email}
                                                                            onChange={onChangeEmail}
                                                                            name="field_1"
                                                                            helperText={(errors.email && touched.email) && errors.email}
                                                                            /*InputProps={{
                                                                                ...params.InputProps,
                                                                                endAdornment: (
                                                                                    <React.Fragment>
                                                                                        {!!users.isFetching ?
                                                                                            <CircularProgress
                                                                                                color="inherit"
                                                                                                size={20}/> : null}
                                                                                        {params.InputProps.endAdornment}
                                                                                    </React.Fragment>
                                                                                ),
                                                                            }}*/
                                                                        />
                                                                    )}
                                                                />
                                                                <TextField
                                                                    id="user_id"
                                                                    name="user_id"
                                                                    value={values.user_id}
                                                                    type="hidden"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                                {renderContactTypeField(errors, values, handleChange, handleBlur, touched)}
                                                                <TextField
                                                                    disabled={!!values.user_id}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.first_name && touched.first_name}
                                                                    id="first_name"
                                                                    label="First Name"
                                                                    name="first_name"
                                                                    value={values.first_name}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.first_name && touched.first_name) && errors.first_name}
                                                                    className={classes.textFieldLeft}
                                                                />
                                                                <TextField
                                                                    disabled={!!values.user_id}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.last_name && touched.last_name}
                                                                    id="last_name"
                                                                    label="Last Name"
                                                                    name="last_name"
                                                                    value={values.last_name}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.last_name && touched.last_name) && errors.last_name}
                                                                    className={classes.textField}
                                                                />
                                                                <TextField
                                                                    disabled={!!values.user_id}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.password && touched.password}
                                                                    id="password"
                                                                    label="Password"
                                                                    name="password"
                                                                    type="password"
                                                                    value={values.password}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.password && touched.password) && errors.password}
                                                                    className={classes.textFieldLeft}
                                                                />
                                                                <TextField
                                                                    disabled={!!values.user_id}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.repeat_password && touched.repeat_password}
                                                                    id="repeat_password"
                                                                    label="Repeat Password"
                                                                    name="repeat_password"
                                                                    type="password"
                                                                    value={values.repeat_password}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.repeat_password && touched.repeat_password) && errors.repeat_password}
                                                                    className={classes.textField}
                                                                />
                                                                <TextField
                                                                    disabled={!!values.user_id}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.primary_phone && touched.primary_phone}
                                                                    id="primary_phone"
                                                                    label="Primary Phone"
                                                                    name="primary_phone"
                                                                    value={values.primary_phone}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.primary_phone && touched.primary_phone) && errors.primary_phone}
                                                                    className={classes.textFieldLeft}
                                                                />
                                                                <TextField
                                                                    disabled={!!values.user_id}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.secondary_phone && touched.secondary_phone}
                                                                    id="secondary_phone"
                                                                    label="Secondary Phone"
                                                                    name="secondary_phone"
                                                                    value={values.secondary_phone}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.secondary_phone && touched.secondary_phone) && errors.secondary_phone}
                                                                    className={classes.textField}
                                                                />
                                                            </Grid>
                                                            {renderUserSettingsFields(errors, values, handleChange, handleBlur, touched)}
                                                            <Grid container direction={'row-reverse'}>
                                                                <Grid item>
                                                                    {!!usersList.length && <Button
                                                                        onClick={() => {
                                                                            closeForm();
                                                                        }}
                                                                        className={classes.backButton}
                                                                    >
                                                                        Cancel
                                                                    </Button>}
                                                                    <Button
                                                                        variant="contained"
                                                                        type="submit"
                                                                        color="primary"
                                                                        className={classes.submit}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </form>
                                            );
                                        }}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </Grid>}
                        <Grid container direction={'row-reverse'}>
                            <Grid item>
                                <Button
                                    onClick={() => goBack()}
                                    className={classes.backButton}
                                >
                                    Back
                                </Button>
                                {editionMode && <Button
                                    onClick={() => {
                                        formValues.users = mapUsersListToObject(usersList);
                                        onSubmit(formValues);
                                    }}
                                    variant="contained"
                                    color="primary"
                                    disabled={!userListHaveAPrimeryUser()}
                                    className={classes.submit}
                                >
                                    Submit
                                </Button>}
                                {!editionMode && <Fab
                                    color="primary"
                                    size="medium"
                                    component={Link}
                                    to={`/accounts`}
                                    variant={'extended'}
                                    className={classes.closeButton}
                                >
                                    Close
                                </Fab>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

UsersForm.propTypes = {
    onSubmit: PropTypes.func,
    prevStep: PropTypes.func,
    isFetching: PropTypes.bool
};

export default UsersForm;


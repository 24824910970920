import React from 'react';
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {connect} from "react-redux";
import DashboardPage from "./pages/dashboard/DashboardPage";
import LoginPage from "./pages/login/LoginPage";
import MeterPage from "./pages/meter/MeterPage";
import MeterCreatePage from "./pages/meter-create/MeterCreatePage";
import AccountPage from "./pages/account/AccountPage";
import AccountCreatePage from "./pages/account-create/AccountCreatePage";
import AboutPage from "./pages/about/AboutPage";
import {makeStyles} from "@material-ui/core";
import PrivateRoute from "./PrivateRoute";
import history from '../history';
import './App.css';
import TopBar from "./partials/TopBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import DrawerMenu from "./partials/DrawerMenu";
import {logoutUser} from "../actions/authActions";
import {tenantChange} from "../actions/tenantActions";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import ReadingPage from "./pages/meter/RealTimeEnergyPage";
import BusinessPage from "./pages/business/BusinessPage";
import BusinessEditionPage from "./pages/business/BusinessEditionPage";
import UserPage from "./pages/users/UserPage";
import UserEditionPage from "./pages/users/UserEditionPage";
import AccountEditionPage from "./pages/account/AccountEditionPage";
import AccountViewPage from "./pages/account/AccountViewPage";
import AccountUserPage from "./pages/account/AccountUserPage";
import GatewayPage from "./pages/gateway/GatewayPage";
import GatewayCreatePage from "./pages/gateway-create/GatewayCreatePage";

// Or Create your Own theme:
const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#20409A'
            }
        }
    },
);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const App = ({auth, logoutUser, tenant, tenantChange}) => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        logoutUser();
    };

    const handleTenantChange = (tenant) => {
        tenantChange(tenant);
    };

    const renderContent = () => {
        return (
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Switch>
                    <Route path="/login" exact component={LoginPage}/>
                    <PrivateRoute path="/dashboard" exact component={DashboardPage}/>
                    <PrivateRoute path="/meters" exact component={MeterPage}/>
                    <PrivateRoute path="/meters/create" exact component={MeterCreatePage}/>
                    <PrivateRoute path="/meters/:id/readings" exact component={ReadingPage}/>
                    <PrivateRoute path="/meters/:id" exact component={MeterCreatePage}/>
                    <PrivateRoute path="/gateways" exact component={GatewayPage}/>
                    <PrivateRoute path="/gateways/create" exact component={GatewayCreatePage}/>
                    <PrivateRoute path="/gateways/:id" exact component={GatewayCreatePage}/>
                    <PrivateRoute path="/business" exact component={BusinessPage}/>
                    <PrivateRoute path="/business/:id" exact component={BusinessEditionPage}/>
                    <PrivateRoute path="/users" exact component={UserPage}/>
                    <PrivateRoute path="/users/:id" exact component={UserEditionPage}/>
                    <PrivateRoute path="/accounts" exact component={AccountPage}/>
                    <PrivateRoute path="/accounts/create" exact component={AccountCreatePage}/>
                    <PrivateRoute path="/accounts/:id/view" exact component={AccountViewPage}/>
                    <PrivateRoute path="/accounts/:id/users" exact component={AccountUserPage}/>
                    <PrivateRoute path="/accounts/:id" exact component={AccountEditionPage}/>
                    <PrivateRoute path="/about" exact component={AboutPage}/>
                    <Redirect to="/dashboard"/>
                </Switch>
            </main>
        );
    };

    return (
        <Router history={history}>
            <MuiThemeProvider theme={theme}>
                <div className={classes.root}>
                    <CssBaseline/>
                    <TopBar
                        drawerOpen={open}
                        drawerOpenHandle={handleDrawerOpen}
                        isAuthenticated={auth.isAuthenticated}
                        userLogged={auth.user}
                        logoutUser={handleLogout}
                        onTenantChange={handleTenantChange}
                        tenant={tenant.tenant}/>

                    <DrawerMenu
                        drawerOpen={open}
                        drawerCloseHandle={handleDrawerClose}
                        isAuthenticated={auth.isAuthenticated}/>

                    {renderContent()}
                </div>
            </MuiThemeProvider>
        </Router>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
    tenant: state.tenant,
});

const mapDispatchToProps = {
    logoutUser,
    tenantChange
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

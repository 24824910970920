import React from 'react';
import PropTypes from "prop-types";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Home';
import RouterIcon from '@material-ui/icons/Router';
import MemoryIcon from '@material-ui/icons/Memory';
import BusinessIcon from '@material-ui/icons/Business';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import UsersIcon from '@material-ui/icons/Group';
import {Link} from "react-router-dom";


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    drawer: {
        width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
            transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
}));

const DrawerMenu = ({isAuthenticated, drawerOpen, drawerCloseHandle}) => {
    const classes = useStyles();
    const theme = useTheme();

    if (!isAuthenticated){
        return null;
    }

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: drawerOpen,
                    [classes.drawerClose]: !drawerOpen,
                }),
            }}
            open={drawerOpen}
        >
            <div className={classes.toolbar}>
                <IconButton onClick={drawerCloseHandle}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
            <Divider />
            <List>
                <ListItem component={Link} to="/" button >
                    <ListItemIcon><DashboardIcon /></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem component={Link} to="/accounts" button >
                    <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                    <ListItemText primary="Accounts" />
                </ListItem>
                <ListItem component={Link} to="/business" button >
                    <ListItemIcon><BusinessIcon /></ListItemIcon>
                    <ListItemText primary="Business" />
                </ListItem>
                <ListItem component={Link} to="/meters" button >
                    <ListItemIcon><MemoryIcon /></ListItemIcon>
                    <ListItemText primary="Meters" />
                </ListItem>
                <ListItem component={Link} to="/gateways" button >
                    <ListItemIcon><RouterIcon /></ListItemIcon>
                    <ListItemText primary="Gateways" />
                </ListItem>
                <ListItem component={Link} to="/users" button >
                    <ListItemIcon><UsersIcon /></ListItemIcon>
                    <ListItemText primary="Users" />
                </ListItem>
            </List>
        </Drawer>
    )
};

DrawerMenu.propTypes = {
  isAuthenticated: PropTypes.bool,
  drawerOpen: PropTypes.bool,
  drawerCloseHandle: PropTypes.func
};

export default DrawerMenu;

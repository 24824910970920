import React from 'react';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import MaterialTable, {MTableHeader} from "material-table";
import EditIcon from '@material-ui/icons/Edit';
import { Delete } from '@material-ui/icons';
import Tooltip from "@material-ui/core/Tooltip";
import { updateUser } from "../../../actions/userActions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";

const momentParseFormat = 'YYYY-MM-DD HH:mm:ss';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    fab: {
        margin: theme.spacing(0.5),
    },
    progress: {
        margin: theme.spacing(0.5),
    },
}));

const UserTable = ({users, paginator, fetchUsers, searchInput, orderColumn, orderDirection, updateUser, handleReload}) => {
    const classes = useStyles();
    // const dispatch = useDispatch()

    const handleDelete =async (data) => {
        let newData = {...data,disabled_flag:1,reload:true}
        try{
            updateUser(newData);
        }catch(err){
            console.log(err)
        }
        // setTimeout()
    }

    const handleChangePage = (event, newPage) => {
        fetchUsers(newPage, searchInput, orderColumn, orderDirection);
    };

    const onSearchChange = (search) => {
        fetchUsers(0, search)
    };

    const onSortChange = (orderedColumnId, orderDirection) => {
        fetchUsers(paginator.currentPage, searchInput, columns[orderedColumnId].field, orderDirection);
    };

    const getSortColumnId = (orderColumn) => {
        return columns.findIndex(column => column.field === orderColumn);
    };

    const columns = [
        {title: "User ID", field: "user_id"},
        {title: "First Name", field: "first_name"},
        {title: "Last Name", field: "last_name"},
        {title: "Email", field: "email"},
        {title: "Primary Phone", field: "primary_phone", sorting: false},
        {title: "Secondary Phone", field: "secondary_phone", sorting: false},
        // {title: "Disabled flag", field: "disabled_flag", sorting: false},
    ];

    if (users) {
        return (
            <MaterialTable
                columns={columns}
                data={users.filter((user) => user.disabled_flag !== 1)}
                title="Users Table"
                options={{
                    pageSize: 20,
                    debounceInterval: 800,
                    searchText: searchInput,
                    actionsColumnIndex: 6
                }}
                onSearchChange={onSearchChange}
                components={{
                    Header: props => (
                        <MTableHeader {...props} onOrderChange={onSortChange} orderBy={getSortColumnId(orderColumn)}
                                      orderDirection={orderDirection}/>
                    ),
                    Pagination: () => (
                        <TablePagination
                            page={paginator.currentPage}
                            count={paginator.totalRecords}
                            rowsPerPageOptions={[]}
                            rowsPerPage={20}
                            onChangePage={handleChangePage}
                        />
                    )
                }}
                actions={[
                    rowData => (
                        {
                            icon: () => {
                                return (
                                    <Tooltip title="Edit" aria-label="Edit">
                                        <Fab color="primary" className={classes.fab} size="small" component={Link}
                                             to={`users/${rowData.user_id}`}>
                                            <EditIcon/>
                                        </Fab>
                                    </Tooltip>
                                );
                            }
                        }),
                        rowData => (
                            {
                                icon: () => {
                                    return (
                                        <Tooltip title="Delete" aria-label="Delete">
                                            <Fab 
                                                color="primary" 
                                                className={classes.fab} 
                                                size="small" 
                                                component={Link} 
                                                onClick={() => {
                                                    if (window.confirm("Are you sure you want to delete the user?")) {
                                                        handleDelete(rowData);
                                                    }
                                                }}
                                            >
                                                <Delete />
                                            </Fab>
                                        </Tooltip>
                                    );
                                }
                            }
                        )
                        
                ]}
            />
        );
    }
    return '';
};

UserTable.defaultProps = {};

UserTable.propTypes = {
    users: PropTypes.array,
    paginator: PropTypes.object,
    fetchUsers: PropTypes.func,
    searchInput: PropTypes.string,
    orderColumn: PropTypes.string,
    orderDirection: PropTypes.string
};

const mapDispatchToProps = {
    updateUser
};

// export default UserTable;
export default connect(null,mapDispatchToProps)(UserTable);

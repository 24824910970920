import coiApi from "../api/coiApi";
import moment from "moment";
import axios from 'axios';
import {getTenant} from '../api/coiApi';

import {
  CHECK_METERS_ERROR,
  CHECK_METERS_REQUEST,
  CHECK_METERS_SUCCESS,
  CREATE_METERS_ERROR,
  CREATE_METERS_REQUEST,
  CREATE_METERS_SUCCESS,
  CREATE_METERS_CLEAN_UP,
  GET_DATA_POINTS_ERROR,
  GET_DATA_POINTS_REQUEST,
  GET_DATA_POINTS_SUCCESS,
  GET_METER_ERROR,
  GET_METER_REQUEST,
  GET_METER_SUCCESS,
  GET_METERS_ERROR,
  GET_METERS_REQUEST,
  GET_METERS_SUCCESS,
  GET_READINGS_ERROR,
  GET_READINGS_REQUEST,
  GET_READINGS_SUCCESS,
  GET_UNASSOCIATED_METERS_ERROR,
  GET_UNASSOCIATED_METERS_REQUEST,
  GET_UNASSOCIATED_METERS_SUCCESS,
  UPDATE_METERS_ERROR,
  UPDATE_METERS_REQUEST,
  UPDATE_METERS_STATUS_ERROR,
  UPDATE_METERS_STATUS_REQUEST,
  UPDATE_METERS_STATUS_SUCCESS,
  UPDATE_METERS_SUCCESS,
  METER_REBOOT_REQUEST,
  METER_REBOOT_SUCCESS, 
  METER_REBOOT_ERROR, 
  GET_ILON_METER_LIST_REQUEST,
  GET_ILON_METER_LIST_ERROR,
  GET_ILON_METER_LIST_SUCCESS,
  GET_ILON_METER_LIST_RESET,
  GET_OUTPUT_TOGGLE_STATUS_REQUEST,
  GET_OUTPUT_TOGGLE_STATUS_SUCCESS,
  GET_OUTPUT_TOGGLE_STATUS_ERROR,
  GET_OUTPUT_TOGGLE_STATUS_RESET,
  OUTPUT_TOGGLE_REQUEST,
  OUTPUT_TOGGLE_SUCCESS,
  OUTPUT_TOGGLE_ERROR
} from "./actionTypes";
import history from "../history";
import { meter } from "../reducers/meterReducer";

export const getMeters =
  (page = 0, search = "", orderColumn = "meter_id", orderDirection = "desc") =>
  async (dispatch) => {
    dispatch({
      type: GET_METERS_REQUEST,
      orderColumn,
      orderDirection,
    });

    try {
      page++;

      const { data } = await coiApi.get(
        `/admin/meter?page=${page}&search=${search}&orderColumn=${orderColumn}&orderDirection=${orderDirection}`
      );

      dispatch({
        type: GET_METERS_SUCCESS,
        meters: data.data,
        currentPage: data.current_page - 1, //It's zero-based index.
        totalRecords: data.total,
        from: data.from,
        to: data.to,
        searchInput: search,
        orderColumn: orderColumn,
        orderDirection: orderDirection,
      });
    } catch (err) {
      const msg = err.response.data.message
        ? err.response.data.message
        : "An expected error occurred";
      dispatch({
        type: GET_METERS_ERROR,
        payload: msg,
      });
    }
  };

export const getUnassociatedMeters = () => async (dispatch) => {
  dispatch({
    type: GET_UNASSOCIATED_METERS_REQUEST,
  });

  try {
    const { data } = await coiApi.get(`/admin/meter/meters_without_gateways`);

    dispatch({
      type: GET_UNASSOCIATED_METERS_SUCCESS,
      meters: data,
    });
  } catch (err) {
    const msg = err.response.data.message
      ? err.response.data.message
      : "An expected error occurred";
    dispatch({
      type: GET_UNASSOCIATED_METERS_ERROR,
      payload: msg,
    });
  }
};

export const createMeter = (meterData) => async (dispatch) => {
  dispatch({
    type: CREATE_METERS_REQUEST,
  });

  try {
    const { data } = await coiApi.post(`/admin/meter`, {
      device_id: meterData.device_id,
      address_mapping: meterData.address_mapping,
      meter_number: meterData.meter_number,
      account_id: meterData.account_id,
      pulse_weight: meterData.pulse_weight,
      notes: meterData.notes,
    });

    dispatch({
      type: CREATE_METERS_SUCCESS,
      meter: data,
    });

    history.push("/meters");
  } catch (err) {
    const msg = err.response.data.message
      ? err.response.data.message
      : "An expected error occurred";
    dispatch({
      type: CREATE_METERS_ERROR,
      payload: msg,
    });
  }
};

export const updateMeter = (meter) => async (dispatch) => {
  dispatch({
    type: UPDATE_METERS_REQUEST,
  });

  try {
    const { data } = await coiApi.put(`/admin/meter/${meter.meter_id}`, meter);

    dispatch({
      type: UPDATE_METERS_SUCCESS,
    });

    history.push("/meters");
  } catch (err) {
    const msg = err.response.data.message
      ? err.response.data.message
      : "An expected error occurred";
    dispatch({
      type: UPDATE_METERS_ERROR,
      payload: msg,
    });
  }
};

export const updateMeterStatus = (meter) => async (dispatch) => {
  dispatch({
    type: UPDATE_METERS_STATUS_REQUEST,
  });

  try {
    const { data } = await coiApi.post(`/admin/meter/set_status`, {
      meter_id: meter.id,
      status: meter.status,
    });

    dispatch({
      type: UPDATE_METERS_STATUS_SUCCESS,
    });

    //Refresh meters list
    dispatch(getMeters());
  } catch (err) {
    const msg = err.response.data.message
      ? err.response.data.message
      : "An expected error occurred";
    dispatch({
      type: UPDATE_METERS_STATUS_ERROR,
      payload: msg,
    });
  }
};

export const checkMeters = () => async (dispatch) => {
  dispatch({
    type: CHECK_METERS_REQUEST,
  });

  try {
    const { data } = await coiApi.get(`admin/meters-check`);

    const now = moment();
    const metersWithIssues = [];
    const stats = {
      all: 0,
      issues: 0,
      working: 0,
    };

    for (const row of data.meters) {
      row.ilonDevice =
        row.mac_address && row.device_id == null && row.address_mapping == null
          ? 1
          : 0;
      row.obviusDevice = row.device_id && row.address_mapping ? 1 : 0;

      if (row.last_reading_insert_time && row.last_reading_time) {
        //If both fields have date:
        const dateReadingInsertTime = moment(row.last_reading_insert_time);
        const dateReadingTime = moment(row.last_reading_time);

        const diffInsertTime = now.diff(dateReadingInsertTime, "minutes");
        const diffReadingTime = now.diff(dateReadingTime, "minutes");

        if (diffInsertTime < 60 && diffReadingTime < 60) {
          //If the difference between NOW() and both dates are less than 60 minutes
          if (row.flat_readings === 1) {
            row.flatData = 1;
            metersWithIssues.push(row);
            stats.issues++;
          } else {
            stats.working++;
          }
        } else if (diffInsertTime < 60 && diffReadingTime > 120) {
          //We have good inserts times but with old readings: DELAYED DATA.
          row.delayedData = 1;
          metersWithIssues.push(row);
          stats.issues++;
        } else if (diffInsertTime > 60 && diffReadingTime > 60) {
          metersWithIssues.push(row);
          stats.issues++;
        }
      } else if (
        !row.last_reading_insert_time ||
        !row.last_reading_time ||
        row.status === "installed"
      ) {
        //If meter doesnt have a last_reading_insert_time
        metersWithIssues.push(row);
        stats.issues++;
      }

      stats.all++;
    }

    dispatch({
      type: CHECK_METERS_SUCCESS,
      meters: data.meters,
      metersWithIssues: metersWithIssues,
      stats: {
        all: stats.all,
        issues: stats.issues,
        working: stats.working,
        lastUpdateTime: now,
      },
    });
  } catch (err) {
    const msg = err.response.data.message
      ? err.response.data.message
      : "An expected error occurred";
    dispatch({
      type: CHECK_METERS_ERROR,
      payload: msg,
    });
  }
};

export const getMeter = (id) => async (dispatch) => {
  dispatch({
    type: GET_METER_REQUEST,
  });

  try {
    const { data } = await coiApi.get(`/admin/meter/${id}`);

    dispatch({
      type: GET_METER_SUCCESS,
      meter: data,
    });
  } catch (err) {
    const msg = err.response.data.message
      ? err.response.data.message
      : "An expected error occurred";
    dispatch({
      type: GET_METER_ERROR,
      payload: msg,
    });
  }
};

export const getReadings =
  (meterId, quantity = 10) =>
  async (dispatch) => {
    dispatch({
      type: GET_READINGS_REQUEST,
    });

    try {
      const body = {
        meter_id: meterId,
        quantity: quantity,
      };

      const { data } = await coiApi.post(`/admin/meter/last_readings`, body);

      dispatch({
        type: GET_READINGS_SUCCESS,
        readings: data,
      });
    } catch (err) {
      const msg = err.response.data.message
        ? err.response.data.message
        : "An expected error occurred";
      dispatch({
        type: GET_READINGS_ERROR,
        payload: msg,
      });
    }
  };

export const getDataPoints =
  (meterId, start, end, intervalMinutes) => async (dispatch) => {
    dispatch({
      type: GET_DATA_POINTS_REQUEST,
    });

    try {
      const body = {
        meter_id: meterId,
        start: start,
        end: end,
        interval_minutes: intervalMinutes,
        get_energy: false,
      };

      const { data } = await coiApi.post(`/admin/meter/datapoints_graphic`,
        body
      );

      dispatch({
        type: GET_DATA_POINTS_SUCCESS,
        readings: data,
      });
    } catch (err) {
      const msg = err.response.data.message
        ? err.response.data.message
        : "An expected error occurred";
      dispatch({
        type: GET_DATA_POINTS_ERROR,
        payload: msg,
      });
    }
};

export const rebootMeter =
  (meterId) => async (dispatch) => {
    dispatch({
      type: METER_REBOOT_REQUEST,
    });

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'tenant_id': getTenant(),
                'meter_id': meterId
            }
        } 

        await axios.post(`${process.env.REACT_APP_COI_ILON_OUTPUT_TOGGLE_API}/reboot`, {}, config);

      dispatch({
        type: METER_REBOOT_SUCCESS,
      });
    } catch (err) {
      const msg = err.response.data.message
        ? err.response.data.message
        : "An expected error occurred";
      dispatch({
        type: METER_REBOOT_ERROR,
        payload: msg,
      });
    }
  };


export const cleanMeter = () => async (dispatch) => {
  dispatch({
    type: CREATE_METERS_CLEAN_UP,
  });
};

export const getIlonMeterList =
() => async (dispatch) => {
  dispatch({
    type: GET_ILON_METER_LIST_REQUEST,
  });

  try {
      const config = {
          headers: {
              'Content-Type': 'application/json',
              'tenant_id': getTenant()
          }
      } 
      const { data }  = await axios.get(`${process.env.REACT_APP_COI_ILON_OUTPUT_TOGGLE_API}/meterList`, config);


    dispatch({
      type: GET_ILON_METER_LIST_SUCCESS,
      data
    });
  } catch (err) {
      console.log(err);
    const msg = err.response.data.message
      ? err.response.data.message
      : "An expected error occurred";
    dispatch({
      type: GET_ILON_METER_LIST_ERROR,
      payload: msg,
    });
  }
};

export const cleanIlonMeterList = () => async(dispatch) => {
    dispatch({
       type: GET_ILON_METER_LIST_RESET,
    })
}

export const getOuputToggleStatus = (meterID) => async (dispatch) => {
  dispatch({
    type: GET_OUTPUT_TOGGLE_STATUS_REQUEST,
  });
  try {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'tenant_id': getTenant(),
            'meter_id': meterID
        }
    } 
    const { data } = await axios.get(`${process.env.REACT_APP_COI_ILON_OUTPUT_TOGGLE_API}`, config);
    dispatch({
      type: GET_OUTPUT_TOGGLE_STATUS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const msg = err.response.data.message
      ? err.response.data.message
      : "An expected error occurred";
    dispatch({
      type: GET_OUTPUT_TOGGLE_STATUS_ERROR,
      payload: msg,
    });
  }
};

export const toggleOuput = (meterID, switchID) => async (dispatch) => {
  dispatch({
    type: OUTPUT_TOGGLE_REQUEST,
  });

  try {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'tenant_id': getTenant(),
            'meter_id': meterID,
            'switch_name': switchID
        }
    } 
    const { data } = await axios.post(`${process.env.REACT_APP_COI_ILON_OUTPUT_TOGGLE_API}`, {}, config);

    dispatch({
      type: OUTPUT_TOGGLE_SUCCESS
    });

    dispatch({
      type: GET_OUTPUT_TOGGLE_STATUS_SUCCESS,
      payload: data,
    });

  } catch (err) {
    const msg = err.response.data.message
      ? err.response.data.message
      : "An expected error occurred";
    dispatch({
      type: OUTPUT_TOGGLE_ERROR,
      payload: msg,
    });
  }
};

export const clearOutputToggle = () => (dispatch) => {
  dispatch({
    type: GET_OUTPUT_TOGGLE_STATUS_RESET
  })
}

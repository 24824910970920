import React, { useEffect } from "react";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
// import Switch from '@mui/material/Switch';
import Switch from "@material-ui/core/Switch";
import {styled} from "@material-ui/styles"

import {
  getDataPoints,
  getMeter,
  getReadings,
  getOuputToggleStatus,
  toggleOuput,
  clearOutputToggle
} from "../../../actions/meterActions";
import LastReadingsTable from "./LastReadingsTable";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ResponsiveContainer from "recharts/es6/component/ResponsiveContainer";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const dateParseFormat = "YYYY-MM-DD HH:mm";
const hoursParseFormat = "HH:mm a";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  graphContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 400,
    width: "100%",
  },
  separator: {
    marginTop: theme.spacing(2),
  },
  meterContainer: {
    margin: theme.spacing(2, 0),
  },
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
  loadingContainer: {
    textAlign: "center",
  },
  startDatePicker: {
    width: "220px",
  },
  endDatePicker: {
    width: "220px",
    marginLeft: theme.spacing(2),
  },
  toggleTable: {
    borderCollapse: "collapse",
    width: "25%",
    margin: "2rem"
  },
  toggleTableTD: {
    border: "1px solid #dddddd",
    textAlign: "center",
    padding: "8px",

  },
}));

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: blue[600],
    '&:hover': {
      backgroundColor: blue[600],
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[600],
  },
}));

const RealTimeEnergyPage = ({
  getReadings,
  match,
  getDataPoints,
  getMeter,
  meter,
  readings,
  dataPoints,
  outputToggleStatus,
  getOuputToggleStatus,
  clearOutputToggle,
  outputToggle,
  toggleOuput
}) => {
  const classes = useStyles();
  const meterId = match.params.id;
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().tz(moment.tz.guess()).startOf("day").toDate()
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().tz(moment.tz.guess()).endOf("day").toDate()
  );

  useEffect(() => {
    fetchMeter();
    fetchDataPoints();
    fetchReadings();
    fetchOutputToggleDetails();

    return () => {
      clearOutputToggle();
    }
  }, []);

  const fetchDataPoints = () => {
    getDataPoints(meterId, selectedStartDate, selectedEndDate, 15);
  };

  const fetchMeter = () => {
    getMeter(meterId);
  };

  const fetchReadings = () => {
    getReadings(meterId, 10);
  };

  const fetchOutputToggleDetails = () => {
    getOuputToggleStatus(meterId);
  };

  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
  };

  const onRefresh = () => {
    fetchDataPoints();
    fetchReadings();
  };

  const LoadingSpinner = () => {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress className={classes.progress} />
      </div>
    );
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    fetchDataPoints();
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    fetchDataPoints();
  };

  const renderMeterDetails = () => {
    if (meter.isFetching) return <LoadingSpinner />;

    if (meter.error)
      return (
        <h3>There was an error getting the meter: {meter.errorMessage}</h3>
      );

    return (
      <Grid container className={classes.meterContainer}>
        <Grid item sm={3}>
          Number: <strong>{meter.meter.meter_number}</strong>
        </Grid>
        <Grid item sm={3}>
          Pulse weight: <strong>{meter.meter.pulse_weight}</strong>
        </Grid>
      </Grid>
    );
  };

  const renderRealTimeChart = () => {
    if (dataPoints.isFetching) return <LoadingSpinner />;

    if (dataPoints.error)
      return (
        <h3>
          There was an error getting the data points: {dataPoints.errorMessage}
        </h3>
      );

    if (!dataPoints.data.length) {
      return <h3>No readings found in last 24 hours for this meter</h3>;
    }

    const data = [];
    dataPoints.data.map((point) => {
      let dateTime;
      if (point.date_time) {
        dateTime = moment.utc(point.date_time).local();
        dateTime = dateTime.format(hoursParseFormat);
      }
      data.push({
        dateTime: dateTime.toString(),
        demand: roundToTwo(point.capacity),
      });
    });

    return (
      <div className={classes.graphContainer}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorDemand" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="dateTime" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              formatter={function (value, name) {
                return `${value} kW`;
              }}
            />
            <Area
              type="monotone"
              dataKey="demand"
              stroke="#82ca9d"
              fillOpacity={1}
              fill="url(#colorDemand)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const renderLastReadingsTable = () => {
    if (readings.isFetching) return <LoadingSpinner />;

    if (readings.error)
      return (
        <h3>
          There was an error getting the readings: {readings.errorMessage}
        </h3>
      );

    if (!readings.data.length) {
      return <h3>No readings found in last 15 minutes for this meter</h3>;
    }

    return <LastReadingsTable readings={readings.data} />;
  };

  const handleToggleSwitch1 = (event) => {
    toggleOuput(meterId, 'Output1');
  };

  const handleToggleSwitch2 = (event) => {
    toggleOuput(meterId, 'Output2');
  };

  const renderOutputToggle = () => {
    if (outputToggleStatus.isFetching) return <LoadingSpinner />;
    
    if(outputToggleStatus.error){
      return <p>{outputToggle.errorMessage}</p>
    } else{
      return (
        <table className={classes.toggleTable}>
          <thead>
            <tr>
              <th className={classes.toggleTableTD}>Switch</th>
              <th className={classes.toggleTableTD}>Current Status</th>
              <th className={classes.toggleTableTD}>Toggle</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.toggleTableTD}>Output 1</td>
              <td className={classes.toggleTableTD}>
                {outputToggleStatus.data ? outputToggleStatus.data.switch1_status : "OFF"}
              </td>
              <td className={classes.toggleTableTD}>
                <GreenSwitch
                  checked={
                    outputToggleStatus.data
                      ? outputToggleStatus.data.switch1_status === "OFF"
                        ? false
                        : true
                      : false
                  }
                  onChange={(e) => handleToggleSwitch1(e)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.toggleTableTD}>Output 2</td>
              <td className={classes.toggleTableTD}>
                {outputToggleStatus.data ? outputToggleStatus.data.switch2_status : "OFF"}
              </td>
              <td className={classes.toggleTableTD}>
                <GreenSwitch
                  checked={
                    outputToggleStatus.data
                      ? outputToggleStatus.data.switch2_status === "OFF"
                        ? false
                        : true
                      : false
                  }
                  onChange={(e) => handleToggleSwitch2(e)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      );
    } 
  };

  return (
    <Container maxWidth="xl">
      <Grid container className={classes.titleContainer}>
        <Grid item sm={8}>
          <Typography variant="h4" component="h4">
            Meter
          </Typography>
        </Grid>
      </Grid>
      {renderMeterDetails()}
      {renderOutputToggle()}
      <Grid container className={classes.titleContainer}>
        <Grid item sm={8}>
          <Typography variant="h4" component="h4">
            Last Readings
          </Typography>
        </Grid>
        <Grid item sm={4} className={classes.alignRight}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onRefresh}
          >
            Refresh <RefreshIcon />
          </Button>
        </Grid>
      </Grid>
      {renderLastReadingsTable()}
      <Grid container className={classes.titleContainer}>
        <Grid item sm={6}>
          <Typography variant="h4" component="h4">
            Real Time Demand
          </Typography>
        </Grid>
        <Grid item sm={6} className={classes.alignRight}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              variant="inline"
              id="start-date-picker"
              label="Start Date"
              value={selectedStartDate}
              onChange={handleStartDateChange}
              disableFuture
              format="yyyy/MM/dd hh:mm a"
              className={classes.startDatePicker}
            />
            <KeyboardDateTimePicker
              variant="inline"
              id="end-date-picker"
              label="End Date"
              value={selectedEndDate}
              minDate={selectedStartDate}
              onChange={handleEndDateChange}
              disableFuture
              format="yyyy/MM/dd hh:mm a"
              className={classes.endDatePicker}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      {renderRealTimeChart()}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  readings: state.readings,
  dataPoints: state.dataPoints,
  meter: state.meter,
  outputToggleStatus: state.outputToggleStatus,
  outputToggle: state.outputToggle

});

const mapDispatchToProps = {
  getReadings,
  getDataPoints,
  getMeter,
  getOuputToggleStatus,
  toggleOuput,
  clearOutputToggle
};

export default connect(mapStateToProps, mapDispatchToProps)(RealTimeEnergyPage);

import React from 'react';
import MaterialTable from "material-table";

const UserTable = ({users, onDelete, onEdit, showRemove = false, showEdit = false, editingUser = false}) => {

    const columns = [
        {title: "First Name", field: "first_name"},
        {title: "Last Name", field: "last_name"},
        {title: "Email", field: "email"},
        {title: "Type", field: "contact_type", render: rowData => rowData.contact_type === 1 ? 'Primary' : 'Secondary'},
        {title: "Primary Phone", field: "primary_phone"},
        {title: "Secondary Phone", field: "secondary_phone"},
    ];

    const actions = () => {
        const actions = [];
        if (showEdit) {
            actions.push({
                icon: 'edit',
                tooltip: 'Edit User',
                disabled: editingUser,
                onClick: (event, rowData) => {
                    onEdit(rowData)
                }
            });
        }
        if (showRemove) {
            actions.push({
                icon: 'delete',
                tooltip: 'Delete User',
                disabled: editingUser,
                onClick: (event, rowData) => {
                    onDelete(rowData)
                }
            });
        }
        return actions;
    };

    if (users) {
        return (
            <MaterialTable
                columns={columns}
                data={users}
                options={{
                    search: false,
                    paging: false,
                    showTitle: false,
                    toolbar: false,
                    actionsColumnIndex: 6
                }}
                actions={actions()}
            />
        );
    }
};

export default UserTable;

import {
    GET_USER_BY_ID_ERROR,
    GET_USER_BY_ID_REQUEST,
    GET_USER_BY_ID_SUCCESS,
    GET_USER_ERROR,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    USERS_CLEAN_UP
} from '../actions/actionTypes';

const INITIAL_STATE_USER_BY_NAME = {
    isFetching: false,
    data: {},
    error: false,
    errorMessage: ''
};

const INITIAL_STATE = {
    isFetching: false,
    data: [],
    paginator: {
        currentPage: 0,
        totalRecords: 0,
        from: 0,
        to: 0
    },
    error: false,
    errorMessage: '',
    searchInput: '',
    orderColumn: 'user_name',
    orderDirection: 'asc'
};

export const users = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_REQUEST:
            return {
                ...state,
                isFetching: true,
                data: [],
                error: false,
                errorMessage: '',
                searchInput: '',
                orderColumn: action.orderColumn,
                orderDirection: action.orderDirection,
            };

        case GET_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.users,
                paginator: {
                    currentPage: action.currentPage,
                    totalRecords: action.totalRecords,
                    from: action.from,
                    to: action.to
                },
                error: false,
                errorMessage: '',
                searchInput: action.searchInput,
                orderColumn: action.orderColumn,
                orderDirection: action.orderDirection
            };

        case GET_USER_ERROR:
            return {
                ...state,
                isFetching: false,
                data: [],
                error: true,
                errorMessage: action.payload,
                searchInput: '',
                orderColumn: '',
                orderDirection: '',
            };
        
        case USERS_CLEAN_UP:
            return {
                ...state,
                isFetching: false,
                data: [],
                error: false,
                errorMessage: ''
            };
        default:
            return state;
    }
};

export const userById = (state = INITIAL_STATE_USER_BY_NAME, action) => {
    switch (action.type) {
        case GET_USER_BY_ID_REQUEST:
            return {
                ...state,
                isFetching: true,
                data: {},
                error: false,
                errorMessage: ''
            };

        case GET_USER_BY_ID_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.user,
                error: false,
                errorMessage: ''
            };

        case GET_USER_BY_ID_ERROR:
            return {
                ...state,
                isFetching: false,
                data: {},
                error: true,
                errorMessage: action.payload
            };
        default:
            return state;
    }
};


import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Container, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import {Link} from "react-router-dom";
import {getAccount, updateAccount,cleanAccount} from "../../../actions/accountActions";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    titleContainer: {
        margin: theme.spacing(1, 0, 3),
    },
    root: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(4)
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2, 0, 0),
    },
    formBox: {
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2, 4, 4, 4),
        borderRadius: '4px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);'
    },
    fullWidth: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textFieldLeft: {
        marginRight: theme.spacing(2),
        width: `calc(100% - 16px)`
    },
    textField: {
        width: `calc(100% - 16px)`
    },
    spaceBetween: {
        marginRight: theme.spacing(2),
    },
    closeButton: {
        borderRadius: `4px !important`,
        marginRight: `16px !important`
    },
    progress: {
        margin: theme.spacing(0.5),
    },
    loadingContainer: {
        textAlign: 'center',
    }
}));

const AccountEditionPage = ({getAccount, updateAccount, account, match, cleanAccount}) => {

    const classes = useStyles();
    const accountId = match.params.id;

    useEffect(() => {
        getAccount(accountId);
        return () => {
            cleanAccount();
        }
    }, []);

    useEffect(() => {
        if (account.account && account.account.account_id) {
            mapObjectToValues();
        }
    }, [account]);

    const validationRules = () => {
        return Yup.object().shape({
            account_name: Yup.string()
                .required('Required'),
            account_number: Yup.string()
                .required('Required'),
            service_street: Yup.string()
                .required('Required'),
            service_city: Yup.string()
                .required('Required'),
            service_state: Yup.string()
                .required('Required'),
            service_country: Yup.string()
                .required('Required'),
            service_zipcode: Yup.string()
                .required('Required'),
            mailing_street: Yup.string()
                .required('Required'),
            mailing_city: Yup.string()
                .required('Required'),
            mailing_state: Yup.string()
                .required('Required'),
            mailing_country: Yup.string()
                .required('Required'),
            mailing_zipcode: Yup.string()
                .required('Required'),
            primary_contact_name: Yup.string()
                .required('Required'),
            primary_contact_email: Yup.string()
                .required('Required'),
            primary_contact_phone: Yup.string().matches(/[\d() -]+$/, {message: 'Format valid: (999) 999-9999'})
                .required('Required')
        });
    };

    const mapValuesToObject = values => {
        return {
            account_id: values.account_id,
            utility_id: 1, // TODO Change it to use the tenant id selected on the TopBar
            account_name: values.account_name,
            account_number: values.account_number,
            primary_contact_name: values.primary_contact_name,
            primary_contact_email: values.primary_contact_email,
            primary_contact_phone: values.primary_contact_phone,
            service_address: {
                address_id: values.service_address_id,
                street: values.service_street,
                city: values.service_city,
                state: values.service_state,
                country: values.service_country,
                zipcode: values.service_zipcode,
                address_type: 'account_service_address',
            },
            mailing_address: {
                address_id: values.mailing_address_id,
                street: values.mailing_street,
                city: values.mailing_city,
                state: values.mailing_state,
                country: values.mailing_country,
                zipcode: values.mailing_zipcode,
                address_type: 'account_mailing_address'
            }
        };
    };

    const mapObjectToValues = () => {
        const result = {
            account_id: account.account.account_id,
            account_name: account.account.account_name || '',
            account_number: account.account.account_number || '',
            primary_contact_name: account.account.primary_contact_name || '',
            primary_contact_email: account.account.primary_contact_email || '',
            primary_contact_phone: account.account.primary_contact_phone || '',
            service_address_id: account.account.service_address ? account.account.service_address.address_id : '',
            service_street: account.account.service_address ? account.account.service_address.street : '',
            service_city: account.account.service_address ? account.account.service_address.city : '',
            service_state: account.account.service_address ? account.account.service_address.state : '',
            service_country: account.account.service_address ? account.account.service_address.country : '',
            service_zipcode: account.account.service_address ? account.account.service_address.zipcode : '',
            mailing_address_id: account.account.mailing_address ? account.account.mailing_address.address_id : '',
            mailing_street: account.account.mailing_address ? account.account.mailing_address.street : '',
            mailing_city: account.account.mailing_address ? account.account.mailing_address.city : '',
            mailing_state: account.account.mailing_address ? account.account.mailing_address.state : '',
            mailing_country: account.account.mailing_address ? account.account.mailing_address.country : '',
            mailing_zipcode: account.account.mailing_address ? account.account.mailing_address.zipcode : ''
        };
        return result;
    };

    const onSubmit = (values) => {
        updateAccount(values);
    };

    const LoadingSpinner = () => {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress className={classes.progress}/>
            </div>
        );
    };

    const renderAccountForm = () => {
        if (account.isFetching) {
            return <LoadingSpinner/>;
        }

        return (<Formik
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={mapObjectToValues()}
            validationSchema={validationRules}
            onSubmit={(values) => {
                values.account_number = account.account.account_number;
                onSubmit(mapValuesToObject(values));
            }}
        >
            {({handleSubmit, errors, values, handleChange, handleBlur, touched}) => {
                return (
                    <form onSubmit={handleSubmit} className={classes.form} noValidate autoComplete="off">
                        <Grid container>
                            <Paper className={classes.root}>
                                <Grid container>
                                    <Grid item sm={6}>
                                        <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                            Account Information
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.account_name && touched.account_name}
                                            id="account_name"
                                            label="Account Name"
                                            name="account_name"
                                            value={values.account_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.account_name && touched.account_name) && errors.account_name}
                                            className={classes.textFieldLeft}
                                        />
                                        <TextField
                                            disabled={true}
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.account_number && touched.account_number}
                                            id="account_number"
                                            label="Account Number"
                                            name="account_number"
                                            value={values.account_number}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.account_number && touched.account_number) && errors.account_number}
                                            className={classes.textFieldLeft}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.primary_contact_name && touched.primary_contact_name}
                                            id="primary_contact_name"
                                            label="Primary Contact Name"
                                            name="primary_contact_name"
                                            value={values.primary_contact_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.primary_contact_name && touched.primary_contact_name) && errors.primary_contact_name}
                                            className={classes.textFieldLeft}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.primary_contact_email && touched.primary_contact_email}
                                            id="primary_contact_email"
                                            label="Primary Contact Email"
                                            name="primary_contact_email"
                                            value={values.primary_contact_email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.primary_contact_email && touched.primary_contact_email) && errors.primary_contact_email}
                                            className={classes.textFieldLeft}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.primary_contact_phone && touched.primary_contact_phone}
                                            id="primary_contact_phone"
                                            label="Primary Contact Phone"
                                            name="primary_contact_phone"
                                            value={values.primary_contact_phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.primary_contact_phone && touched.primary_contact_phone) && errors.primary_contact_phone}
                                            className={classes.textFieldLeft}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                            Service Address
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.service_street && touched.service_street}
                                            id="service_street"
                                            label="Street"
                                            name="service_street"
                                            value={values.service_street}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.service_street && touched.service_street) && errors.service_street}
                                            className={classes.textField}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.service_city && touched.service_city}
                                            id="service_city"
                                            label="City"
                                            name="service_city"
                                            value={values.service_city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.service_city && touched.service_city) && errors.service_city}
                                            className={classes.textField}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.service_state && touched.service_state}
                                            id="service_state"
                                            label="State"
                                            name="service_state"
                                            value={values.service_state}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.service_state && touched.service_state) && errors.service_state}
                                            className={classes.textField}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.service_country && touched.service_country}
                                            id="service_country"
                                            label="Country"
                                            name="service_country"
                                            value={values.service_country}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.service_country && touched.service_country) && errors.service_country}
                                            className={classes.textField}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.service_zipcode && touched.service_zipcode}
                                            id="service_zipcode"
                                            label="Zipcode"
                                            name="service_zipcode"
                                            value={values.service_zipcode}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.service_zipcode && touched.service_zipcode) && errors.service_zipcode}
                                            className={classes.textField}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} direction={'row-reverse'}>
                                    <Grid item sm={6}>
                                        <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                            Mailing Address
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.mailing_street && touched.mailing_street}
                                            id="mailing_street"
                                            label="Street"
                                            name="mailing_street"
                                            value={values.mailing_street}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.mailing_street && touched.mailing_street) && errors.mailing_street}
                                            className={classes.textField}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.mailing_city && touched.mailing_city}
                                            id="mailing_city"
                                            label="City"
                                            name="mailing_city"
                                            value={values.mailing_city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.mailing_city && touched.mailing_city) && errors.mailing_city}
                                            className={classes.textField}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.mailing_state && touched.mailing_state}
                                            id="mailing_state"
                                            label="State"
                                            name="mailing_state"
                                            value={values.mailing_state}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.mailing_state && touched.mailing_state) && errors.mailing_state}
                                            className={classes.textField}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.mailing_country && touched.mailing_country}
                                            id="mailing_country"
                                            label="Country"
                                            name="mailing_country"
                                            value={values.mailing_country}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.mailing_country && touched.mailing_country) && errors.mailing_country}
                                            className={classes.textField}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.mailing_zipcode && touched.mailing_zipcode}
                                            id="mailing_zipcode"
                                            label="Zipcode"
                                            name="mailing_zipcode"
                                            value={values.mailing_zipcode}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.mailing_zipcode && touched.mailing_zipcode) && errors.mailing_zipcode}
                                            className={classes.textField}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid container direction={'row-reverse'}>
                            <Grid item>
                                <Fab
                                    color="default"
                                    size="small"
                                    component={Link}
                                    to={`/accounts`}
                                    variant={'extended'}
                                    className={classes.closeButton}
                                >
                                    Cancel
                                </Fab>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )
            }}
        </Formik>);
    };

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    <Typography variant="h4" component="h4">
                        Edit Account
                    </Typography>
                </Grid>
            </Grid>
            {renderAccountForm()}
        </Container>

    );
};

AccountEditionPage.propTypes = {
    getAccount: PropTypes.func,
};

const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = {
    getAccount,
    updateAccount,
    cleanAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountEditionPage);


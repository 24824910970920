import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getUsers, cleanUsers} from "../../../actions/userActions";
import UserTable from "./UserTable";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    titleContainer: {
        margin: theme.spacing(1, 0, 3),
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    progress: {
        margin: theme.spacing(0.5),
    },
    loadingContainer: {
        textAlign: 'center',
    }
}));

const UserPage = ({users, getUsers, cleanUsers}) => {
    const classes = useStyles();
    const [reload,setReload] = useState(false)

    const handleReload = ()=>{
        setReload(!reload)
    }

    useEffect(() => {
        fetchUsers()
        return () => {
            cleanUsers();
        }
    }, [reload]);
    
    const fetchUsers = (page, search, orderColumn, orderDirection) => {
        getUsers(0, page, search, orderColumn, orderDirection)
    };

    const LoadingSpinner = () => {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress className={classes.progress}/>
            </div>
        );
    };


    const renderUsersList = () => {
        if (users.isFetching)
            return <LoadingSpinner/>;

        if (users.error)
            return <h3>There was an error getting the users: {users.errorMessage}</h3>;
    
    

        return (
            <UserTable
                users={users.data}
                paginator={users.paginator}
                fetchUsers={fetchUsers}
                searchInput={users.searchInput}
                orderColumn={users.orderColumn}
                orderDirection={users.orderDirection}
                handleReload={handleReload}
            />
        );
    };

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    <Typography variant="h4" component="h4">
                        Users
                    </Typography>
                </Grid>
            </Grid>
            {renderUsersList()}
        </Container>
    );
};

const mapStateToProps = state => ({
    users: state.users
});

const mapDispatchToProps = {
    getUsers,
    cleanUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);

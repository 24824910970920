// -- Auth --
export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_ERROR = 'LOG_OUT_ERROR';

export const GET_LOGGED_USER_REQUEST = 'GET_LOGGED_USER_REQUEST';
export const GET_LOGGED_USER_SUCCESS = 'GET_LOGGED_USER_SUCCESS';
export const GET_LOGGED_USER_ERROR = 'GET_LOGGED_USER_ERROR';

// -- Gateways --
export const GET_GATEWAYS_REQUEST = 'GET_GATEWAYS_REQUEST';
export const GET_GATEWAYS_SUCCESS = 'GET_GATEWAYS_SUCCESS';
export const GET_GATEWAYS_ERROR = 'GET_GATEWAYS_ERROR';

export const GET_GATEWAY_REQUEST = 'GET_GATEWAY_REQUEST';
export const GET_GATEWAY_SUCCESS = 'GET_GATEWAY_SUCCESS';
export const GET_GATEWAY_ERROR = 'GET_GATEWAY_ERROR';

export const CREATE_GATEWAY_REQUEST = 'CREATE_GATEWAY_REQUEST';
export const CREATE_GATEWAY_SUCCESS = 'CREATE_GATEWAY_SUCCESS';
export const CREATE_GATEWAY_ERROR = 'CREATE_GATEWAY_ERROR';

export const CREATE_GATEWAY_CLEAN_UP = 'CREATE_GATEWAY_CLEAN_UP';


export const UPDATE_GATEWAY_REQUEST = 'UPDATE_GATEWAY_REQUEST';
export const UPDATE_GATEWAY_SUCCESS = 'UPDATE_GATEWAY_SUCCESS';
export const UPDATE_GATEWAY_ERROR = 'UPDATE_GATEWAY_ERROR';

// -- Meters --
export const GET_METERS_REQUEST = 'GET_METERS_REQUEST';
export const GET_METERS_SUCCESS = 'GET_METERS_SUCCESS';
export const GET_METERS_ERROR = 'GET_METERS_ERROR';

export const GET_UNASSOCIATED_METERS_REQUEST = 'GET_UNASSOCIATED_METERS_REQUEST';
export const GET_UNASSOCIATED_METERS_SUCCESS = 'GET_UNASSOCIATED_METERS_SUCCESS';
export const GET_UNASSOCIATED_METERS_ERROR = 'GET_UNASSOCIATED_METERS_ERROR';

export const GET_METER_REQUEST = 'GET_METER_REQUEST';
export const GET_METER_SUCCESS = 'GET_METER_SUCCESS';
export const GET_METER_ERROR = 'GET_METER_ERROR';

export const CREATE_METERS_REQUEST = 'CREATE_METERS_REQUEST';
export const CREATE_METERS_SUCCESS = 'CREATE_METERS_SUCCESS';
export const CREATE_METERS_ERROR = 'CREATE_METERS_ERROR';

export const CREATE_METERS_CLEAN_UP = 'CREATE_METERS_CLEAN_UP';

export const UPDATE_METERS_REQUEST = 'UPDATE_METERS_REQUEST';
export const UPDATE_METERS_SUCCESS = 'UPDATE_METERS_SUCCESS';
export const UPDATE_METERS_ERROR = 'UPDATE_METERS_ERROR';

export const UPDATE_METERS_STATUS_REQUEST = 'UPDATE_METERS_STATUS_REQUEST';
export const UPDATE_METERS_STATUS_SUCCESS = 'UPDATE_METERS_STATUS_SUCCESS';
export const UPDATE_METERS_STATUS_ERROR = 'UPDATE_METERS_STATUS_ERROR';

export const CHECK_METERS_REQUEST = 'CHECK_METERS_REQUEST';
export const CHECK_METERS_SUCCESS = 'CHECK_METERS_SUCCESS';
export const CHECK_METERS_ERROR = 'CHECK_METERS_ERROR';

export const HASH_METER_GENERATE_REQUEST = 'HASH_METER_GENERATE_REQUEST';
export const HASH_METER_GENERATE_SUCCESS = 'HASH_METER_GENERATE_SUCCESS';
export const HASH_METER_GENERATE_ERROR = 'HASH_METER_GENERATE_ERROR';

export const GET_READINGS_REQUEST = 'GET_READINGS_REQUEST';
export const GET_READINGS_SUCCESS = 'GET_READINGS_SUCCESS';
export const GET_READINGS_ERROR = 'GET_READINGS_ERROR';

export const GET_DATA_POINTS_REQUEST = 'GET_DATA_POINTS_REQUEST';
export const GET_DATA_POINTS_SUCCESS = 'GET_DATA_POINTS_SUCCESS';
export const GET_DATA_POINTS_ERROR = 'GET_DATA_POINTS_ERROR';

export const METER_REBOOT_REQUEST = 'METER_REBOOT_REQUEST';
export const METER_REBOOT_SUCCESS = 'METER_REBOOT_SUCCESS';
export const METER_REBOOT_ERROR = 'METER_REBOOT_ERROR';

export const GET_ILON_METER_LIST_REQUEST = 'GET_ILON_METER_LIST_REQUEST';
export const GET_ILON_METER_LIST_SUCCESS = 'GET_ILON_METER_LIST_SUCCESS';
export const GET_ILON_METER_LIST_ERROR = 'GET_ILON_METER_LIST_ERROR';
export const GET_ILON_METER_LIST_RESET = 'GET_ILON_METER_LIST_RESET';

export const GET_OUTPUT_TOGGLE_STATUS_REQUEST = 'GET_OUTPUT_TOGGLE_STATUS_REQUEST';
export const GET_OUTPUT_TOGGLE_STATUS_SUCCESS = 'GET_OUTPUT_TOGGLE_STATUS_SUCCESS';
export const GET_OUTPUT_TOGGLE_STATUS_ERROR = 'GET_OUTPUT_TOGGLE_STATUS_ERROR';
export const GET_OUTPUT_TOGGLE_STATUS_RESET = 'GET_OUTPUT_TOGGLE_STATUS_RESET';

export const OUTPUT_TOGGLE_REQUEST = 'OUTPUT_TOGGLE_REQUEST';
export const OUTPUT_TOGGLE_SUCCESS = 'OUTPUT_TOGGLE_SUCCESS';
export const OUTPUT_TOGGLE_ERROR = 'OUTPUT_TOGGLE_ERROR';

// -- Accounts --
export const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR';
export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';
export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR';
export const UPDATE_ACCOUNT_USERS_REQUEST = 'UPDATE_ACCOUNT_USERS_REQUEST';
export const UPDATE_ACCOUNT_USERS_SUCCESS = 'UPDATE_ACCOUNT_USERS_SUCCESS';
export const UPDATE_ACCOUNT_USERS_ERROR = 'UPDATE_ACCOUNT_USERS_ERROR';
export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';
export const CREATE_ACCOUNT_CLEAN_UP = 'CREATE_ACCOUNT_CLEAN_UP';
export const ACCOUNT_CLEAN_UP = 'ACCOUNT_CLEAN_UP';
export const VALIDATE_ACCOUNT_NUMBER_REQUEST = 'VALIDATE_ACCOUNT_NUMBER_REQUEST';
export const VALIDATE_ACCOUNT_NUMBER_SUCCESS = 'VALIDATE_ACCOUNT_NUMBER_SUCCESS';
export const VALIDATE_ACCOUNT_NUMBER_ERROR = 'VALIDATE_ACCOUNT_NUMBER_ERROR';

// -- Business --
export const GET_BUSINESS_REQUEST = 'GET_BUSINESS_REQUEST';
export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS';
export const GET_BUSINESS_ERROR = 'GET_BUSINESS_ERROR';
export const GET_BUSINESS_BY_NAME_REQUEST = 'GET_BUSINESS_BY_NAME_REQUEST';
export const GET_BUSINESS_BY_NAME_SUCCESS = 'GET_BUSINESS_BY_NAME_SUCCESS';
export const GET_BUSINESS_BY_NAME_ERROR = 'GET_BUSINESS_BY_NAME_ERROR';
export const GET_BUSINESS_BY_ID_REQUEST = 'GET_BUSINESS_BY_ID_REQUEST';
export const GET_BUSINESS_BY_ID_SUCCESS = 'GET_BUSINESS_BY_ID_SUCCESS';
export const GET_BUSINESS_BY_ID_ERROR = 'GET_BUSINESS_BY_ID_ERROR';
export const UPDATE_BUSINESS_REQUEST = 'UPDATE_BUSINESS_REQUEST';
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS';
export const UPDATE_BUSINESS_ERROR = 'UPDATE_BUSINESS_ERROR';
export const BUSINESS_CLEANUP = 'BUSINESS_CLEANUP'

// -- Users --
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_ERROR = 'GET_USER_BY_ID_ERROR';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const USERS_CLEAN_UP = 'USERS_CLEAN_UP';

export const TENANT_CHANGE_SUCCESS = 'TENANT_CHANGE_SUCCESS';
export const TENANT_CHANGE_ERROR = 'TENANT_CHANGE_ERROR';

import React, {useEffect} from "react";
import LoginForm from "./LoginForm";
import {requestLogin} from "../../../actions/authActions";
import {connect} from "react-redux";
import history from '../../../history';
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { ReactComponent as COILogo } from '../../../assets/images/logo_tagline.svg';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        margin: theme.spacing(5)
    }
}));

const LoginPage = (props) => {
    const classes = useStyles();

    useEffect(() => {
        if (props.auth.isAuthenticated) {
            history.push('/dashboard');
        }
    });

    const submitCall = (values) => {
        props.requestLogin({
            email: values.email,
            password: values.password
        });
    };

    return (
        <Container maxWidth="xs">
            <div className={classes.paper}>
                <COILogo className={classes.logo}/>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <LoginForm
                    onSubmit={submitCall}
                    isFetching={props.auth.isFetching}/>
            </div>
        </Container>
    );
};


const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = {
    requestLogin
};


export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);


import React, {useEffect} from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom'
import {getAccounts} from "../../../actions/accountActions";
import AccountTable from "./AccountTable";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    titleContainer: {
        margin: theme.spacing(1, 0, 3),
    },
    button: {
        margin: theme.spacing(0),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    alignRight: {
        textAlign: 'right'
    },
    loadingContainer: {
        textAlign: 'center',
    }
}));

const AccountPage = ({accounts, getAccounts, updateAccount}) => {
    const classes = useStyles();

    useEffect(() => {
        fetchAccounts()
    }, []);

    const fetchAccounts = (page, search, orderColumn, orderDirection) => {
        getAccounts(0, page, search, orderColumn, orderDirection);
    };

    const LoadingSpinner = () => {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress className={classes.progress}/>
            </div>
        );
    };

    const renderAccountList = () => {
        if (accounts.isFetching)
            return <LoadingSpinner />;

        if (accounts.error)
            return <h3>There was an error getting the accounts: {accounts.errorMessage}</h3>;

        return (
            <AccountTable
                accounts={accounts.data}
                paginator={accounts.paginator}
                fetchAccounts={fetchAccounts}
                searchInput={accounts.searchInput}
                orderColumn={accounts.orderColumn}
                orderDirection={accounts.orderDirection}
            />
        );
    };

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                <Typography variant="h4" component="h4">
                    Accounts
                </Typography>
                </Grid>
                <Grid item sm={4} className={classes.alignRight}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        component={Link}
                        to="/accounts/create">
                        New Account
                        <AccountBoxIcon className={classes.rightIcon} />
                    </Button>
                </Grid>
            </Grid>
            {renderAccountList()}
        </Container>
    );
};

const mapStateToProps = state => ({
    accounts: state.accounts
});

const mapDispatchToProps = {
    getAccounts
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);

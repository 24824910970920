import React from "react";
import { Container, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(0.5),
    },
    loadingContainer: {
        textAlign: 'center',
    }
}))

const LoadingSpinner = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="xl">
            <div className={classes.loadingContainer}>
                <CircularProgress className={classes.progress} />
            </div>
        </Container>
    );
};

export default (LoadingSpinner);
import coiApi from '../api/coiApi';

import {
    ACCOUNT_CLEAN_UP,
    CREATE_ACCOUNT_CLEAN_UP,
    CREATE_ACCOUNT_ERROR,
    CREATE_ACCOUNT_REQUEST,
    CREATE_ACCOUNT_SUCCESS,
    GET_ACCOUNT_ERROR,
    GET_ACCOUNT_REQUEST,
    GET_ACCOUNT_SUCCESS,
    GET_ACCOUNTS_ERROR,
    GET_ACCOUNTS_REQUEST,
    GET_ACCOUNTS_SUCCESS,
    UPDATE_ACCOUNT_ERROR,
    UPDATE_ACCOUNT_REQUEST,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_USERS_ERROR,
    UPDATE_ACCOUNT_USERS_REQUEST,
    UPDATE_ACCOUNT_USERS_SUCCESS,
    UPDATE_BUSINESS_ERROR,
    UPDATE_BUSINESS_REQUEST,
    UPDATE_BUSINESS_SUCCESS,
    VALIDATE_ACCOUNT_NUMBER_ERROR,
    VALIDATE_ACCOUNT_NUMBER_REQUEST,
    VALIDATE_ACCOUNT_NUMBER_SUCCESS,
} from "./actionTypes";
import history from "../history";

export const getAccounts = (all = 0, page = 0, search = '', orderColumn = 'account_id', orderDirection = 'desc') => async (dispatch) => {
    dispatch({
        type: GET_ACCOUNTS_REQUEST,
        orderColumn,
        orderDirection
    });

    try {
        page++;

        const {data} = await coiApi.get(
            `/admin/account?page=${page}&search=${search}&orderColumn=${orderColumn}&orderDirection=${orderDirection}&all=${all}`
        );

        dispatch({
            type: GET_ACCOUNTS_SUCCESS,
            accounts: data.data,
            currentPage: data.current_page - 1, //It's zero-based index.
            totalRecords: data.total,
            from: data.from,
            to: data.to,
            searchInput: search,
            orderColumn: orderColumn,
            orderDirection: orderDirection
        });

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: GET_ACCOUNTS_ERROR,
            payload: msg
        });
    }
};

export const getAccount = (id) => async (dispatch) => {
    dispatch({
        type: GET_ACCOUNT_REQUEST
    });

    try {
        const {data} = await coiApi.get(
            `/admin/account/${id}`
        );

        dispatch({
            type: GET_ACCOUNT_SUCCESS,
            account: data
        });

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: GET_ACCOUNT_ERROR,
            payload: msg
        });
    }
};

export const validateAccountNumber = (accountNumber) => async (dispatch) => {
    dispatch({
        type: VALIDATE_ACCOUNT_NUMBER_REQUEST
    });

    try {
        const {data} = await coiApi.post(
            `/admin/account/with_account_number`, accountNumber
        );

        dispatch({
            type: VALIDATE_ACCOUNT_NUMBER_SUCCESS,
            account: data
        });

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: VALIDATE_ACCOUNT_NUMBER_ERROR,
            payload: msg
        });
    }
};

export const cleanAccount = () => async (dispatch) => {
    dispatch({
        type: ACCOUNT_CLEAN_UP
    });
    dispatch({
        type: CREATE_ACCOUNT_CLEAN_UP
    });
};

export const createAccount = (accountData) => async (dispatch) => {
    dispatch({
        type: CREATE_ACCOUNT_REQUEST,
    });

    try {

        const {data} = await coiApi.post(
            `/admin/account`, accountData
        );

        dispatch({
            type: CREATE_ACCOUNT_SUCCESS,
            account: data.response,
        });

        history.push('/accounts');

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: CREATE_ACCOUNT_ERROR,
            payload: msg
        });
    }
};

export const updateAccount = (account) => async (dispatch) => {
    dispatch({
        type: UPDATE_ACCOUNT_REQUEST,
    });

    try {

        const { data } = await coiApi.put(
            `/admin/account/${account.account_id}`, account
        );

        dispatch({
            type: UPDATE_ACCOUNT_SUCCESS
        });

        history.push('/accounts');

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: UPDATE_ACCOUNT_ERROR,
            payload: msg
        });
    }
};

export const updateAccountUsers = (accountUsers) => async (dispatch) => {
    dispatch({
        type: UPDATE_ACCOUNT_USERS_REQUEST,
    });

    try {

        const { data } = await coiApi.post(
            `/admin/account/update_contacts`, accountUsers
        );

        dispatch({
            type: UPDATE_ACCOUNT_USERS_SUCCESS
        });

        history.push('/accounts');

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: UPDATE_ACCOUNT_USERS_ERROR,
            payload: msg
        });
    }
};

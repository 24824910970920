import React, {useEffect} from 'react';
import {Container, makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MultiStepAccountForm from "./MultiStepAccountForm";
import {connect} from "react-redux";
import {cleanAccount, createAccount, getAccount, validateAccountNumber} from "../../../actions/accountActions";
import {getBusiness, getBusinessByName} from "../../../actions/businessActions";
import {getUsers} from "../../../actions/userActions";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2)
    },
    titleContainer: {
        margin: theme.spacing(1, 0, 3),
    },
}));

const AccountCreatePage = ({
                               isFetching,
                               createAccount,
                               getBusiness,
                               business,
                               getUsers,
                               users,
                               match,
                               account,
                               getAccount,
                               getBusinessByName,
                               businessByName,
                               accountNumber,
                               cleanAccount,
                               accountCreate,
                               validateAccountNumber
                           }) => {
    const classes = useStyles();
    const accountId = match.params.id;

    useEffect(() => {
        if (accountId) {
            getAccountData();
        }
        return () => {
            cleanAccount();
        }
    }, [match.params.id]);

    const getAccountData = () => {
        getAccount(accountId);
    };

    const onSubmitAction = (values) => {
        createAccount(values);
    };

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    {accountId && <Typography variant="h4" component="h4">
                        Account View
                    </Typography>}
                    {!accountId && <Typography variant="h4" component="h4">
                        Create an Account
                    </Typography>}
                </Grid>
            </Grid>
            <Paper className={classes.root}>
                <MultiStepAccountForm
                    isFetching={isFetching}
                    getBusiness={getBusiness}
                    business={business}
                    getUsers={getUsers}
                    accountId={accountId}
                    getBusinessByName={getBusinessByName}
                    users={users}
                    businessByName={businessByName}
                    validateAccountNumber={validateAccountNumber}
                    accountNumber={accountNumber}
                    account={account}
                    accountCreate={accountCreate}
                    onSubmitAction={onSubmitAction}/>
            </Paper>
        </Container>
    );
};


const mapStateToProps = state => ({
    isCreating: false,
    business: state.business,
    users: state.users,
    account: state.account,
    businessByName: state.businessByName,
    accountNumber: state.accountNumber,
    accountCreate: state.accountCreate
});

const mapDispatchToProps = {
    createAccount,
    getBusiness,
    getUsers,
    getAccount,
    getBusinessByName,
    validateAccountNumber,
    cleanAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreatePage);

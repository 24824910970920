import React from "react";

const AboutPage = () => {
    return (
        <div>
            <h2>About</h2>

            <p>This is the About Page</p>
        </div>
    );
};

export default AboutPage;

import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: '100%',
        marginBottom: theme.spacing(4)
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textFieldLeft: {
        marginRight: theme.spacing(2),
        width: `calc((100% - 16px)/2)`
    },
    textField: {
        width: `calc((100% - 16px)/2)`
    },
    closeButton: {
        borderRadius: `4px !important`,
        marginRight: `16px !important`
    },
    autocomplete: {
        margin: theme.spacing(2, 2, 1, 0),
        width: `calc(50% - 8px)`,
        display: 'inline-flex'
    },
}));

const MeterForm = ({isFetching, onSubmit, accountId, accounts, getMeter, meterId, meter}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [displayForm, setDisplayForm] = React.useState(false);
    const formikRef = useRef();

    useEffect(() => {
        if (meterId) {
            getMeter(meterId);
        } else {
            setDisplayForm(true);
        }
    }, []);

    useEffect(() => {
        if (meter.meter && meter.meter.meter_id) {
            mapObjectToValues();
            setDisplayForm(true);
        }
    }, [meter]);

    useEffect(() => {
        if (accounts.length) {
            mapObjectToValues();
        }
    }, [accounts]);

    const validationRules = Yup.object().shape({
        meter_number: Yup.string()
            .required('Required'),
        pulse_weight: Yup.number().positive(),
        account_name: Yup.string()
            .required('Required'),
        account_id: Yup.number()
            .required('Required'),
        notes: Yup.string()
    });

    const onSubmitAction = (values, setSubmitting) => {
        setSubmitting(false);
        if (meterId) {
            values.meter_id = meterId;
        }
        delete values.account_name;
        onSubmit(values);
    };

    const mapObjectToValues = () => {
        const map = {
            meter_number: meter.meter.meter_number || '',
            pulse_weight: meter.meter.pulse_weight || '',
            account_id: meter.meter.account ? meter.meter.account.account_id : '',
            account_name: meter.meter.account ? meter.meter.account.account_name : '',
            account: meter.meter.account,
            notes: meter.meter.notes || ''
        }
        return map;
    };

    const onChangeAccount = (event, account) => {
        if (account) {
            formikRef.current.setFieldValue('account_id', account.account_id || '');
            formikRef.current.setFieldValue('account_name', account.account_name || '');
        } else {
            formikRef.current.resetForm();
        }
    };

    return (
        <Formik
            initialValues={mapObjectToValues()}
            enableReinitialize={true}
            ref={formikRef}
            validationSchema={validationRules}
            onSubmit={(values, {setSubmitting}) => {
                onSubmitAction(values, setSubmitting)
            }}
        >
            {({handleSubmit, errors, values, handleChange, handleBlur, touched, isSubmitting}) => {

                return (
                    <div>
                        {displayForm && <form onSubmit={handleSubmit} className={classes.form} noValidate>
                            <Paper className={classes.root}>
                                <Grid container>
                                    <Grid item sm={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.meter_number && touched.meter_number}
                                            id="meter_number"
                                            label="Meter Number"
                                            name="meter_number"
                                            value={values.meter_number}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.meter_number && touched.meter_number) && errors.meter_number}
                                            className={classes.textFieldLeft}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            disabled={!!meterId && meter.meter.disabled_flag === 0}
                                            error={errors.pulse_weight && touched.pulse_weight}
                                            id="pulse_weight"
                                            label="Pulse Weight"
                                            name="pulse_weight"
                                            value={values.pulse_weight}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.pulse_weight && touched.pulse_weight) && errors.pulse_weight}
                                            className={classes.textField}
                                        />
                                        <Autocomplete
                                            id="field_2_autocomplete"
                                            open={open}
                                            onOpen={() => {
                                                setOpen(true);
                                            }}
                                            onClose={() => {
                                                setOpen(false);
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            className={classes.autocomplete}
                                            getOptionLabel={option => option.account_name}
                                            onChange={onChangeAccount}
                                            onBlur={handleBlur}
                                            options={accounts}
                                            defaultValue={values.account}
                                            filterSelectedOptions={true}
                                            loading={accounts.isFetching}
                                            disableOpenOnFocus={true}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    id="field_2"
                                                    label="Account"
                                                    variant="outlined"
                                                    autoComplete="field_2"
                                                    value={values.account_name}
                                                    error={errors.account_name && touched.account_name}
                                                    onChange={(event) => {
                                                        values.account_name = event.target.value;
                                                    }}
                                                    name="field_2"
                                                    helperText={(errors.account_name && touched.account_name) && errors.account_name}
                                                />
                                            )}
                                        />
                                        <TextField
                                            variant="outlined"
                                            id="notes"
                                            label="Notes"
                                            error={errors.notes && touched.notes}
                                            multiline
                                            rowsMax="4"
                                            value={values.notes}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.notes && touched.notes) && errors.notes}
                                            margin="normal"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Grid container direction={'row-reverse'}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        disabled={isSubmitting}
                                        color="default"
                                        className={classes.closeButton}
                                        component={Link}
                                        to="/meters"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={isSubmitting}
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        {isFetching && !meterId && <span>Creating Meter ...</span>}
                                        {!isFetching && !meterId && <span>Create</span>}
                                        {isFetching && meterId && <span>Updating Meter ...</span>}
                                        {!isFetching && meterId && <span>Update</span>}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        }
                    </div>
                );
            }}
        </Formik>
    );
};

MeterForm.propTypes = {
    onSubmit: PropTypes.func,
    isFetching: PropTypes.bool
};

export default MeterForm;


import React from 'react';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from "@material-ui/core/Tooltip";
import MaterialTable from "material-table";
import Fab from "@material-ui/core/Fab";

const momentParseFormat = 'YYYY-MM-DD HH:mm:ss';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    fab: {
        margin: theme.spacing(0.5),
    },
    progress: {
        margin: theme.spacing(0.5),
    },
}));

const GatewayMeterTable = ({meters, onRemoveMeter}) => {
    const classes = useStyles();

    const columns = [
        {title: "Meter ID", field: "meter_id", width: '100px'},
        {title: "Meter Number", field: "meter_number", type: "numeric"},
        {title: "Pulse Weight", field: "pulse_weight"},
        {title: "Address Mapping", field: "address_mapping"}
    ];

    const handleRemoveMeter = (event, meter) => {
        onRemoveMeter(meter);
    }
    if (meters) {
        return (
            <Paper className={classes.root}>
                <MaterialTable
                    columns={columns}
                    data={meters}
                    options={{
                        cellStyle: {textAlign: 'center'},
                        search: false,
                        sorting: false,
                        toolbar: false,
                        paging: false,
                        showTitle: false,
                        actionsColumnIndex: 5,
                        headerStyle: {width: '100px', textAlign: 'center'},
                    }}
                    actions={[
                        rowData => (
                            {
                                icon: () => {
                                    return (<DeleteIcon/>);
                                },
                                tooltip: "Remove",
                                onClick: handleRemoveMeter
                            })
                    ]}
                />
            </Paper>
        );
    } else {
        return '';
    }
};

GatewayMeterTable.propTypes = {
    meters: PropTypes.array
};

export default GatewayMeterTable;

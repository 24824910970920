import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getBusiness, cleanBusiness} from "../../../actions/businessActions";
import BusinessTable from "./BusinessTable";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    titleContainer: {
        margin: theme.spacing(1, 0, 3),
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    progress: {
        margin: theme.spacing(0.5),
    },
    loadingContainer: {
        textAlign: 'center',
    }
}));

const BusinessPage = ({business, getBusiness, cleanBusiness}) => {
    const classes = useStyles();

    useEffect(() => {
        fetchBusiness()

        return() => {
            cleanBusiness()
        }   
    }, []);

    const fetchBusiness = (page, search, orderColumn, orderDirection) => {
        getBusiness(0, page, search, orderColumn, orderDirection)
    };

    const LoadingSpinner = () => {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress className={classes.progress}/>
            </div>
        );
    };


    const renderBusinessList = () => {
        if (business.isFetching)
            return <LoadingSpinner/>;

        if (business.error)
            return <h3>There was an error getting the business: {business.errorMessage}</h3>;

        return (
            <BusinessTable
                business={business.data}
                paginator={business.paginator}
                fetchBusiness={fetchBusiness}
                searchInput={business.searchInput}
                orderColumn={business.orderColumn}
                orderDirection={business.orderDirection}
            />
        );
    };

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    <Typography variant="h4" component="h4">
                        Business
                    </Typography>
                </Grid>
            </Grid>
            {renderBusinessList()}
        </Container>
    );
};

const mapStateToProps = state => ({
    business: state.business
});

const mapDispatchToProps = {
    getBusiness,
    cleanBusiness
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPage);

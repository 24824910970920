import React from 'react';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import MaterialTable, {MTableHeader} from "material-table";
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";

const momentParseFormat = 'YYYY-MM-DD HH:mm:ss';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    fab: {
        margin: theme.spacing(0.5),
    },
    progress: {
        margin: theme.spacing(0.5),
    },
}));

const BusinessTable = ({business, paginator, fetchBusiness, searchInput, orderColumn, orderDirection}) => {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        fetchBusiness(newPage, searchInput, orderColumn, orderDirection);
    };

    const onSearchChange = (search) => {
        fetchBusiness(0, search)
    };

    const onSortChange = (orderedColumnId, orderDirection) => {
        fetchBusiness(paginator.currentPage, searchInput, columns[orderedColumnId].field, orderDirection);
    };

    const getSortColumnId = (orderColumn) => {
        return columns.findIndex(column => column.field === orderColumn);
    };

    const columns = [
        {title: "Business ID", field: "business_id"},
        {title: "Business Name", field: "full_business_name"},
        {title: "Primary Contact Name", field: "primary_contact_name"},
        {title: "Primary Contact Phone", field: "primary_contact_phone", sorting: false},
        {
            title: "Address", render: (business) => {
                return business.address.street + ', ' + business.address.city + ', ' + business.address.state
            }, sorting: false
        }
    ];

    if (business) {
        return (
            <MaterialTable
                columns={columns}
                data={business}
                title="Business Table"
                options={{
                    pageSize: 20,
                    debounceInterval: 800,
                    searchText: searchInput,
                    actionsColumnIndex: 5
                }}
                onSearchChange={onSearchChange}
                components={{
                    Header: props => (
                        <MTableHeader {...props} onOrderChange={onSortChange} orderBy={getSortColumnId(orderColumn)}
                                      orderDirection={orderDirection}/>
                    ),
                    Pagination: () => (
                        <TablePagination
                            page={paginator.currentPage}
                            count={paginator.totalRecords}
                            rowsPerPageOptions={[]}
                            rowsPerPage={20}
                            onChangePage={handleChangePage}
                        />
                    )
                }}
                actions={[
                    rowData => (
                        {
                            icon: () => {
                                return (
                                    <Tooltip title="Edit" aria-label="Edit">
                                        <Fab color="primary" className={classes.fab} size="small" component={Link}
                                             to={`business/${rowData.business_id}`}>
                                            <EditIcon/>
                                        </Fab>
                                    </Tooltip>
                                );
                            }
                        })
                ]}
            />
        );
    }
    return '';
};

BusinessTable.defaultProps = {};

BusinessTable.propTypes = {
    business: PropTypes.array,
    paginator: PropTypes.object,
    fetchBusiness: PropTypes.func,
    searchInput: PropTypes.string,
    orderColumn: PropTypes.string,
    orderDirection: PropTypes.string
};

export default BusinessTable;

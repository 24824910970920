import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {Container, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import {Link} from "react-router-dom";
import {getBusinessById, updateBusiness} from "../../../actions/businessActions";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    titleContainer: {
        margin: theme.spacing(1, 0, 3),
    },
    root: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(4)
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2, 0, 0),
    },
    formBox: {
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2, 4, 4, 4),
        borderRadius: '4px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);'
    },
    fullWidth: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textFieldLeft: {
        marginRight: theme.spacing(2),
        width: `calc((100% - 16px)/2)`
    },
    textField: {
        width: `calc((100% - 16px)/2)`
    },
    spaceBetween: {
        marginRight: theme.spacing(2),
    },
    closeButton: {
        borderRadius: `4px !important`,
        marginRight: `16px !important`
    },
    progress: {
        margin: theme.spacing(0.5),
    },
    loadingContainer: {
        textAlign: 'center',
    }
}));

const BusinessEditionPage = ({getBusinessById, updateBusiness, businessById, match}) => {

    const classes = useStyles();
    const businessId = match.params.id;

    useEffect(() => {
        getBusinessById(businessId);
    }, []);

    useEffect(() => {
        if (businessById.data && businessById.data.business_id) {
            mapObjectToValues();
        }
    }, [businessById]);

    const validationRules = () => {
        return Yup.object().shape({
            business_id: Yup.string(),
            full_business_name: Yup.string()
                .required('Required'),
            business_type: Yup.string()
                .required('Required'),
            business_street: Yup.string()
                .required('Required'),
            business_city: Yup.string()
                .required('Required'),
            business_state: Yup.string()
                .required('Required'),
            business_country: Yup.string()
                .required('Required'),
            business_zipcode: Yup.string()
                .required('Required'),
            primary_contact_name: Yup.string()
                .required('Required'),
            primary_contact_email: Yup.string()
                .email()
                .required('Required'),
            primary_contact_phone: Yup.string().matches(/[\d() -]+$/, {message: 'Format valid: (999) 999-9999'})
                .required('Required')
        });
    };

    const mapValuesToObject = values => {
        let businessObject = {
            business_id: values.business_id,
            full_business_name: values.full_business_name,
            business_type: values.business_type,
            primary_contact_name: values.primary_contact_name,
            primary_contact_email: values.primary_contact_email,
            primary_contact_phone: values.primary_contact_phone,
            address: {
                address_id: values.address_id,
                address_type: 'business_address',
                street: values.business_street,
                city: values.business_city,
                state: values.business_state,
                country: values.business_country,
                zipcode: values.business_zipcode
            }
        };
        return businessObject;
    };

    const mapObjectToValues = () => {
        const result = {
            business_id: businessById.data.business_id || '',
            address_id: businessById.data.address ? businessById.data.address.address_id : '',
            full_business_name: businessById.data.full_business_name || '',
            business_type: businessById.data.business_type || '',
            primary_contact_name: businessById.data.primary_contact_name || '',
            primary_contact_email: businessById.data.primary_contact_email || '',
            primary_contact_phone: businessById.data.primary_contact_phone || '',
            business_street: businessById.data.address ? businessById.data.address.street : '',
            business_city: businessById.data.address ? businessById.data.address.city : '',
            business_state: businessById.data.address ? businessById.data.address.state : '',
            business_country: businessById.data.address ? businessById.data.address.country : '',
            business_zipcode: businessById.data.address ? businessById.data.address.zipcode : '',
        };
        return result;
    };

    const onSubmit = (values) => {
        updateBusiness(values);
    };

    const LoadingSpinner = () => {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress className={classes.progress}/>
            </div>
        );
    };

    const renderBusinessForm = () => {
        if (businessById.isFetching) {
            return <LoadingSpinner/>;
        }

        return (<Formik
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={mapObjectToValues()}
            validationSchema={validationRules}
            onSubmit={(values) => {
                onSubmit(mapValuesToObject(values));
            }}
        >
            {({handleSubmit, errors, values, handleChange, handleBlur, touched}) => {
                return (
                    <form onSubmit={handleSubmit} className={classes.form} noValidate autoComplete="off">
                        <Paper className={classes.root}>
                            <Grid container>
                                <Grid item sm={12}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.full_business_name && touched.full_business_name}
                                        id="full_business_name"
                                        label="Business Name"
                                        name="full_business_name"
                                        value={values.full_business_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.full_business_name && touched.full_business_name) && errors.full_business_name}
                                        className={classes.textFieldLeft}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_street && touched.business_street}
                                        id="business_street"
                                        label="Business Street"
                                        name="business_street"
                                        value={values.business_street}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_street && touched.business_street) && errors.business_street}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        select
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu
                                            }
                                        }}
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_type && touched.business_type}
                                        id="business_type"
                                        label="Business Type"
                                        name="business_type"
                                        value={values.business_type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_type && touched.business_type) && errors.business_type}
                                        className={classes.textFieldLeft}
                                    >
                                        <MenuItem value="commercial">Commercial</MenuItem>
                                        <MenuItem value="industrial">Industrial</MenuItem>
                                    </TextField>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_city && touched.business_city}
                                        id="business_city"
                                        label="Business City"
                                        name="business_city"
                                        value={values.business_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_city && touched.business_city) && errors.business_city}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.primary_contact_name && touched.primary_contact_name}
                                        id="primary_contact_name"
                                        label="Primary Contact Name"
                                        name="primary_contact_name"
                                        value={values.primary_contact_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.primary_contact_name && touched.primary_contact_name) && errors.primary_contact_name}
                                        className={classes.textFieldLeft}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_state && touched.business_state}
                                        id="business_state"
                                        label="Business State"
                                        name="business_state"
                                        value={values.business_state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_state && touched.business_state) && errors.business_state}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.primary_contact_email && touched.primary_contact_email}
                                        id="primary_contact_email"
                                        label="Primary Contact Email"
                                        name="primary_contact_email"
                                        value={values.primary_contact_email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.primary_contact_email && touched.primary_contact_email) && errors.primary_contact_email}
                                        className={classes.textFieldLeft}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_country && touched.business_country}
                                        id="business_country"
                                        label="Business Country"
                                        name="business_country"
                                        value={values.business_country}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_country && touched.business_country) && errors.business_country}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.primary_contact_phone && touched.primary_contact_phone}
                                        id="primary_contact_phone"
                                        label="Primary Contact Phone"
                                        name="primary_contact_phone"
                                        value={values.primary_contact_phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.primary_contact_phone && touched.primary_contact_phone) && errors.primary_contact_phone}
                                        className={classes.textFieldLeft}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        error={errors.business_zipcode && touched.business_zipcode}
                                        id="business_zipcode"
                                        label="Business Zipcode"
                                        name="business_zipcode"
                                        value={values.business_zipcode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.business_zipcode && touched.business_zipcode) && errors.business_zipcode}
                                        className={classes.textField}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Grid container direction={'row-reverse'}>
                            <Grid item>
                                <Fab
                                    color="default"
                                    size="small"
                                    component={Link}
                                    to={`/business`}
                                    variant={'extended'}
                                    className={classes.closeButton}
                                >
                                    Cancel
                                </Fab>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )
            }}
        </Formik>);
    };

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    <Typography variant="h4" component="h4">
                        Edit Business
                    </Typography>
                </Grid>
            </Grid>
            {renderBusinessForm()}
        </Container>
    );
};

BusinessEditionPage.propTypes = {
    getBusiness: PropTypes.func,
};

const mapStateToProps = state => ({
    businessById: state.businessById
});

const mapDispatchToProps = {
    getBusinessById,
    updateBusiness
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessEditionPage);


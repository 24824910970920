import {
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS,
    LOG_IN_ERROR,
    LOG_OUT_REQUEST,
    LOG_OUT_SUCCESS, GET_LOGGED_USER_REQUEST, GET_LOGGED_USER_SUCCESS, GET_LOGGED_USER_ERROR
} from '../actions/actionTypes';

const INITIAL_STATE = {
    isFetching: false,
    isAuthenticated: !!localStorage.getItem('TOKEN'),
    token: localStorage.getItem('TOKEN'),
    user: localStorage.getItem('USER') ? JSON.parse(localStorage.getItem('USER')) : {},
    error: false,
    errorMessage: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOG_IN_REQUEST:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                error: false,
                errorMessage: ''
            };
        case LOG_IN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                token: action.payload.token,
                error: false,
                errorMessage: '',
            };
        case LOG_IN_ERROR:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                error: true,
                errorMessage: action.payload,
            };

        case GET_LOGGED_USER_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case GET_LOGGED_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                user: action.user,
                error: false,
                errorMessage: '',
            };

        case GET_LOGGED_USER_ERROR:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.error,
            };

        case LOG_OUT_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case LOG_OUT_SUCCESS:
            return {
                isFetching: false,
                isAuthenticated: false,
            };

        default:
            return state;
    }
};

import {
    ACCOUNT_CLEAN_UP, CREATE_ACCOUNT_CLEAN_UP,
    CREATE_ACCOUNT_ERROR,
    CREATE_ACCOUNT_REQUEST,
    CREATE_ACCOUNT_SUCCESS,
    GET_ACCOUNT_ERROR,
    GET_ACCOUNT_REQUEST,
    GET_ACCOUNT_SUCCESS,
    GET_ACCOUNTS_ERROR,
    GET_ACCOUNTS_REQUEST,
    GET_ACCOUNTS_SUCCESS,
    VALIDATE_ACCOUNT_NUMBER_ERROR,
    VALIDATE_ACCOUNT_NUMBER_REQUEST,
    VALIDATE_ACCOUNT_NUMBER_SUCCESS
} from '../actions/actionTypes';

const INITIAL_STATE = {
    isFetching: false,
    data: [],
    paginator: {
        currentPage: 0,
        totalRecords: 0,
        from: 0,
        to: 0
    },
    error: false,
    errorMessage: '',
    searchInput: '',
    orderColumn: 'account_name',
    orderDirection: 'asc'
};

const INITIAL_STATE_ACCOUNT = {
    isFetching: false,
    account: {},
    error: false,
    errorMessage: ''
};

const INITIAL_STATE_ACCOUNT_CREATE = {
    isFetching: false,
    account: {},
    error: false,
    errorMessage: ''
};

export const accountNumber = (state = INITIAL_STATE_ACCOUNT, action) => {
    switch (action.type) {
        case VALIDATE_ACCOUNT_NUMBER_REQUEST:
            return {
                ...state,
                isFetching: true,
                account: {},
                error: false,
                errorMessage: ''
            };

        case VALIDATE_ACCOUNT_NUMBER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                account: action.account,
                error: false,
                errorMessage: ''
            };

        case VALIDATE_ACCOUNT_NUMBER_ERROR:
            return {
                ...state,
                isFetching: false,
                account: {},
                error: true,
                errorMessage: action.payload
            };

        default:
            return state;
    }
};

export const account = (state = INITIAL_STATE_ACCOUNT, action) => {
    switch (action.type) {
        case GET_ACCOUNT_REQUEST:
            return {
                ...state,
                isFetching: true,
                account: {},
                error: false,
                errorMessage: ''
            };

        case GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                account: action.account,
                error: false,
                errorMessage: ''
            };

        case GET_ACCOUNT_ERROR:
            return {
                ...state,
                isFetching: false,
                account: {},
                error: true,
                errorMessage: action.payload
            };

        case ACCOUNT_CLEAN_UP:
            return {
                ...state,
                isFetching: false,
                account: {},
                error: false,
                errorMessage: ''
            };

        case ACCOUNT_CLEAN_UP:
            return {
                ...state,
                isFetching: false,
                account: {},
                error: false,
                errorMessage: ''
            };

        default:
            return state;
    }
};

export const accounts = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ACCOUNTS_REQUEST:
            return {
                ...state,
                isFetching: true,
                data: [],
                error: false,
                errorMessage: '',
                searchInput: '',
                orderColumn: action.orderColumn,
                orderDirection: action.orderDirection,
            };

        case GET_ACCOUNTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.accounts,
                paginator: {
                    currentPage: action.currentPage,
                    totalRecords: action.totalRecords,
                    from: action.from,
                    to: action.to
                },
                error: false,
                errorMessage: '',
                searchInput: action.searchInput,
                orderColumn: action.orderColumn,
                orderDirection: action.orderDirection
            };

        case GET_ACCOUNTS_ERROR:
            return {
                ...state,
                isFetching: false,
                data: [],
                error: true,
                errorMessage: action.payload,
                searchInput: '',
                orderColumn: '',
                orderDirection: '',
            };

        default:
            return state;
    }
};

export const accountCreate = (state = INITIAL_STATE_ACCOUNT_CREATE, action) => {
    switch (action.type) {
        case CREATE_ACCOUNT_REQUEST:
            return {
                ...state,
                isCreating: true,
                account: {},
                error: false,
                errorMessage: ''
            };

        case CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isCreating: false,
                account: action.account,
                error: false,
                errorMessage: '',
            };

        case CREATE_ACCOUNT_ERROR:
            return {
                ...state,
                isCreating: false,
                account: {},
                error: true,
                errorMessage: action.payload,
            };

        case CREATE_ACCOUNT_CLEAN_UP:
            return {
                ...state,
                isFetching: false,
                account: {},
                error: false,
                errorMessage: ''
            };
        default:
            return state;
    }
};

import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import {getGateways} from "../../../actions/gatewayActions";
import GatewayTable from "./GatewayTable";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import RouterIcon from '@material-ui/icons/Router';
import RefreshIcon from "@material-ui/icons/Refresh";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    alignRight: {
        textAlign: 'right'
    },
    loadingContainer: {
        textAlign: 'center'
    }
}));


const GatewayPage = ({gateways, getGateways}) => {
    const classes = useStyles();

    useEffect(() => {
        fetchGateways()
    }, []);

    const fetchGateways = (page, search, orderColumn, orderDirection) => {
        getGateways(page, search, orderColumn, orderDirection);
    };

    const onRefresh = () => {
        fetchGateways();
    };

    const LoadingSpinner = () => {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress className={classes.progress}/>
            </div>
        );
    };

    const renderGatewayList = () => {
        if (gateways.isFetching)
            return <LoadingSpinner />;

        if (gateways.error)
            return <h3>There was an error getting the gateways: {gateways.errorMessage}</h3>;

        return (
            <GatewayTable
                gateways={gateways.data}
                paginator={gateways.paginator}
                fetchGateways={fetchGateways}
                searchInput={gateways.searchInput}
                orderColumn={gateways.orderColumn}
                orderDirection={gateways.orderDirection}
            />
        );
    };

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    <Typography variant="h4" component="h4">
                        Gateways
                    </Typography>
                </Grid>
                <Grid item sm={4} className={classes.alignRight}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        component={Link}
                        to="/gateways/create">
                        New Gateway
                        <RouterIcon className={classes.rightIcon}/>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={onRefresh}>
                        Refresh <RefreshIcon/>
                    </Button>
                </Grid>
            </Grid>
            {renderGatewayList()}
        </Container>
    );
};

const mapStateToProps = state => ({
    gateways: state.gateways
});

const mapDispatchToProps = {
    getGateways
};

export default connect(mapStateToProps, mapDispatchToProps)(GatewayPage);

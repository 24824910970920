import React from 'react';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import moment from 'moment-timezone';
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Fab from '@material-ui/core/Fab';
import BuildIcon from '@material-ui/icons/Build';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import PowerIcon from '@material-ui/icons/Power';
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

const momentParseFormat = 'YYYY-MM-DD HH:mm:ss';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    fab: {
        margin: theme.spacing(0.5),
    },
    progress: {
        margin: theme.spacing(0.5),
    },
}));

const MeterTableOLDTOREMOVE = ({dashboard, meters, paginator, fetchMeters, updateMeterStatus}) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);


    const handleChangePage = (event, newPage) => {
        fetchMeters(newPage);
    };

    const renderActions = (meter) => {
        if (loading) {
            return <LoadingSpinner/>;
        }

        const handleConfigureMeter = value => {
            console.log('Getting hash... and change meter status to `configuring`');
            changeMeterStatus('configuring');
        };

        const handleInstallMeter = value => {
            console.log('Change meter status to `installed`');
            changeMeterStatus('installed');
        };

        const changeMeterStatus = status => {
            updateMeterStatus(meter.meter_id, status);
        };

        return (
            <div>
                {meter.status === 'initialized' && (
                    <Tooltip title="Configure" aria-label="Configure">
                        <Fab color="primary" className={classes.fab} size="small" onClick={handleConfigureMeter}>
                            <BuildIcon/>
                        </Fab>
                    </Tooltip>
                )}
                {meter.status === 'configured' && (
                    <Tooltip title="Install" aria-label="Install">
                        <Fab color="default" className={classes.fab} size="small" onClick={handleInstallMeter}>
                            <PowerIcon/>
                        </Fab>
                    </Tooltip>
                )}
                {meter.status === 'issue' && ( // Add another condition for example if meter is not receiving notifications well
                    <Tooltip title="Problem" aria-label="Problem">
                        <Fab color="secondary" className={classes.fab} size="small">
                            <ReportProblemIcon/>
                        </Fab>
                    </Tooltip>
                )}
            </div>
        );

    };

    const LoadingSpinner = () => {
        return (
            <CircularProgress className={classes.progress}/>
        );
    };

    if (meters) {
        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Meter ID</TableCell>
                            <TableCell>Meter Number</TableCell>
                            <TableCell>Account Name</TableCell>
                            <TableCell>Account #</TableCell>
                            <TableCell>Type</TableCell>
                            {/*<TableCell>Notes</TableCell>*/}
                            <TableCell>Last Reading Time</TableCell>
                            <TableCell>Last Reading Insert Time</TableCell>
                            <TableCell>Last Meter Check</TableCell>
                            <TableCell>Flat Readings</TableCell>
                            <TableCell>Real-Time Demand</TableCell>
                            {/*<TableCell>Delayed Data</TableCell>*/}
                            {!dashboard && <TableCell>Status</TableCell>}
                            {!dashboard && <TableCell>Actions</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {meters.map((meter, index) => {
                            let lastReadingTime = '-';
                            if (meter.last_reading_time) {
                                lastReadingTime = moment(meter.last_reading_time, momentParseFormat);
                                lastReadingTime = lastReadingTime.tz(moment.tz.guess()).format(momentParseFormat);
                            }

                            let lastReadingInsertTime = '-';
                            if (meter.last_reading_insert_time) {
                                lastReadingInsertTime = moment(meter.last_reading_insert_time, momentParseFormat);
                                lastReadingInsertTime = lastReadingInsertTime.tz(moment.tz.guess()).format(momentParseFormat);
                            }

                            let lastMeterCheck = '-';
                            if (meter.last_meter_check) {
                                lastMeterCheck = moment(meter.last_meter_check, momentParseFormat);
                                lastMeterCheck = lastMeterCheck.tz(moment.tz.guess()).format(momentParseFormat);
                            }

                            return (<TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {meter.meter_id}
                                </TableCell>
                                <TableCell>{meter.meter_number}</TableCell>
                                <TableCell>{meter.account_name}</TableCell>
                                <TableCell>{meter.account_number}</TableCell>
                                <TableCell>{meter.device_id ? 'Obvius' : 'iLon'}</TableCell>
                                <TableCell>{lastReadingTime}</TableCell>
                                <TableCell>{lastReadingInsertTime}</TableCell>
                                <TableCell>{lastMeterCheck}</TableCell>
                                <TableCell>{meter.flat_readings === 1 ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{meter.last_15_minutes_average_demand ? meter.last_15_minutes_average_demand.toFixed(2) + 'kW' : '-'}</TableCell>
                                {!dashboard && <TableCell>{meter.status}</TableCell>}
                                {!dashboard && <TableCell>{renderActions(meter)}</TableCell>}
                            </TableRow>);
                        })
                        }
                    </TableBody>
                    {paginator && <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={paginator.totalRecords}
                                page={paginator.currentPage}
                                from={paginator.from}
                                to={paginator.to}
                                rowsPerPageOptions={[]}
                                colSpan={5}
                                rowsPerPage={20}
                                onChangePage={handleChangePage}
                            />
                        </TableRow>
                    </TableFooter>
                    }
                </Table>
            </Paper>
        );
    }
};

MeterTableOLDTOREMOVE.defaultProps = {
    dashboard: false
};

MeterTableOLDTOREMOVE.propTypes = {
    dashboard: PropTypes.bool,
    meters: PropTypes.array,
    paginator: PropTypes.object,
    fetchMeters: PropTypes.func,
    updateMeterStatus: PropTypes.func
};

export default MeterTableOLDTOREMOVE;

import coiApi from '../api/coiApi';
import { LOG_IN_REQUEST, LOG_IN_SUCCESS, LOG_IN_ERROR, LOG_OUT_REQUEST, LOG_OUT_SUCCESS, LOG_OUT_ERROR, GET_LOGGED_USER_REQUEST, GET_LOGGED_USER_SUCCESS, GET_LOGGED_USER_ERROR } from './actionTypes';
import {tenantChange} from './tenantActions';

export const requestLogin = credentials => async (dispatch, getState) => {
    dispatch({
        type: LOG_IN_REQUEST,
    });

    try {
        const params = {
            email: credentials.email,
            password: credentials.password,
        };

        const { data } = await coiApi.post(
            '/admin/login',
            params
        );

        dispatch(
            loginSuccess({ token: data.token })
        );
    } catch (err) {
        const msg = err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch(loginError(msg));
    }
};

export const loginSuccess = userData => async (dispatch, getState) => {

    localStorage.setItem('TOKEN', userData.token);

    await dispatch(getLoggedUser(userData.token)); //Waits until we finish processing user data.

    dispatch({
        type: LOG_IN_SUCCESS,
        payload: userData,
    });

    dispatch(tenantChange());
};

export const loginError = message => {
    return {
        type: LOG_IN_ERROR,
        payload: message,
    };
};

export const getLoggedUser = (token) => async (dispatch) => {
    dispatch({
        type: GET_LOGGED_USER_REQUEST
    });

    try {
        const {data} = await coiApi.get(
            '/admin/admin-user/current',
        );

        localStorage.setItem('USER', JSON.stringify(data));

        dispatch({
            type: GET_LOGGED_USER_SUCCESS,
            user: data
        });

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: GET_LOGGED_USER_ERROR,
            error: msg
        });
    }
};

export const logoutUser = () => async (dispatch, getState) => {
    dispatch({
        type: LOG_OUT_REQUEST
    });

    try {
        await coiApi.get(
            '/admin/logout'
        );

        localStorage.removeItem('TOKEN');
        localStorage.removeItem('USER');
        localStorage.removeItem('TENANT');

        dispatch({
            type: LOG_OUT_SUCCESS,
        });

    } catch (err) {
        dispatch({
            type: LOG_OUT_ERROR
        });
    }
};

import React  from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from "@material-ui/icons/esm/AccountCircle";
import SwapVertIcon from '@material-ui/icons/SwapVert';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import COILogoInverted from '../../assets/images/logo_tagline_inverted.svg';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        marginLeft: theme.spacing(2)
    },
}));


const TopBar = ({drawerOpen, drawerOpenHandle, isAuthenticated, userLogged, logoutUser, tenant, onTenantChange}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tenantEl, setTenantEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const openTenantMenu = Boolean(tenantEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logoutUser(); //Call props function and logout user
        handleClose(); //Close menu.
    };

    const handleTenantChange = (event) => {
        console.log(event.currentTarget);

        const tenantSelected = event.currentTarget.textContent; //ToDo: change this is really ugly
        onTenantChange(tenantSelected);
        handleTenantMenuClose();
    };

    const handleTenantMenu = (event) => {
        setTenantEl(event.currentTarget);
    };

    const handleTenantMenuClose = () => {
        setTenantEl(null);
    };

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: drawerOpen,
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={drawerOpenHandle}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: drawerOpen || !isAuthenticated,
                    })}
                >
                    <MenuIcon />
                </IconButton>
                <img src={COILogoInverted} alt="COI Energy Logo" height="60" width="120" />
                <Typography variant="h6" className={classes.title}>
                    Admin
                </Typography>
                <div>
                </div>
                {isAuthenticated && (
                <div>
                    <IconButton
                        edge="end"
                        aria-label="Account of current user"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleTenantMenu}>
                        <Typography>
                            {tenant}
                        </Typography>
                        <SwapVertIcon />
                    </IconButton>

                    <Menu
                        id="menu-tenant"
                        anchorEl={tenantEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={openTenantMenu}
                        onClose={handleTenantMenuClose}>
                        <MenuItem onClick={handleTenantChange}>COI</MenuItem>
                        <MenuItem onClick={handleTenantChange}>TECO</MenuItem>
                        <MenuItem onClick={handleTenantChange}>NYPA</MenuItem>
                    </Menu>
                    <IconButton
                        edge="end"
                        aria-label="Account of current user"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleMenu}>
                        <Typography>
                            {userLogged.name}
                        </Typography>
                             <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>

                )}
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    isAuthenticated: PropTypes.bool,
    drawerOpen: PropTypes.bool,
    drawerOpenHandle: PropTypes.func,
    userLogged: PropTypes.object,
    logoutUser: PropTypes.func,
    tenant: PropTypes.string,
    onTenantChange: PropTypes.func,
};

export default TopBar;

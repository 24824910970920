import React from 'react';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import MaterialTable, {MTableHeader} from "material-table";
import Container from "@material-ui/core/Container";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import {Link} from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    fab: {
        margin: theme.spacing(0.5),
    },
    meterContainer: {
        margin: theme.spacing(2, 0),
    },
    displayNone: {
        display: 'none'
    }
}));

const GatewayTable = ({gateways, paginator, fetchGateways, searchInput, orderColumn, orderDirection}) => {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        fetchGateways(newPage, searchInput, orderColumn, orderDirection);
    };

    const onSearchChange = (search) => {
        fetchGateways(0, search)
    };

    const onSortChange = (orderedColumnId, orderDirection) => {
        fetchGateways(paginator.currentPage, searchInput, columns[orderedColumnId].field, orderDirection);
    };

    const getSortColumnId = (orderColumn) => {
        return columns.findIndex(column => column.field === orderColumn);
    };

    const columns = [
        {title: "Gateway ID", field: "gateway_id"},
        {title: "Unique Identifier", field: "unique_identifier"},
        {
            title: "Brand - Model", field: "brand", render: (rowData) => {
                return rowData.brand + ' - ' + rowData.model
            }
        },
        {
            title: "Account Name", sorting: false, render: (rowData) => {
                if (rowData.meter_list.length) {
                    return rowData.meter_list[0].account ? rowData.meter_list[0].account.account_name : '-';
                }
                return '-';
            }
        },
        {
            title: "Account Number", sorting: false, render: (rowData) => {
                if (rowData.meter_list.length) {
                    return rowData.meter_list[0].account ? rowData.meter_list[0].account.account_number : '-';
                }
                return '-';
            }
        },
        {title: "Installed Date", field: "install_date"},
    ];

    const meterColumns = [
        {title: "Meter ID", field: "meter_id"},
        {title: "Meter Number", field: "meter_number"},
        {title: "Pulse Weight", field: "pulse_weight"},
        {title: "Address Mapping", field: "address_mapping"}
    ];

    if (gateways) {
        return (
            <Paper className={classes.root}>
                <MaterialTable
                    columns={columns}
                    data={gateways}
                    title="Gateways Table"
                    options={{
                        pageSize: 20,
                        debounceInterval: 800,
                        searchText: searchInput,
                        actionsColumnIndex: 6
                    }}
                    detailPanel={[
                        rowData => ({
                            disabled: !rowData.meter_list.length,
                            icon: () => <ArrowForwardIosIcon className={(!rowData.meter_list.length) ? classes.displayNone : ''} />,
                            render: (rowData) => <Container maxWidth="xl" className={classes.meterContainer}>
                                <MaterialTable
                                    columns={meterColumns}
                                    data={rowData.meter_list}
                                    options={{
                                        showTitle: false,
                                        toolbar: false,
                                        search: false,
                                        paging: false
                                    }}
                                />
                            </Container>
                        })
                    ]}
                    onSearchChange={onSearchChange}
                    components={{
                        Header: props => (
                            <MTableHeader {...props} onOrderChange={onSortChange} orderBy={getSortColumnId(orderColumn)}
                                          orderDirection={orderDirection}/>
                        ),
                        Pagination: () => (
                            <TablePagination
                                page={paginator.currentPage}
                                count={paginator.totalRecords}
                                rowsPerPageOptions={[]}
                                rowsPerPage={20}
                                onChangePage={handleChangePage}
                            />
                        )
                    }}
                    actions={[
                        rowData => (
                            {
                                icon: () => {
                                    return (
                                        <Tooltip title="Edit" aria-label="Edit">
                                            <Fab color="primary" className={classes.fab} size="small" component={Link}
                                                 to={`gateways/${rowData.gateway_id}`}>
                                                <EditIcon/>
                                            </Fab>
                                        </Tooltip>
                                    );
                                },
                                hidden: rowData.type === 'ilon'
                            }
                        )
                    ]}
                />
            </Paper>
        );
    }
};

GatewayTable.propTypes = {
    gateways: PropTypes.array,
    paginator: PropTypes.object,
    fetchGateways: PropTypes.func,
    searchInput: PropTypes.string,
    orderColumn: PropTypes.string,
    orderDirection: PropTypes.string
};

export default GatewayTable;

import React, {useEffect} from 'react';
import {Container, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import MeterForm from "./MeterForm";
import {connect} from "react-redux";
import {getAccounts} from "../../../actions/accountActions";
import {createMeter, updateMeter, getMeter, cleanMeter} from "../../../actions/meterActions";
import {parse} from 'query-string'
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    titleContainer: {
        margin: theme.spacing(1, 0, 3),
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2, 0, 0),
    },
}));

const MeterCreatePage = ({isFetching, createMeter, getAccounts, accounts, location, updateMeter, getMeter, cleanMeter, match, meter}) => {
    const classes = useStyles();
    const [accountId, setAccountId] = React.useState(null);
    const meterId = match.params.id;

    useEffect(() => {
        getAccounts(1);
        const queryParams = parse(location.search);
        if (queryParams.account_id) {
            setAccountId(queryParams.account_id);
        }
        return () => {
            cleanMeter();
        }
    }, [location.search]);

    const onSubmit = (meter) => {
        if (meterId){
            updateMeter(meter);
        }else{
            createMeter(meter);
        }
    };

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    {!meterId && <Typography variant="h4" component="h4">
                        Create Meter
                    </Typography>}
                    {!!meterId && <Typography variant="h4" component="h4">
                        Edit Meter
                    </Typography>}
                </Grid>
            </Grid>
            <MeterForm
                isFetching={isFetching}
                accountId={accountId}
                getMeter={getMeter}
                meterId={meterId}
                meter={meter}
                accounts={accounts.data}
                onSubmit={onSubmit}/>
        </Container>

    );
};


const mapStateToProps = state => ({
    accounts: state.accounts,
    meter: state.meter
});

const mapDispatchToProps = {
    createMeter,
    updateMeter,
    getAccounts,
    getMeter,
    cleanMeter
};

export default connect(mapStateToProps, mapDispatchToProps)(MeterCreatePage);

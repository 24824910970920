import React, {useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {checkMeters} from "../../../actions/meterActions";
import MeterTableOLDTOREMOVE from "../meter/MeterTable-OLD-TO-REMOVE";
import Button from "@material-ui/core/Button";
import RefreshIcon from '@material-ui/icons/Refresh';
import {green, red} from "@material-ui/core/colors";
import MeterTable from "../meter/MeterTable";


const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    success: {
        color: green[500]
    },
    error: {
        color: red[500]
    }
}));

const DashboardPage = ({checkMetersData, checkMeters}) => {
    const classes = useStyles();

    useEffect(() => {
        fetchMeters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchMeters = () => {
        checkMeters();
    };

    const renderStats = () => {
        if (!checkMetersData.isFetching) {
            return (
                <div className="box__container">
                    <div className="box">All: <strong>{checkMetersData.stats.all}</strong></div>
                    <div className={classes.success}>Working: <strong>{checkMetersData.stats.working}</strong></div>
                    <div className={classes.error}>Issues: <strong>{checkMetersData.stats.issues}</strong></div>
                </div>
            );
        }
    };

    const onRefresh = () => {
        fetchMeters();
    };

    const renderLoading = () => {
        if (checkMetersData.isFetching)
            return <h3>Loading ...</h3>;
    };

    const renderTable = () => {
        if (!checkMetersData.isFetching) {
            if (checkMetersData.metersWithIssues.length === 0){
                return (
                    <p>No issues found.</p>
                );
            }
            return [
                <h3 key="h3">Meters with Issues</h3>,
                <Button key="refresh_btn"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={onRefresh}>
                    Refresh <RefreshIcon/>
                </Button>,
                <MeterTableOLDTOREMOVE
                    key="table"
                    meters={checkMetersData.metersWithIssues}
                    fetchMeters={fetchMeters}
                    dashboard={true}/>
            ];
        }
    };

    return (
        <Container maxWidth="xl">
            <h1>Dashboard</h1>
            <h2>Meters</h2>
            {renderLoading()}
            {renderStats()}
            {renderTable()}
        </Container>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
    checkMetersData: state.checkMeters
});

const mapDispatchToProps = {
    checkMeters
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);


import { TENANT_CHANGE_SUCCESS, TENANT_CHANGE_ERROR } from './actionTypes';

export const tenantChange = (tenant) => async (dispatch, getState) => {
    try {
        console.log(`tenant on action ${tenant}`)

        if (!tenant){ //If tenant param is not set
            //Default settings
            if (window.location.href.includes('https://admin.coioptimizer.com')){ //Prod env
                tenant = 'TECO';
            } else {
                tenant = 'COI';
            }
        }

        localStorage.setItem('TENANT', tenant);

        dispatch({
            type: TENANT_CHANGE_SUCCESS,
            tenant: tenant
        });

        window.location.reload();

    } catch (err) {
        dispatch({
            type: TENANT_CHANGE_ERROR
        });
    }
};

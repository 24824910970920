import coiApi from '../api/coiApi';

import {
    CREATE_GATEWAY_ERROR,
    CREATE_GATEWAY_REQUEST,
    CREATE_GATEWAY_SUCCESS,
    CREATE_GATEWAY_CLEAN_UP,
    GET_GATEWAY_ERROR,
    GET_GATEWAY_REQUEST,
    GET_GATEWAY_SUCCESS,
    GET_GATEWAYS_ERROR,
    GET_GATEWAYS_REQUEST,
    GET_GATEWAYS_SUCCESS, UPDATE_GATEWAY_ERROR, UPDATE_GATEWAY_REQUEST, UPDATE_GATEWAY_SUCCESS,
} from "./actionTypes";
import history from "../history";

export const getGateways = (page = 0, search = '', orderColumn = 'gateway_id', orderDirection = 'desc') => async (dispatch) => {
    dispatch({
        type: GET_GATEWAYS_REQUEST,
        orderColumn,
        orderDirection
    });

    try {
        page++;

        const {data} = await coiApi.get(
            `/admin/gateway?page=${page}&search=${search}&orderColumn=${orderColumn}&orderDirection=${orderDirection}`
        );

        dispatch({
            type: GET_GATEWAYS_SUCCESS,
            meters: data.data,
            currentPage: data.current_page - 1, //It's zero-based index.
            totalRecords: data.total,
            from: data.from,
            to: data.to,
            searchInput: search,
            orderColumn: orderColumn,
            orderDirection: orderDirection
        });
    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: GET_GATEWAYS_ERROR,
            payload: msg
        });
    }
};

export const createGateway = (gateway) => async (dispatch) => {
    dispatch({
        type: CREATE_GATEWAY_REQUEST,
    });

    try {

        const {data} = await coiApi.post(
            `/admin/gateway`, gateway
        );

        dispatch({
            type: CREATE_GATEWAY_SUCCESS,
            meter: data,
        });

        history.push('/gateways');

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: CREATE_GATEWAY_ERROR,
            payload: msg
        });
    }
};

export const updateGateway = (gateway) => async (dispatch) => {
    dispatch({
        type: UPDATE_GATEWAY_REQUEST,
    });

    try {

        const {data} = await coiApi.put(
            `/admin/gateway/${gateway.gateway_id}`, gateway
        );

        dispatch({
            type: UPDATE_GATEWAY_SUCCESS,
            meter: data,
        });

        history.push('/gateways');

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: UPDATE_GATEWAY_ERROR,
            payload: msg
        });
    }
};

export const getGateway = (id) => async (dispatch) => {
    dispatch({
        type: GET_GATEWAY_REQUEST
    });

    try {
        const {data} = await coiApi.get(
            `/admin/gateway/${id}`
        );

        dispatch({
            type: GET_GATEWAY_SUCCESS,
            gateway: data
        });

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: GET_GATEWAY_ERROR,
            payload: msg
        });
    }
};

export const cleanGateway = () => async (dispatch) => {
    dispatch({
        type: CREATE_GATEWAY_CLEAN_UP
    });
};

import React, {useEffect} from 'react';
import {Container, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import GatewayForm from "./GatewayForm";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {getUnassociatedMeters} from "../../../actions/meterActions";
import {createGateway, getGateway, updateGateway, cleanGateway} from "../../../actions/gatewayActions";

const useStyles = makeStyles(theme => ({
    titleContainer: {
        margin: theme.spacing(1, 0, 3),
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2, 0, 0),
    },
}));

const GatewayCreatePage = ({getGateway, createGateway, gateway, updateGateway, cleanGateway, getUnassociatedMeters, unassociatedMeters, match}) => {
    const classes = useStyles();
    const gatewayId = match.params.id;

    useEffect(() => {
        getUnassociatedMeters();
        if (gatewayId) {
            getGateway(gatewayId);
        }

        return () => {
            cleanGateway();
        }
    }, []);

    const onsubmit = (gateway) => {
        if (gatewayId){
            updateGateway(gateway);
        }else {
            createGateway(gateway);
        }
    };
    
    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    {!gatewayId && <Typography variant="h4" component="h4">
                        Create Gateway
                    </Typography>}
                    {!!gatewayId && <Typography variant="h4" component="h4">
                        Edit Gateway
                    </Typography>}
                </Grid>
            </Grid>
            <GatewayForm
                isFetching={gateway.isFetching}
                gateway={gateway}
                unassociatedMeters={unassociatedMeters}
                onSubmit={onsubmit}/>
        </Container>

    );
};


const mapStateToProps = state => ({
    gateway: state.gateway,
    unassociatedMeters: state.unassociatedMeters,
});

const mapDispatchToProps = {
    createGateway,
    updateGateway,
    getUnassociatedMeters,
    getGateway,
    cleanGateway
};

export default connect(mapStateToProps, mapDispatchToProps)(GatewayCreatePage);

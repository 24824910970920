import coiApi from '../api/coiApi';

import {
    GET_BUSINESS_BY_ID_ERROR,
    GET_BUSINESS_BY_ID_REQUEST,
    GET_BUSINESS_BY_ID_SUCCESS,
    GET_BUSINESS_BY_NAME_ERROR,
    GET_BUSINESS_BY_NAME_REQUEST,
    GET_BUSINESS_BY_NAME_SUCCESS,
    GET_BUSINESS_ERROR,
    GET_BUSINESS_REQUEST,
    GET_BUSINESS_SUCCESS,
    UPDATE_BUSINESS_ERROR, UPDATE_BUSINESS_REQUEST,
    UPDATE_BUSINESS_SUCCESS,
    UPDATE_METERS_ERROR,
    UPDATE_METERS_REQUEST,
    UPDATE_METERS_SUCCESS,
    BUSINESS_CLEANUP
} from "./actionTypes";
import {getMeters} from "./meterActions";
import history from "../history";

export const getBusiness = (all = 0, page = 0, search = '', orderColumn = 'business_id', orderDirection = 'desc') => async (dispatch) => {
    dispatch({
        type: GET_BUSINESS_REQUEST,
        orderColumn,
        orderDirection
    });

    try {
        page++;

        const {data} = await coiApi.get(
            `/admin/business?page=${page}&search=${search}&orderColumn=${orderColumn}&orderDirection=${orderDirection}&all=${all}`
        );

        dispatch({
            type: GET_BUSINESS_SUCCESS,
            business: data.data,
            currentPage: data.current_page - 1,
            totalRecords: data.total,
            from: data.from,
            to: data.to,
            searchInput: search,
            orderColumn: orderColumn,
            orderDirection: orderDirection
        });

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: GET_BUSINESS_ERROR,
            payload: msg
        });
    }
};

export const getBusinessByName = (name) => async (dispatch) => {
    dispatch({
        type: GET_BUSINESS_BY_NAME_REQUEST,
    });

    try {
        const {data} = await coiApi.post(
            `/admin/business/with_full_business_name`,
            {full_business_name: name}
        );

        dispatch({
            type: GET_BUSINESS_BY_NAME_SUCCESS,
            business: data
        });

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: GET_BUSINESS_BY_NAME_ERROR,
            payload: msg
        });
    }
};

export const getBusinessById = (id) => async (dispatch) => {
    dispatch({
        type: GET_BUSINESS_BY_ID_REQUEST,
    });

    try {
        const {data} = await coiApi.get(
            `/admin/business/${id}`
        );

        dispatch({
            type: GET_BUSINESS_BY_ID_SUCCESS,
            business: data
        });

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: GET_BUSINESS_BY_ID_ERROR,
            payload: msg
        });
    }
};


export const updateBusiness = (business) => async (dispatch) => {
    dispatch({
        type: UPDATE_BUSINESS_REQUEST,
    });

    try {

        const { data } = await coiApi.put(
            `/admin/business/${business.business_id}`, business
        );

        dispatch({
            type: UPDATE_BUSINESS_SUCCESS
        });

        history.push('/business');

    } catch (err) {
        const msg = err.response.data.message
            ? err.response.data.message
            : 'An expected error occurred';
        dispatch({
            type: UPDATE_BUSINESS_ERROR,
            payload: msg
        });
    }
};



export const cleanBusiness = () => async (dispatch) => {
    dispatch({
        type: BUSINESS_CLEANUP
    });
};

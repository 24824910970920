import coiApi from '../api/coiApi';

import {
    GET_USER_BY_ID_ERROR,
    GET_USER_BY_ID_REQUEST,
    GET_USER_BY_ID_SUCCESS,
    GET_USER_ERROR,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    UPDATE_USER_ERROR,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    USERS_CLEAN_UP
} from "./actionTypes";
import history from "../history";

export const getUsers = (all = 0, page = 0, search = '', orderColumn = 'last_name', orderDirection = 'asc' ) => async (dispatch) => {
    dispatch({
        type: GET_USER_REQUEST,
        orderColumn,
        orderDirection
    })

    try {
        page++;

        const {data} = await coiApi.get(
            `/admin/user?page=${page}&search=${search}&orderColumn=${orderColumn}&orderDirection=${orderDirection}&all=${all}`
        );

        dispatch({
            type: GET_USER_SUCCESS,
            users: data.data,
            currentPage: data.current_page - 1,
            totalRecords: data.total,
            from: data.from,
            to: data.to,
            searchInput: search,
            orderColumn: orderColumn,
            orderDirection: orderDirection
        });

    } catch (err) {
        const msg = err.message
            ? err.message
            : 'An expected error occurred';
        dispatch({
            type: GET_USER_ERROR,
            payload: msg
        });
    }

};

export const getUserById = (id) => async (dispatch) => {
    dispatch({
        type: GET_USER_BY_ID_REQUEST,
    });

    try {
        const {data} = await coiApi.get(
            `/admin/user/${id}`
        );

        dispatch({
            type: GET_USER_BY_ID_SUCCESS,
            user: data
        });


    } catch (err) {
        const msg = err.message
            ? err.message
            : 'An expected error occurred';
        dispatch({
            type: GET_USER_BY_ID_ERROR,
            payload: msg
        });
    }
};

export const updateUser = (user) => async (dispatch) => {
    const reloadCurrentRoute = user.reload
    dispatch({
        type: UPDATE_USER_REQUEST,
    });

    try {

        const {data} = await coiApi.put(
            `/admin/user/${user.user_id}`, user
        );

        

        dispatch({
            type: UPDATE_USER_SUCCESS
        });

        if(reloadCurrentRoute){
         window.location.reload()   
        }else{
            history.push('/users');
        }
        

    } catch (err) {
        const msg = err.message
            ? err.message
            : 'An expected error occurred';
        dispatch({
            type: UPDATE_USER_ERROR,
            payload: msg
        });
    }
};

export const cleanUsers = () => async (dispatch) => {
    dispatch({
        type: USERS_CLEAN_UP
    });
};
